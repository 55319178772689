import React, { Component } from 'react';
import {
	Row, Col, Card,
	Button, CardBody, Modal,
	ModalHeader, ModalBody,
	ModalFooter, Input, FormGroup,
	UncontrolledDropdown,
	DropdownMenu, DropdownItem,
	InputGroupAddon, Label, CustomInput,
	DropdownToggle
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
import PageTitle from '../../components/PageTitle';
import swal from 'sweetalert';
import EditModal from "./EditModal"
import { default as Select } from 'react-select'

const columns = [
	{
		dataField: 'first_name',
		text: 'Name',
		sort: true,
	},
	{
		dataField: 'phone_number',
		text: 'Phone',
		sort: false,
	},
	{
		dataField: 'email',
		text: 'Email',
		sort: true,
	},
	{
		dataField: 'state_name',
		text: 'Assigned State',
		sort: false,
	},
	{
		dataField: 'LGA_name',
		text: 'Assigned LGA',
		sort: false,
	},

	{
		dataField: 'actions',
		text: 'Actions',
		sort: false,
	},
];

const defaultSorted = [
	{
		dataField: 'id',
		order: 'asc',
	},
];



class Tables extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			modal_standard: false,
			table_data: [],
			EditProfile: false,
			Edit_data: [],
			state_id: [],
			states: [],
			report_types: [],
			report_type: [],
			lga_id: [],
			lgas: []

		};
		this.tog_standard = this.tog_standard.bind(this);
		this.toggle = this.toggle.bind(this);
		this.openModalWithSize = this.openModalWithSize.bind(this);
		this.openModalWithClass = this.openModalWithClass.bind(this);
	}
	tog_standard() {
		this.setState(prevState => ({
			modal_standard: !prevState.modal_standard
		}));
	}

	toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal,
		}));
	};

	openModalWithSize = size => {
		this.setState({ size: size, className: null });
		this.toggle();
	};


	openModalWithClass = className => {
		this.setState({ className: className, size: null });
		this.toggle();
	};

	componentDidMount() {
		axios.get(apiURL + '/api/security-operatives').then((res) => {
			this.setState({ table_data: res.data })
		}).catch(e => {
			console.log("Addition failed , Error", e);
		});

		axios.get(apiURL + '/api/active-states').then((res) => {
			var states = res.data.states.map(row => {
				row.key = row.id;
				row.label = row.name;
				row.value = row.id;
				return row;
			});
			var report_types = res.data.report_types.map(row => {
				row.key = row.id;
				row.label = row.title;
				row.value = row.id;
				return row;
			});
			this.setState({ states, report_types })
		}).catch(e => {
			console.log("Addition failed , Error", e);
		});



	}


	onChangeState = (state_id) => {
		this.setState({
			dataLoaded: false,
			state_id: state_id,

		});

		var state_ids = state_id.map(row => {
			return row.id;
		})
		console.log(state_ids)
		axios.post(apiURL + '/api/user/security-operatives-lgas', state_ids).then((res) => {

			console.log(res.data)
			if (res.data.status === 1) {
				var lgas = res.data.lgas.map(row => {
					row.key = row.id;
					row.label = row.name;
					row.value = row.id;
					return row;
				});

				this.setState({
					dataLoaded: true,
					lgas,
					lga_id: []
				})
			}
		}).catch(e => console.log("Addition failed , Error ", e));
	}

	handleValidSubmit = (event, values) => {
		console.log(this.state.state_id)
		if (this.state.state_id.length == 0) {
			swal("Warning!", "Select state", "warning");
			return
		}else if ( this.state.report_type.length == 0) {
			swal("Warning!", "Select Categorys", "warning");
			return
		}else if (this.state.lga_id.length == 0) {
			swal("Warning!", "Select LGA", "warning");
			return
		} else {
			document.getElementById("fssam-btn").disabled = true;
			values.so_state_id = this.state.state_id.map(row => {
				return row.id;
			})
			values.report_type = this.state.report_type.map(row => {
				return row.id;
			})

			values.so_lga_id = this.state.lga_id.map(row => {
				return row.id;
			})
			console.log(values)
			
			values.user_type = 3
			axios.post(apiURL + '/api/security-operative', values).then((res) => {
				if (res.data.status === 1) {
					document.getElementById("fssam-btn").disabled = false;
					swal("Success!", "Security Operative has been added sucessfully", "success").then(() => {
						window.location.reload();
					});
				} else {
					document.getElementById("fssam-btn").disabled = false;
					swal("Warning!", res.data.message, "warning");
				}
				this.setState({
					dataLoaded: true,
				});
			}).catch(e => console.log("Addition failed , Error ", e));
		}

	}

	EditProfile = (e, user_id = 0) => {
		if (user_id == 0) {
			this.setState({
				EditProfile: false,
				user_id,
				Edit_data: null
			});
		} else {
			axios.get(apiURL + `/api/fssam-users/${user_id}`).then((res) => {
				if (res.status == 200) {
					var Edit_data = res.data.data;
					this.setState({
						EditProfile: true,
						user_id,
						Edit_data,
					});
				} else {
					var alertStatus = {};
					alertStatus.message = res.data.message;
					alertStatus.color = "danger"
					this.setState({
						EditProfile: false,
						user_id: 0,
						Edit_data: null,
						alertStatus
					});
					var that = this;
					setTimeout(function () {
						that.setState({
							alertStatus: null
						});
					}, 9000);
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	};

	updateStatus = (row_id, status) => {
		var data_upload = {};
		var success_msg = "";
		var fail_msg = "";
		var status_word = "";
		swal({
			title: "Are you sure?",
			text: "Do you want to delete Security operative ?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					axios.delete(apiURL + `/api/fssam-users/${row_id}`).then((res) => {
						var alertStatus = {};
						if (res.status === 1) {
							alertStatus.color = "success";
							alertStatus.message = success_msg;
						} else {
							alertStatus.color = "danger";
							alertStatus.message = fail_msg;
						}
						this.setState({
							alertStatus
						});
						window.location.reload();
					}).catch(e => console.log("Addition failed , Error ", e));
				} else {
					swal("You have canceled the operation!");
				}
			})
	}
	render() {
		const { SearchBar } = Search;
		const { ExportCSVButton } = CSVExport;
		this.state.table_data = this.state.table_data.map((row, index) => {
			row.actions = <div>
				<Button className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm" onClick={(e) => { this.EditProfile(e, row.id) }}>Edit</Button>
				<Button className="ml-1 mr-1 delete-bg pl-4 pr-4" size="sm" onClick={() => { this.updateStatus(row.id, 2) }}>Delete</Button>
			</div>

			return row;
		})
		return (
			<React.Fragment>

				<Row>
					<Col Col lg={12} className="text-right">
						<Button onClick={this.tog_standard} color="primary" size="sm"  >Add Security Operative</Button>
					</Col>
					<Col lg={12}>
						<ToolkitProvider
							bootstrap4
							keyField="id"
							data={this.state.table_data}
							columns={columns}
							search
							exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
							{props => (
								<React.Fragment>
									<Row>
										<Col>
											<SearchBar {...props.searchProps} />
										</Col>

									</Row>

									<BootstrapTable
										{...props.baseProps}
										bordered={false}
										defaultSorted={defaultSorted}
										pagination={paginationFactory({ sizePerPage: 5, sizePerPageRenderer: sizePerPageRenderer, sizePerPageList: [{ text: '5', value: 5, }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: 'All', value: this.state.table_data.length }] })}
										wrapperClasses="table-responsive"
									/>
								</React.Fragment>
							)}

						</ToolkitProvider>
					</Col>
				</Row>

				<Row>
					<Card>
						<CardBody>

							<Row>
								<Modal
									isOpen={this.state.modal_standard}
									toggle={this.tog_standard}
									size="lg"
								>
									<ModalHeader toggle={() => this.setState({ modal_standard: false })}>
										Add security operator
									  </ModalHeader>
									<ModalBody>
										<AvForm onValidSubmit={this.handleValidSubmit} id="fssam-list-form" className="authentication-form">
											<Row>
												<Col lg={4}>
													<AvField
														name="first_name"
														label="First Name"
														type="text"
														validate={{
															required: {
																value: true,
																errorMessage: "First name is required."
															},
															pattern: {
																value: "^[a-zA-Z ,.'-]+$",
																errorMessage: "Invalid name of first name"
															},
															minLength: {
																value: 2,
																errorMessage: "The name must be between 2 and 180 characters"
															},
															maxLength: {
																value: 180,
																errorMessage: "The name must be between 2 and 180 characters"
															}
														}}
													/>
												</Col>
												<Col lg={4}>
													<AvField
														name="middle_name"
														label="Middle Name"
														type="text"
														validate={{
															pattern: {
																value: "^[a-zA-Z ,.'-]+$",
																errorMessage: "Invalid name of middle name"
															}
														}}
													/>
												</Col>
												<Col lg={4}>
													<AvField
														name="last_name"
														label="Last Name"
														type="text"
														validate={{
															pattern: {
																value: "^[a-zA-Z ,.'-]+$",
																errorMessage: "Invalid name of last name"
															}
														}}
													/>
												</Col>
											</Row>
											<Row>
												<Col lg={4}>
													<label>Phone number</label>
													<AvField
														name="phone_number"
														value={this.state.mobile_number}
														autoComplete="off"
														validate={{
															required: {
																value: true,
																errorMessage: "Mobile number is required"
															},
															pattern: {
																value: "^(\\d{10,12},?)+$",
																errorMessage: "Invalid phone number"
															}
														}}
														type="text"
														className="form-control"
														id="Phone_number"
														placeholder="Enter Mobile number" />
												</Col>
												<Col lg={4}>
													<label>Email</label>
													<AvField
														name="email"
														type="email"
														validate={{
															required: {
																value: true,
																errorMessage: "Email  is required"
															}

														}} />
												</Col>
												<Col lg={4}>
													<FormGroup>
														<Label for="exampleSelect">Assigned States</Label>
														<Select
															isMulti={true}
															classNamePrefix="react-select"
															placeholder="Select State"
															options={this.state.states}
															name="so_state_id[]"
															value={this.state.state_id}
															// onChange={state_id => { 
															// 	this.setState({ state_id})

															// }}
															onChange={this.onChangeState}
															required
														/>
													</FormGroup>
												</Col>
												<Col lg={4}>
													<FormGroup>
														<Label for="exampleSelect">Assigned LGAs</Label>
														<Select
															isMulti={true}
															classNamePrefix="react-select"
															placeholder="Select LGAS"
															options={this.state.lgas}
															name="so_lga_id[]"
															value={this.state.lga_id}
															onChange={lga_id => {
																this.setState({ lga_id })
															}}
															required
														/>

													</FormGroup>
												</Col>
												<Col lg={4}>
													<FormGroup>
														<Label for="exampleSelect">Assigned Categories</Label>
														<Select
															isMulti={true}
															classNamePrefix="react-select"
															placeholder="Select Category"
															options={this.state.report_types}
															name="report_type[]"
															value={this.state.report_type}
															onChange={report_type => {
																this.setState({ report_type })
															}}
															required
														/>
													</FormGroup>
												</Col>
												<Col lg={4}>
													<AvField
														name="password"
														label="Password"
														type="password"
														id="password"
														validate={{
															required: {
																value: true,
																errorMessage: "Password is required"
															}

														}} />
												</Col>
												<Col lg={4}>
													<AvField
														name="retypepassword"
														label="Retype Password"
														type="password"
														validate={{
															required: {
																value: true,
																errorMessage: "Re-type password is required"
															},
															match: {
																value: 'password',
																errorMessage: "Re-type password doesn't match with password"
															},

														}} />
												</Col>
											</Row>
											<Row>

												<Col lg={12}>
													<label>&nbsp;</label>
													<Button block className="green_bg_dark" id="fssam-btn">Submit</Button>
												</Col>
											</Row>
										</AvForm>
									</ModalBody>
								</Modal>
							</Row>
						</CardBody>
					</Card>
				</Row>
				<EditModal
					modal={this.state.EditProfile}
					toggle={this.EditProfile}
					Edit_data={this.state.Edit_data}
					user_id={this.state.user_id}
				/>

			</React.Fragment>
		);
	};
}
{/* table starts */ }
const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
	<React.Fragment>
		<label className="d-inline mr-1">Show</label>
		<Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
			defaultValue={currSizePerPage}
			onChange={(e) => onSizePerPageChange(e.target.value)}>
			{options.map((option, idx) => {
				return <option key={idx} value={option.page}>{option.text}</option>
			})}
		</Input>
		<label className="d-inline ml-1">entries</label>
	</React.Fragment>
);


export default Tables;
