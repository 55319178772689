import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Input, Button, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Menu, X, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';
import PageTitle from '../../components/PageTitle';
import logo from '../../assets/images/logo-fav.png';
import Topbar from '../../components/Topbarin';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
import swal from 'sweetalert';
import moment from 'moment';



const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);



class statusReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
			modal_standard: false,
            track_data:[],
            status : false,
            incident_id:""
        };
        
    }

    componentDidMount() {
        this._isMounted = true;
        
        this.setState({ track_data: this.props.data})

        document.body.classList.add('status_of_report_body');
    }

    handleValidSubmit = (event, values) => {
        console.log(this.state.incident_id)
        console.log(values)
        document.getElementById("feedback-btn").disabled = true;
        axios.post(apiURL + `/api/add-feedback/${this.state.track_data.incident_id }`, values).then((res) => {
            if (res.data.status === 1) {
                document.getElementById("feedback-btn").disabled = false;
                swal("Success!", res.data.message, "success").then(() => {
                    window.location.reload();
                });
            } else {
                document.getElementById("feedback-btn").disabled = false;
                swal("Warning!", res.data.message, "warning");
            }
            this.setState({
                dataLoaded: true,
            });
        }).catch(e => console.log("Addition failed , Error ", e));


    }

    render() {
        this.props.data.status =  this.props.data.status .map((row, index) => {
            row.date = <div>
                {moment(row.timestamp * 1000).format('DD-MM-YYYY HH:mm:ss')}
            </div>
            
            return row;
        })

        this.props.data.response=this.props.data.response.map((row, index) => {
            row.date = moment(row.timestamp * 1000).format('DD-MM-YYYY HH:mm:ss')
            
            row.message = row.forwarded_to == "Report was forwarded to" ? <div>Appropriate authority has been informed</div> :"" 
            
            return row;
        })

      

        const responses = [
            {
                dataField: 'date',
                text: 'Date',
                sort: true,
                width: '50px',
            },
            {
                dataField: 'message',
                text: 'Status',
                sort: true,
                width: '50px',
            },
        ];


        const columns = [
            {
                dataField: 'date',
                text: 'Date',
                sort: true,
                width: '50px',
            },
            {
                dataField: 'message',
                text: 'Status',
                sort: true,
                width: '50px',
            },
        ];

        const defaultSorted = [
            {
                dataField: 'id',
                order: 'asc',
            },
        ];

        console.log(this.props.data)
        return (
            <React.Fragment>
                <Topbar />

                <Row className="p_5_0 pl-4 pr-4 white_bg terms-logo-hide ">
                    <Col lg={12} className="terms-logo-hide-report mt-4">
                        <h5 className="modal-text pt-4 ">Report Tracking | Status of Report</h5>
                    </Col>
                </Row>
                <Row className="pl-4 pr-4 pb-4 white_bg">
                    <Col lg={12} className="pb-0 mt-0 pl-0 pr-0" style={{ border: "1px solid #c8c8c8", borderRadius: "10px" }}>
                        <div className="card_bg">
                            <h4 className="pt-2 pl-3 pr-3 mt-0 pb-0">Status of Report</h4>
                        </div>
                        <Row className="status_of_report">
                            <Col className="">
                                <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={this.props.data.status}
                                    columns={columns}
                                    search
                                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                                    {props => (
                                        <React.Fragment>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bordered={false}
                                                defaultSorted={defaultSorted}
                                                wrapperClasses="table-responsive"
                                            />
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            </Col>
                        </Row><br />

                        <div className="card_bg_2 mt-4">
                            <h4 className="pt-2 pl-3 pr-3 mt-0 pb-0">Response to Report</h4>
                        </div>
                        <Row className="status_of_report">
                            <Col className="">
                                <ToolkitProvider
                                    bootstrap4
                                    keyField="id"
                                    data={this.props.data.response}
                                    columns={responses}
                                    search
                                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                                    {props => (
                                        <React.Fragment>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bordered={false}
                                                defaultSorted={defaultSorted}
                                                wrapperClasses="table-responsive"
                                            />
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            </Col>
                        </Row><br />
                        {this.state.track_data.report_status != 2 ? 
                        <div className="card_bg_2 mt-4">
                            <h4 className="pt-2 pl-3 pr-3 mt-0 pb-0">Feedback</h4>
                        </div> :""}
                        {this.state.track_data.report_status != 2 ? 
                        <Row className="status_of_report mt-3 ml-1 mr-1 status-report">
                            <Col lg={12} className="">
                                <AvForm
                                    onValidSubmit={this.handleValidSubmit}
                                    className="form-horizontal"
                                    id="feedback-form"
                                >
                                    <AvField name="feedback" validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "feedback is required"
                                        }

                                    }} type="textarea" height="200px" className="form-control" id="task_name" placeholder="Please provide any further comments or suggestions" />
                                    <Button className="green_bg" id="feedback-btn">Submit Feedback</Button>
                                </AvForm>
                            </Col>
                            <Col lg={12}>
                                <div className="text-right btn_position">

                                </div>
                            </Col>
                        </Row> :""}
                    </Col>
                </Row>
                <footer className=" footer  pb-3">
                    <div className="container-fluid ml-4 mr-4">
                        <div className="row">
                            <div className="col-12">
                                2021 All Rights Reserved. | SIRP
                        </div>
                        </div>
                    </div>
                </footer>

            </React.Fragment>
        );
    }
};

export default statusReport;
