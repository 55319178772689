// @flow
import React, { Component } from 'react';
import { Row, Col,Card, CardBody, Button, UncontrolledDropdown,DropdownToggle,Modal, ModalHeader, ModalBody, ModalFooter,
         DropdownItem,DropdownMenu,} from 'reactstrap';
import { ChevronDown,LogOut, ChevronLeft, ChevronUp, ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { getLoggedInUser } from '../../helpers/authUtils';
import ReportsTables from './ReportsTables';


class FssamReports extends Component {

	constructor(props) {
        super(props);

        this.state = {
            modal: false,
            users:getLoggedInUser(),
        };

        this.toggle = this.toggle.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);
    }

    /**
     * Show/hide the modal
     */
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };

    /**
     * Opens large modal
     */
    openModalWithSize = size => {
        this.setState({ size: size, className: null });
        this.toggle();
    };

    /**
     * Opens modal with custom class
     */
    openModalWithClass = className => {
        this.setState({ className: className, size: null });
        this.toggle();
    };
	
	render() {
        return (   
			<React.Fragment>
				<div className="light_green">
					<PageTitle
						breadCrumbItems={[
							{ label: 'All FSSAM Reports', path: '/fssam-reports', active: true },
						]}
						title={'All FSSAM Reports'}
					/>

					<Row className="mt-4">
						<Col lg={12}>
							<Card className="pl-4 pr-4 pt-4 pb-4">
								<div>
									<h5 className="header-title mb-3 mt-0">All FSSAM Reports</h5>
								</div>
								<ReportsTables />
							</Card>
						</Col>
					</Row>
				</div>			
			</React.Fragment>
		);
	}
};


export default FssamReports;