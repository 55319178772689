import React, { Component } from 'react';
import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ReportsTables from './ReportsTables';
import ReportsTablesTwo from './ReportsTablesTwo';
import ReportsTablesThree from "./ReportsTablesThree";
import { apiURL, BASE_URL } from '../../constants/defaultValues';
import axios from 'axios';
import { getLoggedInUser } from '../../helpers/authUtils';

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: '1',
			new_data:[],
			Processing_data:[],
			closed_data:[]

		};
        this.toggle = this.toggle.bind(this);
    }

    /**
     * Toggle the tab
     */
    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        const tabContents = [
            {
                id: '1',
                title: 'NEW',
                icon: 'uil-home-alt',
                text:<ReportsTables data={this.state.new_data} />
            },
            {
                id: '2',
                title: 'PROCESSING',
                icon: 'uil-user',
                text:<ReportsTablesTwo data={this.state.Processing_data} />
            },
            {
                id: '3',
                title: 'CLOSED',
                icon: 'uil-envelope',
                text:<ReportsTablesThree data={this.state.closed_data}/>
            },
        ];
		const loggedInUser = getLoggedInUser();

        return (
            <React.Fragment>
                <Row>                   
                    <Col lg={12}>
                        {loggedInUser ?                         
						<h5 className="header-title mb-3 mt-0">{loggedInUser.user_type==2 ? loggedInUser.state_name+" State ":""}Reports</h5> :""}

						<Nav className="nav nav-pills navtab-bg nav-justified">
							{tabContents.map((tab, index) => {
								return (
									<NavItem key={index}>
										<NavLink
											href="#"
											className={classnames({ active: this.state.activeTab === tab.id })}
											onClick={() => {
												this.toggle(tab.id);
											}}>
											<i
												className={classnames(
													tab.icon,
													'd-sm-none',
													'd-block',
													'mr-1'
												)}></i>
											<span className="d-none d-sm-block">{tab.title}</span>
										</NavLink>
									</NavItem>
								);
							})}
						</Nav>

						<TabContent activeTab={this.state.activeTab}>
							{tabContents.map((tab, index) => {
								return (
									<TabPane tabId={tab.id} key={index}>
										<Row>
											<Col sm="12">
												<p className="mt-2">{tab.text}</p>
											</Col>
										</Row>
									</TabPane>
								);
							})}
						</TabContent>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Tabs;
