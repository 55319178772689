import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import Flatpickr from 'react-flatpickr'
import { Link } from 'react-router-dom';
import { AvForm, AvField, AvRadioGroup,AvRadio, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import * as FeatherIcon from 'react-feather';

import { getLoggedInUser } from '../../helpers/authUtils';
import Loader from '../../components/Loader';
import OverviewWidget from '../../components/OverviewWidget';
import logo from '../../assets/images/logo-fav.png';
import amotekun from '../../assets/images/amotekun.jpg';
import lalong from '../../assets/images/lalong.jpg';
import taraba from '../../assets/images/taraba.jpg';
import crime1 from '../../assets/images/crime-1.jpg';
import alert1 from '../../assets/images/alert.jpg';
import SwiperCore, { Navigation , Autoplay  } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
import swal from 'sweetalert';
import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';
import Topbar from '../../components/Topbarin';
import StatusReport from "./status-of-report";

SwiperCore.use([Navigation ,Autoplay ]);
	
class ReportTracking extends Component {

    constructor(props) {
        super(props);

        var oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 15);

        this.state = {
			modal_standard: false,
            user: getLoggedInUser(),
            filterDate: [oneWeekAgo, new Date()],
            track_data:null,
            status : false,
            incident_id:"",
            report_status:""
        };
		this.tog_standard = this.tog_standard.bind(this);
    }
	tog_standard() {
		this.setState(prevState => ({
		  modal_standard: !prevState.modal_standard
		}));
	  }
	  componentDidMount() {
        this._isMounted = true;

        document.body.classList.add('report');
    }

    handleValidSubmit = (event, values) => {
        console.log(values)
        document.getElementById("track-btn").disabled = true;
        axios.get(apiURL + `/api/track-report/${values.track_id.trim()}`).then((res) => {
            if (res.data.status === 1) {
                document.getElementById("track-btn").disabled = false;
                this.setState({ track_data : res.data.data})
                // this.setState ({ incident_id : res.data.incident_id , report_status:res.data.report_status})
                // swal("Success!", res.data.message, "success").then(() => {
                    this.setState({ status : true})
                    // window.location.replace("/citizens/status-of-report");
                // });
            } else {
                document.getElementById("track-btn").disabled = false;
                swal("Warning!", res.data.message, "warning");
            }
            this.setState({
                dataLoaded: true,
            });
        }).catch(e => console.log("Addition failed , Error ", e));


    }

    render() {
        console.log(this.state.track_data)
		  
		     const params = {
            effect: 'coverflow',
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: '3',
			coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
      },
            centeredSlides: true,
			navigation: 
			      {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				  }
             }

        return (
            <React.Fragment>
			
			<Topbar/>
			<div className={!this.state.status ? "mt-5" :"d-none"}>
                <div className="white_bg terms-logo-hide mt-4 " >
                    <Row className="pl-4 pr-4 pb-1 p-50 mt-4 ">
	                     <Col lg={12}>
						      <h5 className="modal-text pt-4">Report Tracking | Enter Tracking ID</h5>
						 </Col>
						 <Col lg={12} className="mt-4" style={{minHeight:'calc(65vh - 100px)'}}>
						     <div className="b_10 text-center">
							      <h5 className="modal-text" style={{paddingTop:"30px"}}> Enter Report Tracking ID</h5>
							      <AvForm
							onValidSubmit={this.handleValidSubmit}
							className="form-horizontal"
							id="citizen-form"
							>
                            <div className="px-4">
                            <AvField 
                                name="track_id"  
                                type="text"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Tracking report id is required."
                                    }
                                }}
                            />
                            </div> 
                            <div className="ml-4 mr-4 mb-4" style={{paddingBottom:"30px"}}>
                                <Button block className="green_bg pt-3 pb-3" id="track-btn">Submit</Button>
                                </div>
                                 </AvForm>
							 </div>
						 </Col>
                    </Row>
                   
                    
                    
                   </div>
				   <footer className=" footer  pb-3">
                <div className="container-fluid ml-4 mr-4">
                    <div className="row">
                        <div className="col-12">
                            2021 All Rights Reserved. | SIRP
                        </div>
                    </div>
                </div>
            </footer>
            </div>
            {this.state.track_data != null ? 
            <div className={this.state.status ? "" :"d-none"}>
                        <StatusReport 
                             data={this.state.track_data}
                         />
            </div> : ""}
            </React.Fragment>
        )
    }
}


export default ReportTracking;