import React, { Component } from 'react';
import {  Link } from 'react-router-dom';
import {
    Container, Row, Col, Card, CardBody, InputGroup, InputGroupAddon, UncontrolledDropdown, DropdownMenu, DropdownToggle,
    DropdownItem, Button, Badge, Modal, CustomInput, ModalHeader, Input, FormGroup, Label, ModalBody, ModalFooter
} from "reactstrap";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { MoreVertical } from 'react-feather';
import BootstrapTable from 'react-bootstrap-table-next';

import { apiURL, BASE_URL } from '../../constants/defaultValues';

import axios from 'axios';
import fetch from 'isomorphic-fetch';
import swal from 'sweetalert';

class EditModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            dataLoaded: false,
            Edit_data: [],
            modal:false,
            state:[]
        }
    }

    componentDidMount() {
        this.setState({ dataLoaded: true })
        axios.get(apiURL + '/api/active-states').then((res) => {
            this.setState({ state: res.data.states })
        }).catch(e => {
            console.log("Addition failed , Error", e);
        });


    }




    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }


    IsBetween(value, left, right) {
        return (left < value && value < right) || (right < value && value < left);
    }
    
   
    handleValidSubmit = (event, values) => {
        document.getElementById("fssam-btn").disabled = true;
        axios.post(apiURL + '/api/edit-fssam-user', values).then((res) => {
            if (res.data.status === 1) {
                document.getElementById("fssam-btn").disabled = false;
                swal("Success!", res.data.message, "success").then(() => {
                    window.location.reload();
                });
            } else if(res.data.status === 0){
                document.getElementById("fssam-btn").disabled = false;
                // window.location.reload();
                swal("Warning!", res.data.message, "warning");
            } else{
                window.location.reload();
            }
            this.setState({
                dataLoaded: true,
            });
        }).catch(e => console.log("Addition failed , Error ", e));


    }
    openModal = (e, offers = []) => { return (this.setState({ modal: !this.state.modal, offers })) };
    render() {
        const initialContent = "";

        const { modal, toggle, user_id } = this.props;
        const Edit_data = this.props.Edit_data
        return (

            <Modal
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size="lg"
                wrapClassName="modal-right"
            >
                <ModalHeader toggle={this.props.toggle}>Edit State Administrator</ModalHeader>
                <ModalBody>
                <AvForm onValidSubmit={this.handleValidSubmit} id="fssam-list-form" className="authentication-form">
										         <Row>
                                                 <Col lg={4}>
														     <AvField 
                                                             name="first_name"
                                                              label="First Name"
                                                               type="text" 
                                                               value={Edit_data ? Edit_data.first_name : ""}
                                                               validate={{
																required: {
																	value: true,
																	errorMessage: "First name is required."
																},
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name of first name"
																},
																minLength: {
																	value: 2,
																	errorMessage: "The name must be between 2 and 180 characters"
																},
																maxLength: {
																	value: 180,
																	errorMessage: "The name must be between 2 and 180 characters"
																}
															}}
                                                            />
													 </Col>
													 <Col lg={4}>
													         <AvField 
                                                             name="middle_name" 
                                                             label="Middle Name"
                                                             value={Edit_data ? Edit_data.middle_name : ""}
                                                              type="text" 
                                                              validate={{
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name of middle name"
																}
															}}
                                                               />
													 </Col>
													 <Col lg={4}>
													         <AvField 
                                                             name="last_name" 
                                                             label="Last Name" 
                                                             value={Edit_data ? Edit_data.last_name : ""}
                                                             type="text"
                                                             validate={{
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name of last name"
																}
															}}
                                                               />
													 </Col>
												 </Row>
                                            <AvInput type="hidden" id="edit_id" name="edit_id" value={this.props.user_id}></AvInput>

												 
												 <Row>
												     <Col lg={4}>
                                                     <label>Phone number</label>
                                                     <AvField
                                                    name="phone_number"
                                                    value={Edit_data ? Edit_data.phone_number : ""}
                                                    autoComplete="off"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Mobile number is required"
                                                        },
                                                        pattern: {
                                                            value: "^(\\d{10,12},?)+$",
                                                            errorMessage: "Invalid phone number"
                                                        }
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    id="Phone_number"
                                                    placeholder="Enter Mobile number" />
													 </Col>
													 <Col lg={4}>
														     <label>Email</label>
													         <AvField 
                                                             name="email" 
                                                             type="email" 
                                                             value={Edit_data ? Edit_data.email : ""}
                                                             validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: "Email  is required"
                                                                }
                                                                
                                                            }}  />
													 </Col>
                                                     <Col lg={4}>
							         <FormGroup>
										<Label for="exampleSelect">Assigned State</Label>
										<AvField
													name="state_id"
													type="select"
													autoComplete="off"
													placeholder="Select state"
                                                    value={Edit_data ? Edit_data.state_id : ""}
													// value={this.state.state_}
													validate={{
														required: {
															value: true,
															errorMessage: "select state "
														}}}
													onChange={e => this.setState({ client: e.target.value })}
												>
													<option value="">Select state</option>
													{this.state.state ? this.state.state.map((data)=> {
																return(
																	<option value={data.id}>{data.name}</option>
																)
																}):""}
																
												</AvField>
                                                <AvInput type="hidden" id="user_type" name="user_type" value="2"></AvInput>
								     </FormGroup>
							     </Col>
												 </Row>
										         <Row>
													 <Col lg={4}>
														     <label>&nbsp;</label>
													         <Button block className="green_bg_dark"  id="fssam-btn">Submit</Button>
													 </Col>
												 </Row>
													     </AvForm>
                </ModalBody>
                 
            </Modal>
        );
    }
};

export default (EditModal);


