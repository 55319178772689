import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import DocViewer from "react-doc-viewer";
import PageTitle from '../../components/PageTitle';

const docs = [
    { uri: "https://url-to-my-pdf.pdf" },
  ];
  
class Tables extends Component {
	
	constructor(props) {
        super(props);

        this.state = {

        };
        
    }
    
	render() {
    return (
        <React.Fragment>
             <Row>
			     <Col lg={12}>
			         <DocViewer documents={docs} />
				 </Col>	 
			 </Row>
        </React.Fragment>
    );
};
}
 {/* table starts */}

  
  {/* table ends */}
export default Tables;
