import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

import PageTitle from '../../components/PageTitle';

const records = [
    {
        name: 'Jacob Gurnu',
        phone: '0802090900',
        email: 'jgumu@sirp.ng',
        state: 'Abuja',
        actions: <div>
					<Button className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Edit</Button>
					<Button  className="ml-1 mr-1 delete-bg pl-4 pr-4" size="sm">Delete</Button>
				 </div>,
		

    },
	{
        name: 'Gambo Ayuba',
        phone: '0802090900',
        email: 'gamboa@sirp.ng',
        state: 'Kaduna',
        actions: <div>
					<Button className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Edit</Button>
					<Button  className="ml-1 mr-1 delete-bg pl-4 pr-4" size="sm">Delete</Button>
				 </div>,
		

    },
	{
        name: 'Balogun Moruf',
        phone: '0802090900',
        email: 'gamboa@sirp.ng',
        state: 'Kaduna',
        actions: <div>
					<Button className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Edit</Button>
					<Button  className="ml-1 mr-1 delete-bg pl-4 pr-4" size="sm">Delete</Button>
				 </div>,
		

    }
];

const columns = [
    {
        dataField: 'name',
        text: 'Name',
        sort: true,
    },
    {
        dataField: 'phone',
        text: 'Phone',
        sort: false,
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true,
    },
    {
        dataField: 'state',
        text: 'Assigned State',
        sort: false,
    },
	
	{
        dataField: 'actions',
        text: 'Actions',
        sort: false,
    },
];

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];
 
			  
	
class Tables extends Component {
	
	constructor(props) {
        super(props);

        this.state = {
            modal: false,
		    modal_standard: false,

        };
        this.tog_standard = this.tog_standard.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);
    }
    tog_standard() {
		this.setState(prevState => ({
		  modal_standard: !prevState.modal_standard
		}));
	  }
   
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };

    openModalWithSize = size => {
        this.setState({ size: size, className: null });
        this.toggle();
    };

    
    openModalWithClass = className => {
        this.setState({ className: className, size: null });
        this.toggle();
    };
	
	
	render() {
    return (
        <React.Fragment>
		
				<Row>
				<Col Col lg={12} className="text-right">
                                    <Button onClick={this.tog_standard} color="primary" size="sm"  >Add State Administrator</Button>
                                </Col>
					<Col lg={12}>
						<TableWithSearch />
					</Col>
				</Row>
			     
                 <Row>
			         <Card>
                        <CardBody>
                            
                             <Row>
								 <Modal
									  isOpen={this.state.modal_standard}
									  toggle={this.tog_standard}
									  size="lg"
									>
									  <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
										  Add User
									  </ModalHeader>
									       <ModalBody>
										         <Row>
												     <Col lg={4}>
													     <AvForm>
														     <AvField name="firstname" label="First Name" type="text" required />
													     </AvForm>
													 </Col>
													 <Col lg={4}>
													     <AvForm>
													         <AvField name="middlename" label="Middle Name" type="text" required />
													     </AvForm>
													 </Col>
													 <Col lg={4}>
													     <AvForm>
													         <AvField name="lastname" label="Last Name" type="text" required />
													     </AvForm>
													 </Col>
												 </Row>
												 <Row>
												     <Col lg={4}>
													     <AvForm>
														     <AvField name="phone" label="Phone Number" type="number" required />
													     </AvForm>
													 </Col>
													 <Col lg={4}>
													     <AvForm>
														     <label>Email</label>
													         <AvField name="email" type="email" required />
													     </AvForm>
													 </Col>
													 <Col lg={4}>
														 <label>Assigned State</label>
															 <Input type="select" name="select"  id="exampleSelect2" className="custom-select">
																<option>1</option>
																<option>2</option>
																<option>3</option>
																<option>4</option>
																<option>5</option>
															 </Input>
													 </Col>
												 </Row>
										         <Row>
												     <Col lg={4}>
													     <AvForm>
														     <AvField name="password" label="Password" type="password" required />
													     </AvForm>
													 </Col>
													 <Col lg={4}>
													     <AvForm>
													         <AvField name="retypepassword" label="Retype Password" type="password" required />
													     </AvForm>
													 </Col>
													 <Col lg={4}>
													     <AvForm>
														     <label>&nbsp;</label>
													         <Button block className="green_bg_dark" >Submit</Button>
													     </AvForm>
													 </Col>
												 </Row>
											</ModalBody>
										</Modal>
							 </Row>
						</CardBody>
					</Card>
			 </Row>

           
        </React.Fragment>
    );
};
}
 {/* table starts */}
const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);


   class TableWithSearch extends Component {
	   
	render() {
	   
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    return (
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    data={records}
                    columns={columns}
                    search
                    exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                    {props => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <SearchBar {...props.searchProps} />
                                </Col>
                                
                            </Row>

                            <BootstrapTable
                                {...props.baseProps}
                                bordered={false}
                                defaultSorted={defaultSorted}
                                pagination={paginationFactory({ sizePerPage: 5, sizePerPageRenderer: sizePerPageRenderer, sizePerPageList: [{ text: '5', value: 5, }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: 'All', value: records.length }] })}
                                wrapperClasses="table-responsive"
                            />
                        </React.Fragment>
                    )}
					
                </ToolkitProvider>
				
				
            
    );}
};
	
  {/* table ends */}
export default Tables;
