import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Container, Row, Col, Card, CardBody, InputGroup, InputGroupAddon, UncontrolledDropdown, DropdownMenu, DropdownToggle,
    DropdownItem, Button, Badge, Modal, CustomInput, ModalHeader, Input, FormGroup, Label, ModalBody, ModalFooter
} from "reactstrap";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { MoreVertical } from 'react-feather';
import BootstrapTable from 'react-bootstrap-table-next';

import { apiURL, BASE_URL } from '../../constants/defaultValues';
import {default as Select} from 'react-select'
import axios from 'axios';
import fetch from 'isomorphic-fetch';
import swal from 'sweetalert';

class EditModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            dataLoaded: false,
            Edit_data: [],
            modal: false,
            state_id: [],
            states: [],
            report_types: [],
            report_type_id: [],
            lga_id: [],
            lgas: []

        }
    }

    componentDidMount() {
        this.setState({ dataLoaded: true })

        axios.get(apiURL + '/api/active-states').then((res) => {
            var states = res.data.states.map(row => {
                row.key = row.id;
                row.label = row.name;
                row.value = row.id;
                return row;
            });
            var report_types = res.data.report_types.map(row => {
                row.key = row.id;
                row.label = row.title;
                row.value = row.id;
                return row;
            });
            this.setState({ states, report_types })
        }).catch(e => {
            console.log("Addition failed , Error", e);
        });



    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.modal) {
            //console.log(prevProps.user_id !== this.props.user_id , this.props.user_id!=0)
            if (prevProps.user_id !== this.props.user_id && this.props.user_id != 0) {
                const Edit_data = this.props.Edit_data
                console.log(Edit_data,"Edit_data")
                const states = this.state.states;
				var state_id = [];
				for (let sta of Edit_data.so_state_id.split(",")) {
					let row = states.find(r => { return r.id == sta  });
                    if(row){
					    state_id.push(row);
                    }
                   
				}
                this.setState ({ state_id : state_id})
                const report_types = this.state.report_types;
				var report_type_id = [];
				for (let sta of Edit_data.report_type.split(",")) {
					let row = report_types.find(r => { return r.id == sta  });
                    if(row){
					    report_type_id.push(row);
                    }
                    
				}

                this.setState ({ report_type_id : report_type_id})
                    var edit_lgs = Edit_data.so_state_id.split(",")
                    console.log(edit_lgs)
					axios.post(apiURL + '/api/user/security-operatives-lgas', edit_lgs).then((res) => {
                                var lgas = res.data.lgas.map(row => {
                                    row.key = row.id;
                                    row.label = row.name;
                                    row.value = row.id;
                                    return row;
                                });
                               
                                var lga_id = [];
                                for (let sta of Edit_data.so_lga_id.split(",")) {
                                    let row = lgas.find(r => { return r.id == sta  });
                                    if(row){
                                        lga_id.push(row);
                                    }
                                }
                                this.setState({
                                    dataLoaded: true,
                                    lgas,
                                    lga_id
                                })
                                console.log(lga_id)
                            
					}).catch(e => console.log("Addition failed , Error ", e));
				


                console.log(report_type_id)
                console.log(state_id)
                this.setState(( state_id , report_type_id   ))
            }
        }
    }




    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }


    IsBetween(value, left, right) {
        return (left < value && value < right) || (right < value && value < left);
    }

    onChangeState = (state_id) => {
        this.setState({
            dataLoaded: false,
            state_id: state_id,

        });

        var state_ids = state_id.map(row => {
            return row.id;
        })
        console.log(state_ids)
        axios.post(apiURL + '/api/user/security-operatives-lgas', state_ids).then((res) => {
            if (res.data.status === 1) {
                var lgas = res.data.lgas.map(row => {
                    row.key = row.id;
                    row.label = row.name;
                    row.value = row.id;
                    return row;
                });

                this.setState({
                    dataLoaded: true,
                    lgas,
                    lga_id: this.state.lga_id != null ? 
                        this.state.lga_id.filter(r=>{  return state_ids.indexOf(r.state_id)!=-1 }) : []
                })
            }
        }).catch(e => console.log("Addition failed , Error ", e));
    }


    handleValidSubmit = (event, values) => {
        if (this.state.state_id.length == 0) {
			swal("Warning!", "Select state", "warning");
			return
		}else if ( this.state.report_type_id.length == 0) {
			swal("Warning!", "Select Categorys", "warning");
			return
		}else if (this.state.lga_id.length == 0) {
			swal("Warning!", "Select LGA", "warning");
			return
		} else {
        document.getElementById("fssam-btn").disabled = true;

        values.so_state_id = this.state.state_id.map(row => {
            return row.id;
        })
        values.report_type = this.state.report_type_id.map(row => {
            return row.id;
        })

        values.so_lga_id = this.state.lga_id.map(row => {
            return row.id;
        })
        axios.post(apiURL + '/api/edit-security-operative', values).then((res) => {
            if (res.data.status === 1) {
                document.getElementById("fssam-btn").disabled = false;
                swal("Success!", "Security Operative has been updated sucessfully", "success").then(() => {
                    window.location.reload();
                });
            } else {
                document.getElementById("fssam-btn").disabled = false;
                window.location.reload();
                // swal("Warning!", "Falied to update", "warning");
            }
            this.setState({
                dataLoaded: true,
            });
        }).catch(e => console.log("Addition failed , Error ", e));
    }

    }
    openModal = (e, offers = []) => { return (this.setState({ modal: !this.state.modal, offers })) };
    render() {
        const initialContent = "";

        const { modal, toggle, user_id } = this.props;
        const Edit_data = this.props.Edit_data
        console.log(this.state.state_id)
        console.log(this.state.report_type_id)
        console.log(this.state.lga_id)
        return (

            <Modal
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size="lg"
                wrapClassName="modal-right"
            >
                <ModalHeader toggle={this.props.toggle}>Edit security operator</ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={this.handleValidSubmit} id="fssam-list-form" className="authentication-form">
                        <Row>
                            <Col lg={4}>
                                <AvField
                                    name="first_name"
                                    label="First Name"
                                    type="text"
                                    value={Edit_data ? Edit_data.first_name : ""}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "First name is required."
                                        },
                                        pattern: {
                                            value: "^[a-zA-Z ,.'-]+$",
                                            errorMessage: "Invalid name of first name"
                                        },
                                        minLength: {
                                            value: 2,
                                            errorMessage: "The name must be between 2 and 180 characters"
                                        },
                                        maxLength: {
                                            value: 180,
                                            errorMessage: "The name must be between 2 and 180 characters"
                                        }
                                    }}
                                />
                            </Col>
                            <Col lg={4}>
                                <AvField
                                    name="middle_name"
                                    label="Middle Name"
                                    type="text"
                                    value={Edit_data ? Edit_data.middle_name : ""}
                                    validate={{
                                        pattern: {
                                            value: "^[a-zA-Z ,.'-]+$",
                                            errorMessage: "Invalid name of middle name"
                                        }
                                    }}
                                />
                            </Col>
                            <Col lg={4}>
                                <AvField
                                    name="last_name"
                                    label="Last Name"
                                    type="text"
                                    value={Edit_data ? Edit_data.last_name : ""}
                                    validate={{
                                        pattern: {
                                            value: "^[a-zA-Z ,.'-]+$",
                                            errorMessage: "Invalid name of last name"
                                        }
                                    }}
                                />
                            </Col>
                            <AvInput type="hidden" id="edit_id" name="edit_id" value={this.props.user_id}></AvInput>

                        </Row>
                        <Row>
                            <Col lg={4}>
                                <label>Phone number</label>
                                <AvField
                                    name="phone_number"
                                    value={Edit_data ? Edit_data.phone_number : ""}
                                    autoComplete="off"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Mobile number is required"
                                        },
                                        pattern: {
                                            value: "^(\\d{10,12},?)+$",
                                            errorMessage: "Invalid phone number"
                                        }
                                    }}
                                    type="text"
                                    className="form-control"
                                    id="Phone_number"
                                    placeholder="Enter Mobile number" />
                            </Col>
                            <Col lg={4}>
                                <label>Email</label>
                                <AvField
                                    name="email"
                                    type="email"
                                    value={Edit_data ? Edit_data.email : ""}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "Email  is required"
                                        }

                                    }} />
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label for="exampleSelect">Assigned States</Label>
                                    <Select
                                        isMulti={true}
                                        classNamePrefix="react-select"
                                        placeholder="Select State"
                                        options={this.state.states}
                                        name="so_state_id[]"
                                        value={this.state.state_id}
                                        onChange={this.onChangeState}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label for="exampleSelect">Assigned LGAs</Label>
                                    <Select
                                        isMulti={true}
                                        classNamePrefix="react-select"
                                        placeholder="Select LGAS"
                                        options={this.state.lgas}
                                        name="so_lga_id[]"
                                        value={this.state.lga_id}
                                        onChange={lga_id => {
                                            this.setState({ lga_id })
                                        }}
                                        required
                                    />

                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup>
                                    <Label for="exampleSelect">Assigned Categories</Label>
                                    <Select
                                        isMulti={true}
                                        classNamePrefix="react-select"
                                        placeholder="Select Category"
                                        options={this.state.report_types}
                                        name="report_type[]"
                                        value={this.state.report_type_id}
                                        onChange={report_type_id => {
                                            this.setState({ report_type_id })
                                        }}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6}>
                                <label>&nbsp;</label>
                                <Button block className="green_bg_dark" id="fssam-btn">Submit</Button>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>

            </Modal>
        );
    }
};

export default (EditModal);


