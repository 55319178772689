import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
    Row,
    CustomInput,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label, Col,
    Card, CardBody, Table
} from "reactstrap";
// import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';

export default class printModalContent extends Component {

    render() {
        console.log("sdfsfsdfdfsdfdf")
        const { order_data, order_products, facility_data, currencies, data } = this.props;
        console.log(this.props.data, "sdfsfsdfdfsdfdf")
        var current_currency_symbol = '$';
        var sales_tax = 0;
        var sub_total = 0;
        var p_discount = 0;
        if (order_data != null) {
            var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
            if (current_currency != null) {
                current_currency_symbol = current_currency.currency_symbol;
            }
        }
        return (
            <Row className="invoice-react">
                <Col xxs="12" className="">
                    <div className="mb-5 invoice-contents">
                        <CardBody className="d-flex flex-column justify-content-between">
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row justify-content-between pt-2 pb-2">
                                    <div className="">
                                        {this.props.data ? this.props.data.data ? this.props.data.data.map((data) => {
                                            return (
                                                <Col lg={12}>
                                                    <div className="bg_grey pt-4 pb-4">
                                                        <div className="white bg_view pl-4 pr-4">
                                                            <p>Crime / Incident Type</p>
                                                        </div>
                                                        <Row className="black pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p><strong>Type :{data.report_name} </strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p></p>
                                                            </Col> */}
                                                        </Row>

                                                        <div className="white bg_view pl-4 pr-4">
                                                            <p>State where the incident occured</p>
                                                        </div>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p><strong>State :{data.state_name} </strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p></p>
                                                            </Col> */}
                                                        </Row>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p><strong>LGA : {data.lga_name}</strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p></p>
                                                            </Col> */}
                                                        </Row>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p><strong>Town/City : {data.city}</strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p></p>
                                                            </Col> */}
                                                        </Row>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p style={{ wordBreak: "break-all", textAlign: "left" }}><strong>Address : {data.address}</strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p style={{ wordBreak: "break-all", textAlign: "left" }}	>{data.address}</p>
                                                            </Col> */}
                                                        </Row>

                                                        <div className="white bg_view pl-4 pr-4">
                                                            <p>Date range this incident could have occured</p>
                                                        </div>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p><strong>From : {moment(data.date_range_from).format('DD-MM-YYYY HH:mm:ss')}</strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p></p>
                                                            </Col> */}
                                                        </Row>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p><strong>To : {moment(data.date_range_to).format('DD-MM-YYYY HH:mm:ss')}</strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p></p>
                                                            </Col> */}
                                                        </Row>

                                                        <div className="white bg_view pl-4 pr-4">
                                                            <p>Description of the incident</p>
                                                        </div>
                                                        <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                            <Col sm={12}>
                                                                <p style={{ wordBreak: "break-all", textAlign: "left" }}><strong>Description : {data.description} </strong></p>
                                                            </Col>
                                                            {/* <Col sm={12}>
                                                                <p style={{ wordBreak: "break-all", textAlign: "left" }}></p>
                                                            </Col> */}
                                                        </Row>
                                                        {data.person == 1 || data.person == 2 ?
                                                            <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                                 <div className="white bg_view pl-4 pr-4">
                                                                    <p>Iam</p>
                                                                </div>
                                                                <Col sm={12}>
                                                                    <p style={{ wordBreak: "break-all", textAlign: "left" }}>{data.person == 1 ? "The impacted person"  :"Reporting on behalf of the impacted" }</p>
                                                                </Col>
                                                                {/* <Col sm={12}>
                                                                    <div>
                                                                        <CustomInput type="radio" id="1" name="person" label="The impacted person" checked={data.person == 1} />
                                                                    </div>
                                                                    <div>
                                                                        <CustomInput type="radio" id="2" name="person" label="Reporting on behalf of the impacted" checked={data.person == 2} />
                                                                    </div>
                                                                </Col> */}
                                                            </Row> : ""}
                                                        {data.first_name ?
                                                            <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                                <div className="white bg_view mt-4 pl-4 pr-4">
                                                                    <p>Reporter's Contact Information</p>
                                                                </div>
                                                                <Col sm={12}>
                                                                    <p  style={{ wordBreak: "break-all", textAlign: "left" }}><strong>Full Name : {data.first_name} </strong></p>
                                                                </Col>
                                                                {/* <Col sm={12}>
                                                                    <p></p>
                                                                </Col> */}
                                                            </Row> : ""}
                                                        {data.phone_number ?
                                                            <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                                <Col sm={12}>
                                                                    <p><strong>Phone : {data.phone_number}</strong></p>
                                                                </Col>
                                                                {/* <Col sm={12}>
                                                                    <p></p>
                                                                </Col> */}
                                                            </Row> : ""}
                                                        {data.email ?
                                                            <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                                <Col sm={12}>
                                                                    <p  style={{ wordBreak: "break-all", textAlign: "left" }}><strong>Email : {data.email}</strong></p>
                                                                </Col>
                                                                {/* <Col sm={12}>
                                                                    <p></p>
                                                                </Col> */}
                                                            </Row> : ""}
                                                        {data.contact_address ?
                                                            <Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
                                                                <Col sm={12}>
                                                                    <p style={{ wordBreak: "break-all", textAlign: "left" }}><strong>Address : {data.contact_address}</strong></p>
                                                                </Col>
                                                                {/* <Col sm={12}>
                                                                    <p ></p>
                                                                </Col> */}
                                                            </Row> : ""}
                                                            {data.status != 1 ? 
											typeof this.props.data.log != "undefined" ?
											<Card className="bg_grey pl-4 pr-4 pt-4 pb-4">
												<p className="mb-0 pb-0">LOG</p>< hr />
												<Col className="black">
		
													{this.props.data.log.map((data) => {
														return (<div>
															<Row>
																<Col lg="6" className="black"> <strong>{data.forwarded_to}</strong></Col>
																<Col lg="6" className="black"> {data.message}</Col>
															</Row>
		
														</div>
														)
													})}
												</Col>
											</Card>
											: "" 
											:""}
                                                    </div>
                                                </Col>
                                            )
                                        }) : "":""}
                                    </div>
                                    </div>
                            </div>
						</CardBody>
                    </div>
                </Col>
            </Row>
        );
    }
};
