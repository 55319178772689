import React, { Component } from 'react';
import {
	Row, Col, Card,
	Button, CardBody, Modal,
	ModalHeader, ModalBody,
	ModalFooter, Input, FormGroup,
	UncontrolledDropdown,
	DropdownMenu, DropdownItem,
	InputGroupAddon, Label, CustomInput,
	DropdownToggle
} from 'reactstrap';
import Flatpickr from 'react-flatpickr'
import { Link } from 'react-router-dom';
import alert1 from '../../assets/images/alert.jpg';
import logo from '../../assets/images/logo-fav.png';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvInput, AvGroup, AvFeedback } from 'availity-reactstrap-validation';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
import FileUploader from '../../components/FileUploader';
import Topbar from '../../components/Topbarin';
import { default as Select } from 'react-select'
import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';
import swal from 'sweetalert';
import moment from 'moment';
import Loader from "react-loader-spinner";
class AcceptTermsandConditions extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modal_yes: false,
			modal_submit: false,
			state: [],
			report_types: [],
			state_id: null,
			lga_id: null,
			report_id: null,
			continue: false,
			nextPage: false,
			result: false,
			loader: false,
			state_loader: false,
			Fromdate:null,
			todate:null,
			totime:null,
			FromTime:null

		};
		this.tog_yes = this.tog_yes.bind(this);
		this.tog_submit = this.tog_submit.bind(this);
		this.tog_submit2 = this.tog_submit2.bind(this);
		this.tog_submit3 = this.tog_submit3.bind(this);

	}
	tog_yes() {
		this.setState(prevState => ({
			modal_yes: !prevState.modal_yes
		}));
	}
	tog_submit() {
		this.setState(prevState => ({
			modal_submit: !prevState.modal_submit
		}));
	}

	componentDidMount() {
		this._isMounted = true;
		this.setState({ report_id: this.props.report_id })
		document.body.classList.add('accept_terms');

		axios.get(apiURL + '/api/active-states').then((res) => {
			var states = res.data.states.map(row => {
				row.key = row.id;
				row.label = row.name;
				row.value = row.id;
				return row;
			});
			states.unshift({ key: 0, label: "Select state", value: "" })
			let state_id = null;
			if (states.length) {
				state_id = states[0];
			}
			this.setState({ state: states, report_types: res.data.report_types, state_id })
		}).catch(e => {
			console.log("Addition failed , Error", e);
		});
	}


	onChangeState = (state_id) => {
		this.setState({ state_loader: true })
		this.setState({
			dataLoaded: false,
			state_id: state_id,

		});
		axios.get(apiURL + '/api/user/get-lgas/' + state_id.value).then((res) => {

			console.log(res.data)
			if (res.data.status === 1) {
				var lgas = res.data.lgas.map(row => {
					row.key = row.id;
					row.label = row.name;
					row.value = row.id;
					return row;
				});

				this.setState({
					dataLoaded: true,
					lgas,
					lga_id: null,
					state_loader: false
				})
				document.getElementById("lga-btn").disabled = false;
			}
		}).catch(e => console.log("Addition failed , Error ", e));
	}

	tog_submit2() {
		this.props.selectChange("s5")
		this.setState(prevState => ({
			modal_submit: !prevState.modal_submit,
			continue: true,

		}));
		if (this.props.anonymous) {
			document.getElementById("next-btn").click()
		} else {
			document.getElementById("next2-btn").click()
		}



	}

	tog_submit3() {
		this.setState({ nextPage: !this.state.nextPage })
	}
	handleValidSubmit = (event, values) => {
		console.log(values)
		console.log(this.state.Fromdate , this.state.todate)
		// var momentFrom = moment(this.state.Fromdate.toString().format("MM/DD/YYYY")+" "  +this.state.Fromtime.format("%H %i %s")).unix()
		// var momentTo = moment(this.state.todate.toString().format("MM/DD/YYYY") + " " + this.state.totime.format("%H %i %s")).unix()
		// console.log(momentFrom,momentTo,"aaaaaaaaaaaaaa")
		if (!this.state.Fromdate) {
			swal("Warning!", "Please select date range from ", "warning");
		}
		else if (!this.state.Fromtime) {
			swal("Warning!", "Please select time range from", "warning");
		}
		else if (!this.state.todate) {
			swal("Warning!", "Please select date range to", "warning");
		}
		else if (!this.state.totime) {
			swal("Warning!", "Please select time range to", "warning");
		}
		else if( (moment(this.state.Fromdate).unix()  == moment(this.state.todate).unix() ) && (this.state.totime < this.state.Fromtime)) {
				swal("Warning!", "To time should be greater than from time", "warning");
		}
		else if (this.state.todate < this.state.Fromdate) {
			swal("Warning!", "To date should be greater than from date", "warning");
		}
		
		else if (!this.props.anonymous) {
			if (!this.state.nextPage) {
				this.setState({ nextPage: !this.state.nextPage })
			} else {
				if (this.state.continue) {

					document.getElementById("next2-btn").disabled = true;
					var formData = new FormData(document.getElementById("citizen-form"));
					formData.append('date_range_from', "momentFrom")
					formData.append('date_range_to',"momentTo")
					this.setState({ loader: true, nextPage: false })
					axios.post(apiURL + '/api/add-incidents', formData).then((res) => {
						if (res.data.status === 1) {
							this.setState({ result: true, loader: false })
							this.setState({
								add_data: res.data.data,

							})
							document.getElementById("next2-btn").disabled = false;
							// swal("Success!", res.data.message, "success").then(() => {
							// window.location.reload();
							// });
						} else {
							document.getElementById("next2-btn").disabled = false;
							swal("Warning!", res.data.message, "warning");
						}
						this.setState({
							dataLoaded: true,
						});
					}).catch(e => console.log("Addition failed , Error ", e));

				} else {
					this.tog_submit2();
					this.props.selectChange("s4")
				}
			}

		} else {
			if (this.state.continue) {
				this.setState({ loader: true })
				document.getElementById("next-btn").disabled = true;
				var formData = new FormData(document.getElementById("citizen-form"));
				formData.append('date_range_from',"momentFrom")
				formData.append('date_range_to', "momentTo")
				axios.post(apiURL + '/api/add-incidents', formData).then((res) => {
					if (res.data.status === 1) {
						this.setState({ result: true, loader: false })
						this.setState({
							add_data: res.data.data,
							nextPage: false
						})
						document.getElementById("next-btn").disabled = false;
						// swal("Success!", res.data.message, "success").then(() => {
						// window.location.reload();
						// });
					} else {
						document.getElementById("next-btn").disabled = false;
						swal("Warning!", res.data.message, "warning");
					}
					this.setState({
						dataLoaded: true,
					});
				}).catch(e => console.log("Addition failed , Error ", e));

			} else {
				this.props.selectChange("s4")
				this.tog_submit();

			}


		}
	}
	checkMimeType = (event) => {
		//getting file object
		let files = event.target.files
		//define message container
		let err = ''
		// list allow mime type
		const types = ['.mp4', '.3gp', '.jpeg', '.jpg', '.png', 'video/mp4', 'video/3gpp', 'image/jpeg', 'image/jpg', 'image/png']
		// loop access array
		for (var x = 0; x < files.length; x++) {
			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container 
				err += files[x].name + ' is not a supported file';
			}
		};

		if (err !== '') { // if message not same old that mean has error 
			event.target.value = null;
			swal("Warning", err, "warning");
			return false;
		}
		return true;
	}

	// onChange = e => {
	// 	switch (e.target.name) {
	// 		case 'evidence':
	// 			if (e.target.files[0]) {
	// 				if (this.checkMimeType(e)) {
	// 					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
	// 				} else {
	// 					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
	// 				}
	// 			} else {
	// 				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
	// 			}
	// 			break;
	// 		default:
	// 			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
	// 	}
	// };

	render() {
		const today = moment().format("YYYY-MM-DD");

		return (
			<React.Fragment className="pl-0 pr-0 ml-0 mr-0" >

				<div className="mt-3 fillreport container-fluid pl-0 pr-0 ml-0 mr-0">
					<Row className=" terms-logo-hide">
						<Col lg={3} className="">
							<div className="white_bg pl-4 pr-0 ml-0 mr-0" style={{ height: "100%", minHeight: '100vh' }}>
								<div className="text-center pt-4">
									<img src={logo} alt="" height="80" />
								</div>
								<div>
									<p>PROGRESS FLOW</p>
								</div>

								<div className="radio_custom_sirp">
									<div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox" className="" label="Accept Terms & Conditions" checked={this.props.select_data ? this.props.select_data["s1"] : ""} />
									</div>
									<div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox1" label="Select Type Of Crime" checked={this.props.select_data ? this.props.select_data["s2"] : ""} />
									</div>
									<div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Fill Report" checked={this.props.select_data ? this.props.select_data["s3"] : ""} />
									</div>
									<div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox3" label="Confirm Your ID" checked={this.props.select_data ? this.props.select_data["s4"] : ""} />
									</div>
									<div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox4" label="Submit Report" checked={this.props.select_data ? this.props.select_data["s5"] : ""} />
									</div>
								</div>
							</div>
						</Col>
						{this.state.loader ?
							<Col lg={8} className="mt-5 ml-5 mr-1">
								<Card className="text-center modal-text" style={{ minHeight: "300px" }}>

									<Loader
										type="TailSpin"
										color="#006239"
										height={100}
										width={100}//3 secs
									/> </Card>
							</Col> : ""}

						<Col lg={8} className={this.state.result ? "mt-5 ml-5 mr-1 " : "d-none"} >
							<Card className="text-center modal-text p-2"  >

								<div className="text-center modal-text"><h2 style={{ fontWeight: "700", color: "#1c9741", paddingTop: "80px" }}>Your report has been submitted successfully!</h2></div>
								<div className="text-center f_17">
								{this.props.anonymous ?"Write down this ID :":" Track your report with the ID:"}<strong >{this.state.add_data ? this.state.add_data.track_id : ""}</strong>
								</div>
								<div className="text-center f_17">
								{this.props.anonymous ? "You can track the progress of your report with the ID":""}
								</div>
								{!this.props.anonymous ? <div className="text-center f_17">This ID has been forward to your Email</div> : ""}
								<div className="text-center f_17 m-50 mb-4">
									<Link to="/"><Button className="bg-clr pt-2 pb-2">CLOSE</Button></Link>
								</div>
							</Card>
						</Col>



						<Col lg={9} className="pl-0 pr-0">

							<AvForm
								onValidSubmit={this.handleValidSubmit}
								className="form-horizontal"
								id="citizen-form"
							><div className={!this.state.continue && !this.state.nextPage ? "" : "d-none"}>
									<p className="black pl-4 pr-4 f-600 mt-4 f-18">Incident Reporting Form</p>
									<div className="white_bg mt-0 pl-4 pr-4 pt-4 pb-4" style={{ borderRadius: "10px", height: "100%" }}>
										<h5 className="black f-700">Report Type :</h5>
										<Row className="pl-3 pr-3 pt-3 pb-3">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">Report</label></div>
											</Col>
											<Col lg="10">
												{/* <AvInput type="text" name="report_type" id="text" value={this.props.report_id.id} placeholder={this.props.report_id.title}  /> */}
												<AvField
													name="report_type"
													type="select"
													autoComplete="off"
													placeholder="Select report type"
													value={this.props.report_id != null ? this.props.report_id.id : ""}
													validate={{
														required: {
															value: true,
															errorMessage: "Select report type "
														}
													}}
													onChange={e => this.setState({ report: e.target.value })}
												>
													{this.props.report_id != null ?
														<option value={this.props.report_id.id}>{this.props.report_id.title}</option> : ""}

												</AvField>
											</Col>
										</Row>
										<Col lg={4}>

										</Col>
										<h5 className="black f-700">Select the state where the incident occured:</h5>
										<Row className="pl-3 pr-3 pt-3 pb-0">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r"><span className="text-danger">*</span>State</label></div>
											</Col>
											<Col lg="10">
												<Select
													className={"react-select " + (this.state.selectedStateOptionsError ? "invalid-select-wrapper-feedback" : "")}
													classNamePrefix="react-select"
													placeholder="Select State"
													options={this.state.state}
													value={this.state.state_id}
													name="state_id"
													onChange={this.onChangeState}
													required="true"
												/>

											</Col>
										</Row>
										<Row className="pl-3 pr-3 pt-0 pb-0 mt-3">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">LGA</label></div>
											</Col>
											<Col lg="10">
												{this.state.state_loader ? <div className="text-center modal-text" style={{ minHeight: "300px" }}>
													<Loader
														type="TailSpin"
														color="#006239"
														height={100}
														width={100}//3 secs
													/> </div> :
													<AvField
														name="lga_id"
														type="select"
														id="lga-btn"
														autoComplete="off"
														placeholder="Select LGA"
														validate={{
															required: {
																value: true,
																errorMessage: "select LGA "
															}
														}}
														onChange={e => this.setState({ client: e.target.value })}
													>
														<option value="">Select LGA</option>
														{this.state.lgas ? this.state.lgas.map((data) => {
															return (<option value={data.id}>{data.name}</option>)
														}) : ""}

													</AvField>}
											</Col>
										</Row>
										<Row className="pl-3 pr-3 pt-0 pb-0">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">Town/City</label></div>
											</Col>
											<Col lg="10">
												<AvField
													name="city"
													type="text"
													validate={{
														required: {
															value: true,
															errorMessage: "City is required."
														}
													}}
												/>
											</Col>
										</Row>
										<Row className="pl-3 pr-3 pt-0 pb-0">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">Address</label></div>
											</Col>
											<Col lg="10">
												<AvField
													name="address"
													type="text"
													validate={{
														required: {
															value: true,
															errorMessage: "Address is required."
														}
													}}
												/>
											</Col>
										</Row>
										<Row className="pl-3 pr-3 pt-0 pb-0">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">Land Mark</label></div>
											</Col>
											<Col lg="10">
												<AvField
													name="land_mark"
													type="text"
												/>
											</Col>
										</Row>

										<h5 className="black f-700 pt-2">Date range this incident could have occurred :</h5>
										<Row className="pl-3 pr-3 pt-3 pb-0">
											<Col lg="2" className="pt-2">
												<div className="text-r"><Label className="black f-15 pt-2">From</Label></div>
											</Col>
											<Col lg="3" className="pt-2">
												{/* <AvField
													type="date"
													name="Fromdate"
													id="exampleDate"
													validate={{
														max: {
															value: today,
															errorMessage: "You can not select future date"
														}
													}}

													placeholder="date placeholder" /> */}
														<Flatpickr
													value=""
													placeholder="date "
													options={{ dateFormat: "m/d/Y", maxDate: new Date() }}
													onChange={date => { var Fromdate = new Date(Date.parse(date)); 
														this.setState({ Fromdate });
													} }
													className="form-control"
													name="Fromdate"
													validate={{
														max: {
															value: today,
															errorMessage: "You can not select future date"
														}
													}}
												/>
											</Col>
											<Col lg="3" className="pt-2">
												{/* <AvInput type="time" name="Fromtime" id="exampleTime" placeholder="date Time" /> */}
												<Flatpickr
												name="Fromtime"
												placeholder="time "
                                                value={this.state.Fromtime}
                                                options={{ enableTime: true, noCalendar: true, dateFormat: "h:i K" }}
                                                onChange={date => {
                                                    var Fromtime = date
                                                    this.setState({ Fromtime  });
                                                }}
                                                className="form-control"

                                            />
											</Col>
										</Row>
										<Row className="pl-3 pr-3 pt-3 pb-0">
											<Col lg="2" className="pt-2">
												<div className="text-r"><Label className="black f-15 pt-2">To</Label></div>
											</Col>
											<Col lg="3" className="pt-2">
												{/* <AvField
													type="date"
													name="todate"
													id="exampleDate"
													validate={{
														max: {
															value: today,
															errorMessage: "You can not select future date"
														}
													}}
													placeholder="date placeholder" /> */}
												<Flatpickr
													value=""
													placeholder="date "
													options={{ dateFormat: "m/d/Y", maxDate: new Date() }}
													onChange={date => { var todate = new Date(Date.parse(date)); 
														this.setState({ todate });
													} }
													className="form-control"
													name="todate"
													validate={{
														max: {
															value: today,
															errorMessage: "You can not select future date"
														}
													}}
												/>
											</Col>
											<Col lg="3" className="pt-2">
												{/* <AvInput type="time" name="totime" id="exampleTime" placeholder="date Time" /> */}
												<Flatpickr
												name="totime"
												placeholder="time "
                                                value={this.state.totime}
                                                options={{ enableTime: true, noCalendar: true, dateFormat: "h:i K" }}
                                                onChange={date => {
                                                    var totime = date;

                                                    this.setState({ totime });
                                                }}
                                                className="form-control"

                                            />
											</Col>
										</Row>

										<h5 className="black f-700 mt-4">Please enter a brief description of the incident:</h5>
										<Row className="pl-3 pr-3 pt-3 pb-0">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">Description</label></div>
											</Col>
											<Col lg="10">
												<AvField
													type="textarea"
													name="description"
													id="text" placeholder=""
													validate={{
														required: {
															value: true,
															errorMessage: "Description is required"
														}
													}} />

											</Col>
										</Row>

										<h5 className="black f-700 mt-4">Please upload a picture or video evidence :</h5>
										<Row className="pl-3 pr-3 pt-3 pb-0">
											<Col lg="2">
												<div className="text-r"><label className="black f-15 pt-2 text-r">Evidence</label></div>
											</Col>
											<Col lg="10">
												<AvGroup>
													<AvInput
														// tag={CustomInput}
														// className="custom-file-label"
														type="file"
														name="evidence"
														id="evidence"
														accept=".mp4,.3gp,.jpeg,.jpg,.png"
														placeholder=""
														required
														validate={{
															required: {
																value: true,
																errorMessage: "Evidence is required"
															}
														}}
														onChange={this.onChange}
													/>
													<AvFeedback>Evidence is required</AvFeedback>
												</AvGroup>
											</Col>
										</Row>
										<Row className="mb-4" style={{ paddingBottom: "30px" }}>
											<Col lg="12">
												<div className="text-center mt-4 d-flex justify-content-center">
													<Button type="button" className="ml-4 mt-2 mr-4 bg-clr pt-2 pb-2" onClick={this.props.accept}>PREVIOUS</Button>
													{this.props.anonymous ? <Button id="next-btn" className="ml-4 mt-2 mr-4 green_bg_bg pt-2 pb-2" >SUBMIT</Button> : <Button id="next-btn" className="ml-4 mt-2 mr-4 green_bg_bg pt-2 pb-2">NEXT</Button>}
												</div>
											</Col>
										</Row>
									</div>
								</div>
								{!this.props.anonymous && this.state.nextPage ?
									<Col lg={9} className="">
										<p className="black f-600 mt-4 f-18">Reporter's Information</p>
										<div className="white_bg mt-0 pl-4 pr-4 pt-4 pb-4" style={{ borderRadius: "10px", height: "100%" }}>
											<h5 className=" black f-700">I am :</h5>
											<Row className="pl-3 pr-3 pt-3 pb-3">
												<Col lg="12">
													<div className="pt-2 pb-2">
														<AvRadioGroup name="person" label="" required>
															<AvRadio customInput label="The impacted person" value="1" />
															<AvRadio customInput label="Reporting on behalf of the impacted" value="2" />
														</AvRadioGroup>
													</div>
												</Col>
											</Row>


											<h5 className="black f-700">My Contact Information:</h5>
											<Row className="pl-3 pr-3 pt-3 pb-2">
												<Col lg="4" className="pt-2">
													<div className="d-flex">
														<label className="black pt-2">First Name</label>
														<AvField
															name="first_name"
															type="text"
															validate={{
																required: {
																	value: true,
																	errorMessage: "First name is required."
																},
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name of first name"
																},
																minLength: {
																	value: 2,
																	errorMessage: "The name must be between 2 and 180 characters"
																},
																maxLength: {
																	value: 180,
																	errorMessage: "The name must be between 2 and 180 characters"
																}
															}}
														/>
													</div>
												</Col>
												<Col lg="4" className="pt-2">
													<div className="d-flex">
														<label className="black pt-2">Middle Name</label>
														<AvField
															name="middle_name"
															type="text"
															validate={{
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name of middle name"
																},
																minLength: {
																	value: 2,
																	errorMessage: "The name must be between 2 and 180 characters"
																},
																maxLength: {
																	value: 180,
																	errorMessage: "The name must be between 2 and 180 characters"
																}
															}}
														/>
													</div>
												</Col>
												<Col lg="4" className="pt-2">
													<div className="d-flex">
														<label className="black pt-2">Last Name</label>
														<AvField
															name="last_name"
															type="text"
															validate={{
																pattern: {
																	value: "^[a-zA-Z ,.'-]+$",
																	errorMessage: "Invalid name of last name"
																},
																minLength: {
																	value: 2,
																	errorMessage: "The name must be between 2 and 180 characters"
																},
																maxLength: {
																	value: 180,
																	errorMessage: "The name must be between 2 and 180 characters"
																}
															}}
														/>
													</div>
												</Col>

											</Row>
											<Row className="pl-3 pr-3 pt-2 pb-2">
												<Col lg="6">
													<Row>
														<Col lg="2">
															<label className="black pt-2">Phone</label>
														</Col>
														<Col lg="10">
															<AvField
																name="phone_number"
																value={this.state.mobile_number}
																autoComplete="off"
																validate={{
																	required: {
																		value: true,
																		errorMessage: "Mobile number is required"
																	},
																	pattern: {
																		value: "^(\\d{10,12},?)+$",
																		errorMessage: "Invalid phone number"
																	}
																}}
																type="text"
																className="form-control"
																id="Phone_number"
																placeholder="Enter Mobile number" />
														</Col>
													</Row>
												</Col>
												<Col lg="6">
													<Row>
														<Col lg="2">
															<label className="black pt-2">Email</label>
														</Col>
														<Col lg="10">
															<AvField
																type="email"
																name="email"
																id="text"
																placeholder=""
																validate={{
																	required: {
																		value: true,
																		errorMessage: "email  is required"
																	}

																}} />

														</Col>
													</Row>
												</Col>
											</Row>

											<Row className="pl-3 pr-3 pt-0 pb-0">
												<Col lg="1">
													<div className=""><label className="black f-15 pt-2 text-r">Address</label></div>
												</Col>
												<Col lg="11">
													<AvField
														name="contact_address"
														type="text"
														validate={{
															required: {
																value: true,
																errorMessage: "Address  is required"
															}

														}} />
												</Col>
											</Row>

											<Row>
												<Col lg="12">
													<div className="text-center mt-4">
														<Button className="ml-4 mr-4 bg-clr mt-2 pt-2 pb-2" onClick={this.tog_submit3}>PREVIOUS</Button>
														<Button className="ml-4 mr-4 mt-2 green_bg_bg pt-2 pb-2" id="next2-btn" >SUBMIT</Button>
													</div>
												</Col>
											</Row>

										</div>

									</Col> : ""}

							</AvForm>


						</Col>

					</Row>
					<Row>

					</Row>
					<Row>
						<Modal
							isOpen={this.state.modal_submit}
							toggle={this.tog_submit}
							size="lg"
						>
							<ModalHeader toggle={() => this.setState({ modal_submit: false })}>
							</ModalHeader>
							<ModalBody>
								<div className="text-center modal-text"><h2 style={{ fontWeight: "700", color: "#62000d" }}>Are you sure want to submit this report?</h2></div>
								<div className="text-center m-40 mt-4 mb-4">
									<Button className="bg_pop f-18 mt-2 ml-4 mr-4 btn_modal" onClick={this.tog_submit}>NO</Button>
									<Button className="green_bg f-18 ml-4 mr-4 mt-2 btn_modal" onClick={this.tog_submit2} >YES</Button>
								</div>
							</ModalBody>
						</Modal>
					</Row>


				</div>
			</React.Fragment >
		)
	}
}


export default AcceptTermsandConditions;