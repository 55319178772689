// @flow
import React,{ Component } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, Media, Row, Col ,Modal,
	ModalHeader, ModalBody,Button , CustomInput} from 'reactstrap';
import { AvForm, AvField ,AvInput , AvGroup} from 'availity-reactstrap-validation';

import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
// import { makeColorGradient } from '../../helpers/helperUtils';
// import Loader from '../../components/Loader';
import { getLoggedInUser } from '../../helpers/authUtils';
import Loader from "react-loader-spinner";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import classNames from 'classnames';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactPlayer from 'react-player'
import * as FeatherIcon from 'react-feather';
import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer ,VideoRenderer , DocRenderer} from "react-doc-viewer";
import PDFimage from '../../assets/images/users/pdf.jpg';
import AlbumModal from "../albumModal"
import videoimage from '../../assets/images/users/video.jpg';
import Fileimage from '../../assets/images/users/file.png';


const image_extension = ["jpg","png","jpeg"]
const video_extension = ["mp4", "mov",  "webm", "mpeg-2" , "3gp"] 
const audio_extension = ["wav", "mp3", "aac", "ogg", ]


/* Chat Item Avatar */
const ChatItemAvatar = ({ userAvatar, postedOn }) => {
    return (
        <div className="chat-avatar">
            <img src={userAvatar} alt={userAvatar} />
        </div>
    );
};

/* Chat Item Text */
const ChatItemText = ({userName, text, postedOn, img , image , openImage}) => {
	if(img){
		var type = img.split(".").pop()
	}
	var email_regex = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
	var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
	var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
	text = text.replace(email_regex,'<a target="_blank" href="mailto:$1">$1</a>') 
	text = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
	text = text.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
	
	
    return (
        <div className="conversation-text">
            <div className="ctext-wrap">
                <i>{userName}</i>
				{img ? 
				type.toLowerCase() == "pdf" ? <img src={PDFimage} onClick={(e) => { openImage(e, img) }} /> :
				type.toLowerCase() == "avi"  || type.toLowerCase() == "wmv" ? <a target="_blank" href={img} style={{ wordBreak: "break-all", textAlign: "left" }} className="waves-effect btn black  mt-0 mr-2 "><img src={videoimage}  /></a> :
					image_extension.indexOf(type.toLowerCase()) != -1 ?
						<img src={img} width="250px" height="150px" onClick={(e) => { openImage(e, img) }} />
						:
						video_extension.indexOf(type.toLowerCase()) != -1 ?
							<video width="250px" height="150px" controls onClick={(e) => { openImage(e, img) }}  >
								<source src={img} type="video/mp4" />
							</video> :
							audio_extension.indexOf(type.toLowerCase()) != -1 ?
								<audio controls >
									<source src={img} type="audio/ogg" />
								</audio> : <a target="_blank" href={img} style={{ wordBreak: "break-all", textAlign: "left" }} className="waves-effect btn black  mt-0 mr-2 "><img width="150px"  src={Fileimage} /> </a> : ""}
               <pre className="conversation-list-pre" dangerouslySetInnerHTML={{ __html: text }} ></pre>
            </div>
			<div className="mt-1">{postedOn}</div>
        </div>
    );
};

/* Chat Item */
const chatItemDefaultProps = {
    placement: '',
    children: PropTypes.object,
    className: '',
};

const ChatItem = ({ children, placement, className }) => {
    return <li className={classNames('clearfix', { odd: placement === 'right' }, className)}>{children}</li>;
};

ChatItem.defaultProps = chatItemDefaultProps;


/**
 * Renders the ChatList
 */
class ChatForm extends Component {
    constructor(props) {
        super(props);
		this.state = {			
			message:'',
			loader: false
        };
        this.handleValidMessageSubmit = this.handleValidMessageSubmit.bind(this);
    }

    /**
     * Handle valid form submission
     */
    handleValidMessageSubmit = (event, values) => {
		var formData = new FormData(document.getElementById("chat-form"));
		console.log(values)
        if(values.message!==""){
			this.props.onNewMessagesPosted(formData , this.openModals);
			this.setState({
				message:""
			});
		}
    };


	handleValidIMGSubmit = (event, values) => {
		var formData = new FormData(document.getElementById("img-form"));
		this.props.onNewMessagesPosted(formData, this.openModal);
		this.setState({
			message2: "",
			loader: true
		});
	}
	openModal = () => {
		this.setState({ open: !this.state.open , loader: false  })
	}
	openModals = () => {
	}
	checkMimeType=(event)=>{
		let files = event.target.files 
		let err = ''
		// const video_extension = [  "mpeg-2"]
		const types = ['.mp4', '.3gp', '.jpeg', '.jpg', '.png', '.mov','.webm','.mpeg-2','.wav','.mp3','.aac','.ogg' ,'.doc','.docx','.ppt','.pdf','html','.xlsx',".xls",".txt"
		, 'video/quicktime','video/mp4', 'video/3gpp', 'image/jpeg', 'image/jpg', 'image/png' ,'audio/mpeg' , 'audio/vnd.dlna.adts' , 'application/ogg' , 'application/vnd.ms-powerpoint' ,'video/webm' ,'audio/wav','application/pdf','video/mpeg','text/html','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ,'application/vnd.openxmlformats-officedocument.wordprocessingm',"application/vnd.ms-excel" ,"text/plain" ,"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ,"application/msword"]
		for(var x = 0; x<files.length; x++) {
			if (files[x].size > 20000000) { 
			   err += files[x].name+' file size cannot be greater than 20MB';
			}else if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container 
				err += files[x].name + ' is not a supported file';
			}

		};

		if (err !== '') { // if message not same old that mean has error 
			event.target.value = null;
			swal("Warning", err, "warning");	
			return false; 
		}
		return true;
	}
	
	onChange = e => {
		switch (e.target.name) {
		  case 'filename':
			if(e.target.files[0]){
				if(this.checkMimeType(e)){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		 }
	};



    render() {
        return (
			<Row form className="align-items-center chat-message-wrapper" >
			<AvForm
				onValidSubmit={this.handleValidMessageSubmit}
				className="needs-validation mt-2"
				noValidate
				name="chat-form"
				id="chat-form">
				<Row form className="align-items-center chat-message-wrapper" >
					<Col>
						<AvField
							name="message"
							type="textarea"
							value={this.state.message}
							style={{resize: "none"}}
							onChange={(message) => {
								this.setState({
									message: message.target.value
								});
							}}
						/>
						<div className="d-none">
						<AvInput  type="file" id="file_name" name="file_name" value=""></AvInput>
						</div>
					</Col>

					<Col className="col-auto">
						<button type="file" type="button" onClick={this.openModal} className="btn btn-success chat-send btn-block">
							<FeatherIcon.Paperclip />
						</button>
					</Col>
					<Col className="col-auto">
						<button type="submit" className="btn btn-success chat-send btn-block">
							<FeatherIcon.Send />
						</button>
					</Col>
				</Row>
			</AvForm>

			<Modal
					isOpen={this.state.open}
					toggle={this.openModal}
					size="lg"
					backdrop="static"
				>
					<ModalHeader toggle={() => this.setState({ open: false })}>
					</ModalHeader>
					<ModalBody>
					<div className={!this.state.loader ? "": "d-none"}>
						<AvForm onValidSubmit={this.handleValidIMGSubmit}
							className="needs-validation mt-2"
							noValidate
							name="img-form"
							id="img-form">
							<Row className="pl-3 pr-3 pt-3 pb-0">
								<Col lg="12">
								<AvGroup className="">
										{/* <Label for="name">Name
										<div className="invalid-feedback d-none " id="import_file_error">Please choose the csv file to import</div>
										</Label> */}
										<AvField tag={CustomInput} type="file" 
											name="filename" 
											id="filename"
											// accept=".mp4,.3gp,.jpeg,.jpg,.png"
											placeholder="" 
											validate={{ 	
												required: {
													value: true,
													errorMessage: "This field is required"
												}
											}}
											onChange={this.onChange}
											required
										/>
									</AvGroup>
								</Col>
								<Col lg="12">
									<AvField
										name="message"
										type="textarea"
										placeholder="Enter a message"
										value={this.state.message2}
										style={{resize: "none"}}
										onChange={(message2) => {
											this.setState({
												message2: message2.target.value
											});
										}}
									/></Col>
							<Col className="col-auto">
										<button className="btn btn-success chat-send btn-block" type="submit">
											Send
										</button>
										</Col>
										<Col className="col-auto">
										<button className="btn btn-success chat-send btn-block"  type="button">
											Close
										</button></Col>
							</Row>
						</AvForm>
						</div>
						{this.state.loader ?
							<div className={this.state.loader ? "": "d-none"}>
							<Loader
								type="TailSpin"
								color="#006239"
								height={100}
								width={100}//3 secs
							/> </div>: ""}
					</ModalBody>
				</Modal>
		</Row>
        );
    }
}

export default class ChatWindow extends Component {
	_isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
			dataLoading:true,
			messages:[],
			image:false,
			selectImage:""
        };
		this.handleNewMessagePosted = this.handleNewMessagePosted.bind(this);
    }
	
	componentDidMount() {
		this._isMounted=true;
		this.loadData(true);
		var that = this;
		this.interval = setInterval(() =>{
			that.loadData(false);
		}, 10000);
    }
	
	componentWillUnmount() {
		this._isMounted = false;

		const loggedInUser = getLoggedInUser();
		if(loggedInUser!=null){
			if(loggedInUser.role==='Control Center'){
				clearInterval(this.interval);
			}else if(this.interval!=null && typeof this.interval!='undefined'){
				clearInterval(this.interval);
			}
		}
	}
	
	componentDidUpdate(prevProps,prevState){
		if(this.props.call_center_id!=null && JSON.stringify(this.props.call_center_id)!=JSON.stringify(prevProps.call_center_id)){
			this.loadData(true);
		}
	}
	
	toggleLoader = () => {
        this.setState(prevState => ({
            dataLoading: !prevState.dataLoading,
        }));
    };
	
	 /**
     * Handle new message posted
     */
    handleNewMessagePosted = (formData , openModal) => {
        // save new message
        const loggedInUser = getLoggedInUser();
		console.log(this.props.call_center_id.id,"this.props.call_center_id.id")	
		formData.append("so_fssam_id", this.props.call_center_id.id);
		formData.append("user_id", loggedInUser.id);
		axios.post(apiURL+'/api/add-so-fssam-messages',formData).then((res)=>{
			if(res.data.status==1){
				openModal()
				var data = res.data.data;
				console.log(res.data.data,"data")
				data.userName = loggedInUser.first_name+" "+loggedInUser.last_name;
				data.userPic = apiURL+'/uploads/users/'+loggedInUser.profile_pic;
				if (data.filename != null) {
					data.filename = apiURL + '/uploads/messages/' + data.filename
				} 
				data.position = data.user_id==loggedInUser.id ? "right":"left";
				data.postedOn = moment(data.timestamp*1000).format('D/MM/YYYY H:mm');
				this.setState({
					messages: this.state.messages.concat(data),
				});
				var that = this;
				setTimeout(function(){ 
					that.messagesEnd.scrollIntoView();
				}, 100);
			}
			var that=this;
			setTimeout(function(){ 
				that.setState({
					alertStatus:null
				});
			}, 5000);
		}).catch(e => console.log("Addition failed , Error ", e));
    };
	
	loadData = (scrollTo) => {	
		if(this.props.call_center_id!=null){
			const loggedInUser = getLoggedInUser();
			
			var post_data={};
			post_data.so_fssam_id = this.props.call_center_id.id;
			post_data.user_id = loggedInUser.id;
			console.log(post_data)
			axios.post(apiURL+'/api/so-fssam/messages',post_data).then((res)=>{ 
				var messages = res.data.data;
				console.log( res.data.data)
				messages = messages.map(row=>{
					row.userPic = apiURL+'/uploads/users/'+row.profile_pic;
					if (row.filename != null) {
						row.filename = apiURL + '/uploads/messages/' + row.filename
					} else {
						row.filename = ""
					}

					row.position = row.user_id==loggedInUser.id ? "right":"left";
					row.postedOn = moment(row.timestamp*1000).format('D/MM/YYYY H:mm');
					return row;
				})
				
				const changeLength = messages.length !== this.state.messages;
				
				if(res.data.status===1){
					this.setState({ 
						dataLoading:false,
						messages
					});
					if(scrollTo){
						var that = this;
						setTimeout(function(){ 
							that.messagesEnd.scrollIntoView();
						}, 100);
					}
				}else{
					this.setState({ 
						dataLoading:false,
						messages:[],
					});
				}
				var that=this;
				setTimeout(function(){ 
					that.setState({
						alertStatus:null
					});
				}, 5000);
			}).catch(e => console.log("Addition failed , Error ", e));
		}		
    }


	
	
	
	render() {
		const { messages } = this.state;	
		
		var mda_name='';
		if(this.props.call_center_id!=null){
			mda_name = this.props.call_center_id.name;
		}
		console.log(this.props.call_center_id)
		
		return (<Card>
                <CardBody className="pt-2 pb-1">
                    <h5 className="mb-4 header-title">{mda_name}</h5>

                    {mda_name!=='' ? <div className="chat-conversation" id="contact-ministry-chat">
                        <PerfectScrollbar style={{ minHeight: 'calc(70vh - 48px)', maxHeight: 'calc(70vh - 48px)', width: '100%' }} className="whats-like-chat" >
                            <ul className={classNames('conversation-list', "full-height-chat")}>
                                {messages.map((message, i) => {
                                    return (
                                        <ChatItem key={i} placement={message.position}>
                                            {message.userPic && (
                                                <ChatItemAvatar
                                                    userAvatar={message.userPic}
                                                    postedOn={message.postedOn}
                                                />
                                            )}
                                            <ChatItemText userName={message.userName} text={message.message} postedOn={message.postedOn} img={message.filename} openImage={this.props.openImage} image={this.state.image} />
                                        </ChatItem>
                                    );
                                })}
                            </ul>
							<div style={{ float:"left", clear: "both" }}
								 ref={(el) => { this.messagesEnd = el; }}>
							</div>
                        </PerfectScrollbar>

                        {/* chat form */}
                        <ChatForm onNewMessagesPosted={this.handleNewMessagePosted} />
                    </div>:<PerfectScrollbar style={{ minHeight: 'calc(70vh + 45px)', maxHeight: 'calc(70vh + 45px)', width: '100%' }} className="align-items-center d-flex justify-content-center flex-wrap">
                            <div className="text-center">
								<img src={apiURL+'/uploads/users/avatar.jpg'} alt={"ministry"} style={{width:'200px'}} />
								<h4 className="text-muted pt-2">Click on the user to start communication</h4>
							</div>
                        </PerfectScrollbar>}
                </CardBody>
				

            </Card>);
	}
};

