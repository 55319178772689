import React, { Component } from 'react';
import {
	Row, Col, Card,
	Button, CardBody, Modal,
	ModalHeader, ModalBody,
	ModalFooter, Input, FormGroup,
	UncontrolledDropdown,
	DropdownMenu, DropdownItem,
	InputGroupAddon, Label, CustomInput,
	DropdownToggle
} from 'reactstrap';

import { Link } from 'react-router-dom';
import alert1 from '../../assets/images/alert.jpg';
import Topbar from '../../components/Topbarin';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
import logo from '../../assets/images/logo-fav.png';
import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';
import FillReport from "./fill-report"

class SelectTypeofCrime extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modal_standard: false,
			modal_yes: false,
			report_types: [],
			report_id: null,
			redirect: false,
			anonymous: false,
			select_data: {
					s1: true,
					s2: true,
					s3: false,
					s4: false,
					s5: false
				}


		};
		this.tog_standard = this.tog_standard.bind(this);
		this.tog_yes = this.tog_yes.bind(this);
		this.accept = this.accept.bind(this);
		this.NotAnonymous = this.NotAnonymous.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);


	}

	onSelectChange(id){
		let select_data = this.state.select_data;
		select_data[id]=true;
		this.setState({ select_data})
	}


	tog_standard(id) {
		this.setState(prevState => ({
			modal_standard: !prevState.modal_standard,
			report_id: id
		}));
	}
	tog_yes() {
		this.setState(prevState => ({
			
			modal_yes: !prevState.modal_yes,
			anonymous: true
		}));

		if(!this.state.modal_yes){
			this.setState({ modal_standard:!this.state.modal_standard,})
		}
	}

	NotAnonymous() {
		this.onSelectChange("s3")
		this.setState(prevState => ({
			anonymous: false,
			redirect: true,
			modal_standard: false
		}));

	}


	componentDidMount() {
		this._isMounted = true;
		
		document.body.classList.add('citizens');
		document.body.classList.add('accept_terms');

		axios.get(apiURL + '/api/active-states').then((res) => {

			this.setState({ report_types: res.data.report_types })
		}).catch(e => {
			console.log("Addition failed , Error", e);
		});
	}

	accept() {
		console.log("aaaaaaa")
		this.onSelectChange("s3")
		this.setState({
			redirect: !this.state.redirect,
			modal_yes: false,
			modal_standard: false
		});

	}


	DontAccept(){
			this.setState({
			redirect: !this.state.redirect})

	}
	render() {
		console.log(this.state.report_types)
		console.log(this.state.report_id)
		console.log(this.state.redirect)
		console.log(this.state.anonymous)
		return (
			<React.Fragment className="pl-0 pr-0 ml-0 mr-0" >
				<Topbar />

				<div className="mt-4 container-fluid terms-logo-hide pl-0 pr-0 ml-0 mr-0">
					<div className={!this.state.redirect ? "" : "d-none"}>
						<Row className=" terms-logo-hide">

							<Col lg={3} className="">
								<div className="white_bg pl-2 pr-0 ml-0 mr-0" style={{ height: "100%", minHeight:'100vh' }}>
									<div className="text-center pt-4">
										<img src={logo} alt="" height="100" />
									</div>
									<div>
										<p>PROGRESS FLOW</p>
									</div>
									<div className="radio_custom_sirp">
										<div className="mt-3 mb-3">
											<CustomInput type="checkbox" id="exampleCustomCheckbox" className="" label="Accept Terms & Conditions" checked={this.state.select_data.s1} />
										</div>
										<div className="mt-3 mb-3">
											<CustomInput type="checkbox" id="exampleCustomCheckbox1" label="Select Type Of Crime" checked={this.state.select_data.s2} />
										</div>
										<div className="mt-3 mb-3">
											<CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Fill Report" checked={this.state.select_data.s3} />
										</div>
										<div className="mt-3 mb-3">
											<CustomInput type="checkbox" id="exampleCustomCheckbox3" label="Confirm Your ID" checked={this.state.select_data.s4} />
										</div>
										<div className="mt-3 mb-3">
											<CustomInput type="checkbox" id="exampleCustomCheckbox4" label="Submit Report" checked={this.state.select_data.s5} />
										</div>
									</div>
								</div>
							</Col>

							<Col lg={9} className={!this.state.redirect ? "" : "d-none"}>

								<div className=" mt-4" style={{ borderRadius: "10px", height: "100%" }}>
									<div>
										<h5 className="text-center black pt-4 pb-1">Please select the type of crime or incident to help us direct your report to the right local force.</h5>
									</div>
									<Row className="ml-2 mt-4 mr-2">
										{this.state.report_types ? this.state.report_types.map((data) => {
											return (
												<Col lg={4}>
													<div className="mb-4 card-style" onClick={() => this.tog_standard(data)}>
														<p className="white f-600 f-18 pl-3 pr-3 pt-2 pb-1" >{data.title}</p>
														<div className="ml-4 mr-4 mt-0">
															<Button block className=" black f-600 white_bg f-16">
																<p style={{wordWrap:"break-word"}}>{data.sub_title}</p>
																</Button>
														</div>
													</div>
												</Col>
											)
										}) : ""}

									</Row>
								</div>

							</Col>

						</Row>
					</div>
					<Row>
						<Modal
							isOpen={this.state.modal_standard}
							toggle={this.tog_standard}
							size="lg"
						>
							<ModalHeader toggle={() => this.setState({ modal_standard: false })}>
							</ModalHeader>
							<ModalBody>
								<div className="text-center"></div>
								<div className="text-center modal-text"><h3 style={{ fontWeight: "700", paddingTop: "30px" }}>Do you want anonymous report?</h3></div>
								<div className="text-center m-50 mt-4 mb-4" >
									<Button className="green_bg f-18 ml-4 mt-2 mr-4 btn_modal" onClick={this.tog_yes}>YES</Button>
									<Button className="green_bg f-18 ml-4 mr-4 mt-2 btn_modal" onClick={this.NotAnonymous}>NO</Button>
								</div>
							</ModalBody>
						</Modal>
					</Row>
					<Row>
						<Modal
							isOpen={this.state.modal_yes}
							toggle={this.tog_yes}
							size="lg"
						>
							<ModalHeader toggle={() => this.setState({ modal_yes: false })}>
							</ModalHeader>
							<ModalBody>
								<div className="text-center"><img className="img-fluid mt-1 mb-4" src={alert1} alt="" /></div>
								<div className="text-center modal-text"><h2 style={{ fontWeight: "800", color: "#f30b0b" }}>WARNING</h2></div>
								<div className="text-center modal-text pt-2">
									<p className="f-19">If your report is found to be fake or false,</p>
									<p className="f-19">you will be <strong>APPREHENDED !</strong></p>
								</div>
								<div className="text-center m-40 mt-4 mb-4">
									<Button className="bg_pop f-18 ml-4 mt-2 mr-4 btn_modal" onClick={this.tog_yes}>I Don't Accept</Button>
									<Button className="green_bg f-18 ml-4 mt-2 mr-4 btn_modal" onClick={this.accept}>I Accept</Button>
								</div>
							</ModalBody>
						</Modal>
					</Row>
				</div>

				<div className={this.state.redirect ? "" : "d-none"}>
					<FillReport report_id={this.state.report_id} anonymous={this.state.anonymous} selectChange={this.onSelectChange} select_data={this.state.select_data}  accept={this.accept}/>
				</div>

			</React.Fragment>

		)
	}
}


export default SelectTypeofCrime;