// @flow
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import {
	Card, CardBody, Media, Row, Col, Modal,
	ModalHeader, ModalBody, Button, CustomInput
} from 'reactstrap';
import { AvForm, AvField, AvInput, AvGroup } from 'availity-reactstrap-validation';

import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
// import { makeColorGradient } from '../../helpers/helperUtils';
// import Loader from '../../components/Loader';
import { getLoggedInUser } from '../../helpers/authUtils';
import { default as Select } from 'react-select'
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactPlayer from 'react-player'
import * as FeatherIcon from 'react-feather';
import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer, VideoRenderer, DocRenderer } from "react-doc-viewer";
import AlbumModal from "../albumModal"
import PDFimage from '../../assets/images/users/pdf.jpg';
import Fileimage from '../../assets/images/users/file.png';
import videoimage from '../../assets/images/users/video.jpg';
import Iconimage from '../../assets/images/logo-fav.png';
import swal from 'sweetalert';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Loader from "react-loader-spinner";



const image_extension = ["jpg", "png", "jpeg"]
const video_extension = ["mp4", "mov", "webm", "mpeg-2", "3gp"]
const audio_extension = ["wav", "mp3", "aac", "ogg",]
/* Chat Item Avatar */
const ChatItemAvatar = ({ userAvatar, postedOn }) => {
	return (
		<div className="chat-avatar">
			<img src={userAvatar} alt={userAvatar} />
		</div>
	);
};

/* Chat Item Text */
const ChatItemText = ({ userName, text, postedOn, img, image, openImage, message_type }) => {
	if (message_type == 0) {
		if (img) {
			var type = img.split(".").pop()
		}
		var email_regex = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
		var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		console.log(text)
		text = text.replace(email_regex, '<a target="_blank" href="mailto:$1">$1</a>')
		text = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
		text = text.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');


		return (
			<div className="conversation-text">
				<div className="ctext-wrap">
					<i>{userName}</i>
					{img ?
						type.toLowerCase() == "pdf" ? <img src={PDFimage} onClick={(e) => { openImage(e, img) }} /> :
							type.toLowerCase() == "avi" || type.toLowerCase() == "wmv" ? <a target="_blank" href={img} style={{ wordBreak: "break-all", textAlign: "left" }} className="waves-effect btn black  mt-0 mr-2 "><img src={videoimage} /></a> :
								image_extension.indexOf(type.toLowerCase()) != -1 ?
									<img src={img} width="250px" height="150px" onClick={(e) => { openImage(e, img) }} />
									:
									video_extension.indexOf(type.toLowerCase()) != -1 ?
										<video width="250px" height="150px" controls onClick={(e) => { openImage(e, img) }}  >
											<source src={img} type="video/mp4" />
										</video> :
										audio_extension.indexOf(type.toLowerCase()) != -1 ?
											<audio controls >
												<source src={img} type="audio/ogg" />
											</audio> : <a target="_blank" href={img} style={{ wordBreak: "break-all", textAlign: "left" }} className="waves-effect btn black  mt-0 mr-2 "><img width="250px" height="150px" src={Fileimage} /> </a> : ""}
					<div dangerouslySetInnerHTML={{ __html: text }} ></div>
				</div>
				<div className="mt-1">{postedOn}</div>
			</div>
		);
	} else if (message_type == 2) {
		var loggedInUser = getLoggedInUser();
		if (userName == loggedInUser.first_name) {
			return (
				<div className="text-center"><span className="px-2 py-1" style={{ borderRadius: '4px', color: '#000', backgroundColor: "#a1ebff" }}>You Joined</span></div>
			)
		} else {
			return (<div className="text-center"><span className="px-2 py-1" style={{ borderRadius: '4px', color: '#000', backgroundColor: "#a1ebff" }}>{userName} Joined</span></div>)
		}


	} else if (message_type == 3) {
		var loggedInUser = getLoggedInUser();
		if (userName == loggedInUser.first_name) {
			return (
				<div className="text-center"><span className="px-2 py-1" style={{ borderRadius: '4px', color: '#000', backgroundColor: "#a1ebff" }}>You was removed</span></div>
			)
		} else {
			return (<div className="text-center"><span className="px-2 py-1" style={{ borderRadius: '4px', color: '#000', backgroundColor: "#a1ebff" }}>{userName} was removed</span></div>)
		}
	}

};

/* Chat Item */
const chatItemDefaultProps = {
	placement: '',
	children: PropTypes.object,
	className: '',
};

const ChatItem = ({ children, placement, className }) => {
	return <li className={classNames('clearfix', { odd: placement === 'right' }, className)}>{children}</li>;
};

ChatItem.defaultProps = chatItemDefaultProps;


/**
 * Renders the ChatList
 */
class ChatForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: '',
			loader: false
		};
		// this.handleValidMessageSubmit = this.handleValidMessageSubmit.bind(this);
	}

	/**
	 * Handle valid form submission
	 */
	handleValidMessageSubmit = (event, values) => {
		var formData = new FormData(document.getElementById("chat-form"));
		console.log(values)
		if (values.message !== "") {
			this.props.onNewMessagesPosted(formData, this.openModals);
			this.setState({
				message: ""
			});
		}
	};


	handleValidIMGSubmit = (event, values) => {
		var formData = new FormData(document.getElementById("img-form"));
		this.props.onNewMessagesPosted(formData, this.openModal);
		this.setState({
			message2: "",
			loader: true
		});
	}
	openModal = () => {
		this.setState({ open: !this.state.open, loader: false })
	}
	openModals = () => {
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let err = ''
		// const video_extension = [  "mpeg-2"]
		const types = ['.mp4', '.3gp', '.jpeg', '.jpg', '.png', '.mov', '.webm', '.mpeg-2', '.wav', '.mp3', '.aac', '.ogg', '.doc', '.docx', '.ppt', '.pdf', 'html', '.xlsx', ".xls", ".txt"
			, 'video/quicktime', 'video/mp4', 'video/3gpp', 'image/jpeg', 'image/jpg', 'image/png', 'audio/mpeg', 'audio/vnd.dlna.adts', 'application/ogg', 'application/vnd.ms-powerpoint', 'video/webm', 'audio/wav', 'application/pdf', 'video/mpeg', 'text/html', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingm', "application/vnd.ms-excel", "text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"]
		for (var x = 0; x < files.length; x++) {
			if (files[x].size > 20000000) {
				err += files[x].name + ' file size cannot be greater than 20MB';
			} else if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container 
				err += files[x].name + ' is not a supported file';
			}

		};

		if (err !== '') { // if message not same old that mean has error 
			event.target.value = null;
			swal("Warning", err, "warning");
			return false;
		}
		return true;
	}

	onChange = e => {
		switch (e.target.name) {
			case 'filename':
				if (e.target.files[0]) {
					if (this.checkMimeType(e)) {
						e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
					} else {
						e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
					}
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};

	render() {
		return (
			<Row form className="align-items-center chat-message-wrapper" >
				<AvForm
					onValidSubmit={this.handleValidMessageSubmit}
					className="needs-validation mt-2"
					noValidate
					name="chat-form"
					id="chat-form">
					<Row form className="align-items-center chat-message-wrapper" >
						<Col>
							<AvField
								name="message"
								type="textarea"
								value={this.state.message}
								style={{ resize: "none" }}
								onChange={(message) => {
									this.setState({
										message: message.target.value
									});
								}}
							/>
							<div className="d-none">
								{/*  */}
							</div>
						</Col>

						<Col className="col-auto">
							<button type="file" type="button" onClick={this.openModal} className="btn btn-success chat-send btn-block">
								<FeatherIcon.Paperclip />
							</button>
						</Col>
						<Col className="col-auto">
							<button type="submit" className="btn btn-success chat-send btn-block">
								<FeatherIcon.Send />
							</button>
						</Col>
					</Row>
				</AvForm>

				<Modal
					isOpen={this.state.open}
					toggle={this.openModal}
					size="lg"
					backdrop="static"
				>
					<ModalHeader toggle={() => this.setState({ open: false })}>
					</ModalHeader>
					<ModalBody>
						<div className={!this.state.loader ? "" : "d-none"}>
							<AvForm onValidSubmit={this.handleValidIMGSubmit}
								className="needs-validation mt-2"
								noValidate
								name="img-form"
								id="img-form">
								<Row className="pl-3 pr-3 pt-3 pb-0">
									<Col lg="12">
										<AvGroup className="">
											{/* <Label for="name">Name
										<div className="invalid-feedback d-none " id="import_file_error">Please choose the csv file to import</div>
										</Label> */}
											<AvField tag={CustomInput} type="file"
												name="filename"
												id="filename"
												// accept=".mp4,.3gp,.jpeg,.jpg,.png"
												placeholder=""
												validate={{
													required: {
														value: true,
														errorMessage: "This field is required"
													}
												}}
												onChange={this.onChange}
												required
											/>
										</AvGroup>
									</Col>
									<Col lg="12">
										<AvField
											name="message"
											type="textarea"
											placeholder="Enter a message"
											value={this.state.message2}
											style={{ resize: "none" }}
											onChange={(message2) => {
												this.setState({
													message2: message2.target.value
												});
											}}
										/></Col>
									<Col className="col-auto">
										<button className="btn btn-success chat-send btn-block" type="submit">
											Send
										</button>
									</Col>
									<Col className="col-auto">
										<button className="btn btn-success chat-send btn-block" type="button" onClick={this.openModal}>
											Close
										</button></Col>
								</Row>
							</AvForm>
						</div>
						{this.state.loader ?
							<div className={this.state.loader ? "" : "d-none"}>
								<Loader
									type="TailSpin"
									color="#006239"
									height={100}
									width={100}//3 secs
								/> </div> : ""}
					</ModalBody>
				</Modal>
			</Row>
		);
	}
}

export default class ChatWindow extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			dataLoading: true,
			messages: [],
			image: false,
			selectImage: "",
			Group: false,
			members_data: [],
			user_id: [],
			group_members: [],
			view: false
		};
		this.handleNewMessagePosted = this.handleNewMessagePosted.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.loadData(true);
		var that = this;
		this.interval = setInterval(() => {
			that.loadData(false);
		}, 10000);

		var post_data = {};
		post_data.group_id = this.props.call_center_id ? this.props.call_center_id.id : " ";
		console.log(post_data)
		if (this.props.call_center_id) {
			axios.post(apiURL + '/api/group-members', post_data).then((res) => {
				if (res.data.status === 1) {
					this.setState({
						group_members: res.data.data
					});
					var members = res.data.data
					console.log(members)
					const loggedInUser = getLoggedInUser()
					axios.post(apiURL + '/api/members', { id: loggedInUser.id }).then((res) => {
						var members_data = res.data.data.map(row => {
							row.key = row.id;
							row.label = row.first_name;
							row.value = row.id;
							return row;
						});
						var group_members = members.map(row => {
							return row.user_id;
						})
						members_data = members_data.filter((data) => group_members.indexOf(data.id) == -1)
						this.setState({ members_data })
					}).catch(e => {
						console.log("Addition failed , Error", e);
					});

				} else {
					swal("Warning!", res.data.message, "warning");
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	}

	componentWillUnmount() {
		this._isMounted = false;

		const loggedInUser = getLoggedInUser();
		if (loggedInUser != null) {
			if (loggedInUser.role === 'Control Center') {
				clearInterval(this.interval);
			} else if (this.interval != null && typeof this.interval != 'undefined') {
				clearInterval(this.interval);
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.props.call_center_id != null && JSON.stringify(this.props.call_center_id) != JSON.stringify(prevProps.call_center_id))
		if (this.props.call_center_id != null && JSON.stringify(this.props.call_center_id) != JSON.stringify(prevProps.call_center_id)) {
			this.loadData(true);

			var post_data = {};
			post_data.group_id = this.props.call_center_id ? this.props.call_center_id.id : " ";
			console.log(post_data)
			axios.post(apiURL + '/api/group-members', post_data).then((res) => {
				if (res.data.status === 1) {
					this.setState({
						group_members: res.data.data
					});
					var members = res.data.data
					console.log(members)
					const loggedInUser = getLoggedInUser()
					axios.post(apiURL + '/api/members', { id: loggedInUser.id }).then((res) => {
						var members_data = res.data.data.map(row => {
							row.key = row.id;
							row.label = row.first_name;
							row.value = row.id;
							return row;
						});
						var group_members = members.map(row => {
							return row.user_id;
						})
						members_data = members_data.filter((data) => group_members.indexOf(data.id) == -1)
						this.setState({ members_data })
					}).catch(e => {
						console.log("Addition failed , Error", e);
					});

				} else {
					swal("Warning!", res.data.message, "warning");
				}
			}).catch(e => console.log("Addition failed , Error ", e));


		}
	}

	toggleLoader = () => {
		this.setState(prevState => ({
			dataLoading: !prevState.dataLoading,
		}));
	};

	/**
	* Handle new message posted
	*/
	handleNewMessagePosted = (formData,openModal) => {
		// save new message
		const loggedInUser = getLoggedInUser();
		console.log(this.props.call_center_id.id, "this.props.call_center_id.id")
		formData.append("group_id", this.props.call_center_id.id);
		formData.append("user_id", loggedInUser.id);
		axios.post(apiURL + '/api/add-group-messages', formData).then((res) => {
			if (res.data.status == 1) {
				openModal()
				var data = res.data.data;
				console.log(res.data.data, "data")
				data.userName = loggedInUser.first_name;
				data.message_type = 0
				if (data.filename != null) {
					var type = data.filename.split(".").pop()
						if(type == "3gp"){
							var video = data.filename.replace("3gp", "mp4").toString() 
							data.filename = apiURL + '/uploads/messages/' + video
						}else{
							data.filename = apiURL + '/uploads/messages/' + data.filename
						}
				}
				data.userPic = apiURL + '/uploads/users/' + loggedInUser.profile_pic;
				data.position = data.user_id == loggedInUser.id ? "right" : "left";
				data.postedOn = moment(data.timestamp * 1000).format('D/MM/YYYY H:mm');
				this.setState({
					messages: this.state.messages.concat(data),
				});
				var that = this;
				// setTimeout(function () {
				// 	that.messagesEnd.scrollIntoView();
				// }, 100);
			}
			var that = this;
			setTimeout(function () {
				that.setState({
					alertStatus: null
				});
			}, 5000);
		}).catch(e => console.log("Addition failed , Error ", e));
	};

	loadData = (scrollTo) => {
		if (this.props.call_center_id != null) {
			const loggedInUser = getLoggedInUser();

			var post_data = {};
			post_data.group_id = this.props.call_center_id.id;
			console.log(post_data)
			axios.post(apiURL + '/api/group-message', post_data).then((res) => {
				var messages = res.data.data;
				messages = messages.map(row => {
					row.userPic = apiURL + '/uploads/users/' + row.profile_pic;
					if (row.filename != null) {
						var type = row.filename.split(".").pop()
						if(type == "3gp"){
							var video = row.filename.replace("3gp", "mp4").toString() 
							row.filename = apiURL + '/uploads/messages/' + video
						}else{
							row.filename = apiURL + '/uploads/messages/' + row.filename
						}
					} else {
						row.filename = ""
					}

					row.position = row.user_id == loggedInUser.id ? "right" : "left";
					row.postedOn = moment(row.timestamp * 1000).format('D/MM/YYYY H:mm');
					return row;
				})

				const changeLength = messages.length !== this.state.messages;

				if (res.data.status === 1) {

					if (res.data.refresh) {
						window.location.reload();
					}
					this.setState({
						dataLoading: false,
						messages
					});
					if (scrollTo) {
						var that = this;
						setTimeout(function () {
							that.messagesEnd.scrollIntoView();
						}, 100);
					}
				} else {
					this.setState({
						dataLoading: false,
						messages: [],
					});
				}
				var that = this;
				setTimeout(function () {
					that.setState({
						alertStatus: null
					});
				}, 5000);
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	}

	createGroup = () => {
		this.setState({
			Group: !this.state.Group,
		});

	}

	handleMemberSubmit = (event, values) => {
		values.group_members = this.state.user_id
		console.log(values)
		if (this.state.user_id.length < 1) {
			swal("Warning!", "Please select atleast one member", "warning");
		} else {
			document.getElementById("group-btn").disabled = true;

			axios.post(apiURL + '/api/create-group-members', values).then((res) => {
				if (res.data.status === 1) {
					swal("Success!", res.data.message, "success").then(() => {
						window.location.reload();
					});
					this.setState({
						Group: false,
					});
				} else {
					swal("Warning!", res.data.message, "warning");
					document.getElementById("group-btn").disabled = false;
				}
				var that = this;
				setTimeout(function () {
					that.setState({
						alertStatus: null
					});
				}, 5000);
			}).catch(e => console.log("Addition failed , Error ", e));
			this.setState({
				group_members: [],
				group_name: ""
			});
		}
	};

	createGroup = () => {
		this.setState({
			Group: !this.state.Group,
		});

	}


	viewMembers = () => {
		this.setState({
			view: !this.state.view,
		});
	}

	deleteUser = (row_id, status) => {
		var success_msg = "";
		var fail_msg = "";
		swal({
			title: "Are you sure?",
			text: "Do you want to Remove user?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					var post_data = {};
					post_data.group_id = this.props.call_center_id.id;
					console.log(post_data)
					axios.post(apiURL + `/api/delete-user/${row_id}`, post_data)
						.then((res) => {
							var alertStatus = {};
							if (res.status === 1) {
								alertStatus.color = "success";
								alertStatus.message = success_msg;
							} else {
								alertStatus.color = "danger";
								alertStatus.message = fail_msg;
							}
							this.setState({
								alertStatus
							});
							window.location.reload();
						}).catch(e => console.log("Addition failed , Error ", e));
				} else {
					swal("You have canceled the operation!");
				}
			})
	}
	deleteGroup = (row_id, status) => {
		var success_msg = "";
		var fail_msg = "";
		swal({
			title: "Are you sure?",
			text: "Do you want to Delete Group?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					fetch(apiURL + `/api/delete-group/${row_id}`, { mode: 'cors', method: 'DELETE' }).then(response => {
						if (response.status >= 200 && response.status < 300) {
							return response.json();
						} else {
							console.log(response);
						}
					})
						.then((res) => {
							var alertStatus = {};
							if (res.status === 1) {
								alertStatus.color = "success";
								alertStatus.message = success_msg;
							} else {
								alertStatus.color = "danger";
								alertStatus.message = fail_msg;
							}
							this.setState({
								alertStatus
							});
							window.location.reload();
						}).catch(e => console.log("Addition failed , Error ", e));
				} else {
					swal("You have canceled the operation!");
				}
			})
	}

	render() {
		const { messages } = this.state;

		var mda_name = '';
		if (this.props.call_center_id != null) {
			mda_name = this.props.call_center_id.name;
		}
		console.log(this.props.call_center_id)
		console.log(this.state.group_members)
		const loggedInUser = getLoggedInUser();
		return (<Card>
			<CardBody className="pt-2 pb-1">
				{this.props.call_center_id != null ?
					<Row>
						<h5 className="mb-4 header-title">{mda_name}</h5>
						{this.props.call_center_id.created_by == loggedInUser.id ?
							<Row className="ml-5">
								<Col className="col-auto">
									<button type="submit" className="btn btn-success chat-send btn-block" onClick={this.createGroup}>
										Add members
							</button></Col>
								<Col>
									<button type="submit" className="btn btn-success chat-send btn-block" onClick={() => { this.deleteGroup(this.props.call_center_id ? this.props.call_center_id.id : 0, 2) }}>
										Delete groups
							</button>
								</Col>
							</Row>
							: ""}
						<Col className="col-auto">
							<button type="button" onClick={this.viewMembers} className="btn btn-success chat-send btn-block">
								View members
						</button></Col>

						<Modal
							isOpen={this.state.Group}
							toggle={this.createGroup}
							size="lg"
						>
							<ModalHeader toggle={() => this.setState({ Group: false })}>
								Add Members
							</ModalHeader>
							<ModalBody>
								<AvForm onValidSubmit={this.handleMemberSubmit}
									className="needs-validation mt-2"
									noValidate
									name="img-form"
									id="img-form">
									<Row className="pl-3 pr-3 pt-3 pb-0">
										<Col lg="12" >
											<Select
												isMulti={true}
												classNamePrefix="react-select"
												placeholder="Select Members"
												options={this.state.members_data}
												name="so_user_id[]"
												value={this.state.user_id}
												onChange={user_id => {
													this.setState({ user_id })
												}}
												required
											/>
											<AvInput type="hidden" id="group_id" name="group_id" value={this.props.call_center_id.id}></AvInput>
										</Col>
										<Col className="col-auto mt-2">
											<button className="btn btn-success chat-send btn-block" id="group-btn" type="submit">Add</button>
										</Col>
									</Row>

								</AvForm>
							</ModalBody>
						</Modal>

						<Modal
							isOpen={this.state.view}
							toggle={this.viewMembers}
							size="lg"
						>
							<ModalHeader toggle={() => this.setState({ view: false })}>
								View Members
							</ModalHeader>
							<ModalBody>
								{typeof this.state.group_members != "undefined" ? this.state.group_members.map((data) => {
									return (
										<Media className={"border-top align-items-center chat-use-item"}>
											<div style={{ borderRadius: "50%", borderWidth: "2px", backgroundColor: data.active == "Y" ? "#008000" : "#ff0000", width: "10px", height: "10px" }} />
											<img src={apiURL + '/uploads/users/' + data.profile_pic} className={`avatar rounded-circle mr-3 `} alt={data.userName} /><Media body>
												{
													// this.props.call_center_id.created_by == loggedInUser.id ? 
													data.user_id == loggedInUser.id ? <h6 className="mt-0 mb-0 font-size-15">You</h6> : <h6 className="mt-0 mb-0 font-size-15">{data.userName}</h6>}
											</Media>
											{this.props.call_center_id.created_by == loggedInUser.id ?
												data.user_id != loggedInUser.id ?
													<button className="btn btn-sm btn-success chat-send float-right" onClick={() => { this.deleteUser(data.id, 2) }}  ><FeatherIcon.UserX /></button> : "" : ""}
										</Media>)
								}) : ""}
							</ModalBody>
							<ModalFooter >
								<Col className="col-auto mt-2">
									<button className="btn btn-success chat-send btn-block" onClick={this.viewMembers} type="submit">Close</button>
								</Col>
							</ModalFooter>
						</Modal>
					</Row> : ""}


				{mda_name !== '' ? <div className="chat-conversation" id="contact-ministry-chat">
					<PerfectScrollbar style={{ minHeight: 'calc(70vh - 48px)', maxHeight: 'calc(70vh - 48px)', width: '100%' }} className="whats-like-chat" >
						<ul className={classNames('conversation-list', "full-height-chat")}>
							{messages.map((message, i) => {
								return (
									<ChatItem key={i} placement={message.position}>
										{message.userPic && (
											message.message_type == 0 ?
												<ChatItemAvatar
													userAvatar={message.userPic}
													postedOn={message.postedOn}
												/> : ""
										)}
										<ChatItemText userName={message.userName} text={message.message} postedOn={message.postedOn} img={message.filename} openImage={this.props.openImage} image={this.state.image} message_type={message.message_type} />
									</ChatItem>
								);
							})}
						</ul>
						<div style={{ float: "left", clear: "both" }}
							ref={(el) => { this.messagesEnd = el; }}>
						</div>
					</PerfectScrollbar>

					{/* chat form */}
					<ChatForm onNewMessagesPosted={this.handleNewMessagePosted} />
				</div> : <PerfectScrollbar style={{ minHeight: 'calc(70vh + 45px)', maxHeight: 'calc(70vh + 45px)', width: '100%' }} className="align-items-center d-flex justify-content-center flex-wrap">
					<div className="text-center">
						<img src={apiURL + '/uploads/users/avatar.jpg'} alt={"ministry"} style={{ width: '200px' }} />
						<h4 className="text-muted pt-2">Click on the user to start communication</h4>
					</div>
				</PerfectScrollbar>}
			</CardBody>


		</Card>);
	}
};

