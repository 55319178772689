// @flow
import React,{ Component } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, Media, Row, Col , Input,Modal,
	ModalHeader, ModalBody,Button } from 'reactstrap';
	import { AvForm, AvField ,AvInput} from 'availity-reactstrap-validation';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
// import { makeColorGradient } from '../../helpers/helperUtils';
import Loader from '../../components/Loader';
import { getLoggedInUser } from '../../helpers/authUtils';
import swal from 'sweetalert';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';
import {default as Select} from 'react-select'
import Iconimage from '../../assets/images/logo-fav.png';
// import ministryIcon from '../../assets/images/ministry-icon.png';

const Member = ({ image, name, chat_count, isActive, className, onClick ,active}) => {
    return (<Media className={"border-top align-items-center chat-use-item "+(isActive ? "chat-active":"")} onClick={onClick}>
			<img src={image} className={`avatar rounded-circle mr-3 ${className}`} alt={name} /><Media body>
                <h6 className="mt-0 mb-0 font-size-15">{name} {chat_count>0 && !isActive ? <span className="chat-item-counts" >{chat_count}</span>:""}</h6>
            </Media>
        </Media>)
}

export default class CallCenters extends Component {
	_isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
			dataLoading:true,
			call_centers:[],
			search_word:"",
			Group:false,
			members_data:[],
			user_id:"",
			loggedInUser : getLoggedInUser()
        };
    }
	
	componentDidMount() {
		this._isMounted=true;
		this.loadData();
		

		axios.post(apiURL + '/api/members' ,{id: this.state.loggedInUser.id}).then((res) => {
			var members_data = res.data.data.map(row => {
				row.key = row.id;
				row.label = row.first_name;
				row.value = row.id;
				return row;
			});

			members_data = members_data.filter((data) => data.key != this.state.loggedInUser.id  )
			this.setState({ members_data})
			console.log(this.state.members_data)
		}).catch(e => {
			console.log("Addition failed , Error", e);
		});
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	
	componentDidUpdate(prevProps,prevState){
		if(this.props.call_center_id!=null && JSON.stringify(this.props.call_center_id)!=JSON.stringify(prevProps.call_center_id)){
			this.loadData();
		}
	}
	
	toggleLoader = () => {
        this.setState(prevState => ({
            dataLoading: !prevState.dataLoading,
        }));
    };
	
	loadData = () => {		
		const loggedInUser = getLoggedInUser();
		if(loggedInUser){
			var post_data={};
			post_data.user_id = loggedInUser.id;
		}
		
		axios.post(apiURL+'/api/groups' , post_data).then((res)=>{ 
			var call_centers = res.data.data;
			console.log(call_centers)
			if(res.data.status===1){
				this.setState({ 
					dataLoading:false,
					call_centers
				});
			}else{
				this.setState({ 
					dataLoading:false,
					call_centers:[],
				});
			}
			var that=this;
			setTimeout(function(){ 
				that.setState({
					alertStatus:null
				});
			}, 5000);
		}).catch(e => console.log("Addition failed , Error ", e));		
    }
	
	createGroup = ()=>{
		this.setState({
            Group: !this.state.Group,
        });

	}

	handleValidMessageSubmit = (event, values) => {

		if(this.state.user_id.length < 1){
			swal("Warning!","Please select atleast two members", "warning");
		}else{
			document.getElementById("group-btn").disabled = true;
		
		values.group_members= this.state.user_id.map(row => {
			return row.id;
		})
		values.created_by = this.state.loggedInUser.id
		values.group_members.push(this.state.loggedInUser.id)
		console.log(values , "group_members")
		
        if(values.name!==""){
			axios.post(apiURL+'/api/create-group',values).then((res)=>{ 
				if(res.data.status===1){
					swal("Success!", res.data.message, "success").then(() => {
						window.location.reload();
					});
					this.setState({ 
						Group:false,
					});
				}else{
					swal("Warning!", res.data.message, "warning");
					document.getElementById("group-btn").disabled = false;
				}
				var that=this;
				setTimeout(function(){ 
					that.setState({
						alertStatus:null
					});
				}, 5000);
			}).catch(e => console.log("Addition failed , Error ", e));
			this.setState({
				group_members:[],
				group_name:""
			});
		}}
    };

	render() {
		const { call_centers } = this.state;
		console.log(this.state.call_centers)
		var call_center_id=0;
		if(this.props.call_center_id!=null){
			call_center_id = this.props.call_center_id.id;
		}
		
		var data = call_centers;
		if (this.state.search_word!="") {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search_word.toLowerCase())
			})
		}

		return (<Card>
			<Modal
				isOpen={this.state.Group}
				toggle={this.createGroup}
				size="lg"
			>
				<ModalHeader toggle={() => this.setState({ Group: false })}>
				</ModalHeader>
				<ModalBody>
					<AvForm onValidSubmit={this.handleValidMessageSubmit}
						className="needs-validation mt-2"
						noValidate
						name="img-form"
						id="img-form">
						<Row className="pl-3 pr-3 pt-3 pb-0">
							<Col lg="12">
								<AvField
									name="name"
									type="text"
									placeholder="Enter group name"
									value={this.state.message2}
									required
									onChange={(group_name) => {
										this.setState({
											group_name: group_name.target.value
										});
									}}
								/></Col>
								<Col lg="12" >
								<Select
									isMulti={true}
									classNamePrefix="react-select"
									placeholder="Select Members"
									options={this.state.members_data}
									name="so_user_id[]"
									value={this.state.user_id}
									onChange={user_id => {
										this.setState({ user_id })
									}}
									required
								/>
								</Col>
							<Col className="col-auto mt-2">
								<button className="btn btn-success chat-send btn-block" id="group-btn" type="submit">Create</button>
							</Col>
						</Row>
					</AvForm>
				</ModalBody>
			</Modal>
			<CardBody className="pt-2">
				<h5 className="header-title mb-4 d-flex align-items-center"><Input type="serach" id="call_centers_search" name="call_centers_search" className="ml-3" placeholder="Search" onChange={(e)=>{ this.setState({ search_word:e.target.value }) }} /></h5>
						<button type="button" onClick={this.createGroup} className="btn btn-success chat-send btn-block">
							Create group
						</button>
				{this.state.dataLoading ? <Loader />:<PerfectScrollbar style={{ maxHeight: '70vh', minHeight: '70vh', width: '100%' }}>
					{data.map(row=>{ return <Member image={Iconimage} name={row.name}   chat_count={row.chat_count} isActive={ row.id==call_center_id } key={row.id} onClick={()=>this.props.handleCallCenterChange(row)}  />})}
				</PerfectScrollbar>}
			</CardBody>
		</Card>);
	}
};

