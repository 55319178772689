import React, { Component } from 'react';
import {
    Row, Col, Card, Button, Modal, ModalBody, ModalHeader, ModalFooter,
    CardBody, Input, UncontrolledDropdown, DropdownMenu, CustomInput,
    DropdownItem, DropdownToggle
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer } from "react-doc-viewer";
import { apiURL, BASE_URL } from '../../constants/defaultValues';
import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert';
import PageTitle from '../../components/PageTitle';
import { getLoggedInUser } from '../../helpers/authUtils';
import { Scrollbars } from 'react-custom-scrollbars';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-2"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx} value={option.page} >{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);




class Tables extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            modal_standard: false,
            modal_docviewer: false,
            table_data: [],
            Edit_data: [],
            users: getLoggedInUser(),

        };
        this.tog_standard = this.tog_standard.bind(this);
        this.tog_docviewer = this.tog_docviewer.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);
    }
    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard
        }));
    }
    tog_docviewer() {
        this.setState(prevState => ({
            modal_docviewer: !prevState.modal_docviewer
        }));
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };
    openModalWithSize = size => {
        this.setState({ size: size, className: null });
        this.toggle();
    };


    openModalWithClass = className => {
        this.setState({ className: className, size: null });
        this.toggle();
    };


    componentDidMount() {
        var values = {}
        values.states = this.state.users.state_id
        axios.post(apiURL + '/api/reports', values).then((res) => {
            this.setState({ table_data: res.data.data })
        }).catch(e => {
            console.log("Addition failed , Error", e);
        });


        axios.get(apiURL + '  /api/report/:id').then((res) => {
            this.setState({ table_data: res.data.data })
        }).catch(e => {
            console.log("Addition failed , Error", e);
        });


    }



    ViewProfile = (e, user_id = 0) => {
        if (user_id == 0) {
            this.setState({
                modal_standard: false,
                user_id,
                Edit_data: null
            });
        } else {
            axios.get(apiURL + `/api/report/${user_id}`).then((res) => {
                if (res.status == 200) {
                    var Edit_data = res.data.data;
                    this.setState({
                        modal_standard: true,
                        user_id,
                        Edit_data,
                    });
                } else {
                    var alertStatus = {};
                    alertStatus.message = res.data.message;
                    alertStatus.color = "danger"
                    this.setState({
                        modal_standard: false,
                        user_id: 0,
                        Edit_data: null,
                        alertStatus
                    });
                    var that = this;
                    setTimeout(function () {
                        that.setState({
                            alertStatus: null
                        });
                    }, 9000);
                }
            }).catch(e => console.log("Addition failed , Error ", e));
        }
    };


    render() {

        var table_data = this.state.table_data.map((row, index) => {
            row.actions = <div>
                <Button className="ml-1 mr-1" style={{ background: "#954dbe", border: "none" }} onClick={(e) => { this.ViewProfile(e, row.id) }} color="primary" size="sm">View</Button>
                <a target="_blank" href={row.attachment_url} className="ml-1 mr-1 btn btn-primary btn-sm" style={{ background: "#3d8478", border: "none" }} download>Download PDF</a>
            </div>

            row.date = moment(row.timestamp * 1000).format('DD-MM-YYYY HH:mm:ss')

            return row;
        })


        const columns = [
            {
                dataField: 'added_by_name',
                text: 'Administrator',
                sort: true,
            },
            {
                dataField: 'state_name',
                text: 'State',
                sort: false,
            },
            {
                dataField: 'date',
                text: 'Date Reported',
                sort: false,
            },
            {
                dataField: 'actions',
                text: 'Actions',
                sort: false,
                csvExport : false,
            },
        ];

        const defaultSorted = [
            {
                dataField: 'id',
                order: 'asc',
            },
        ];




        const { SearchBar } = Search;
        const { ExportCSVButton } = CSVExport;
        const docs = [
            { uri: this.state.Edit_data.attachment_url }
        ];
        return (
            <React.Fragment>

                <Row>
                    <Col>
                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={table_data}
                            columns={columns}
                            search
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                            {props => (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            <SearchBar {...props.searchProps} />
                                        </Col>
                                        <Col className="text-right">
                                            <ExportCSVButton {...props.csvProps} className="btn btn-primary">
                                                Export CSV
                                    </ExportCSVButton>
                                        </Col>
                                    </Row>

                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        defaultSorted={defaultSorted}
                                        pagination={paginationFactory({ sizePerPage: 5, sizePerPageRenderer: sizePerPageRenderer, sizePerPageList: [{ text: '5', value: 5, }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: 'All', value: this.state.table_data.length }] })}
                                        wrapperClasses="table-responsive"
                                    />
                                    <Row>
                                        <Modal
                                            isOpen={this.state.modal_standard}
                                            toggle={this.tog_standard}
                                            size="md"
                                        >
                                            <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
                                            </ModalHeader>
                                            <ModalBody className="pl-0 pr-0">
                                                <div className="white bg_view pl-4 pr-4 text-center">
                                                    <p className="">{this.state.users.state_name} State Report</p>
                                                </div>
                                                <div className="ml-2 mr-2 pl-2 pr-2" style={{ height: "300px" }}>
                                                    <Scrollbars >
                                                    <p className="p-2 text-justify"><strong style={{ wordBreak: "break-word" }} >{this.state.Edit_data.report}</strong></p>
                                                    </Scrollbars>
                                                    
                                                </div>
                                                <div className="mt-2">
                                                    <Row>
                                                        <Col lg={3}>
                                                            <div className="pl-4 pr-4">
                                                                <strong>Attachment:</strong>
                                                            </div>
                                                        </Col>
                                                        <Col lg={9}>
                                                            <Button onClick={this.tog_docviewer} style={{ background: "#072308" }} className="pt-0 pb-0">View File</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <ModalFooter>
                                                    <Button style={{ background: "#707170", border: "none" }} onClick={this.tog_standard} className="pt-0 pb-0">Close</Button>
                                                </ModalFooter>

                                            </ModalBody>
                                        </Modal>
                                    </Row>
                                    <Row>
                                        <Modal
                                            isOpen={this.state.modal_docviewer}
                                            toggle={this.tog_docviewer}
                                            size="md"
                                        >
                                            <ModalHeader toggle={() => this.setState({ modal_docviewer: false })}>
                                            </ModalHeader>
                                            <ModalBody className="pl-0 pr-0">
                                                <div className="text-center " >
                                                    <h5 className="green"> <strong> Evidence </strong></h5>
                                                </div>
                                                <DocViewer
                                                    pluginRenderers={[PDFRenderer, PNGRenderer, JPGRenderer]}
                                                    config={{
                                                        header: {
                                                            disableHeader: false,
                                                            disableFileName: false,
                                                            retainURLParams: false
                                                        }
                                                    }}
                                                    documents={docs}
                                                />
                                                <div className="text-center mt-4">
                                                <Button style={{ background: "#707170", border: "none" }} onClick={this.tog_docviewer} className="pt-0 pb-0">Close</Button>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </Row>
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </Col>
                </Row>


            </React.Fragment>
        )
    }
}

export default Tables
