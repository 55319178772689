// @flow
import React from 'react';
import { Row, Col,Card , UncontrolledDropdown,DropdownToggle,
         DropdownItem,DropdownMenu,} from 'reactstrap';
import { ChevronDown, ChevronLeft, ChevronUp, ChevronRight } from 'react-feather';

import PageTitle from '../../components/PageTitle';

import LineChart from './LineChart';
import LineAnnotationChart from './LineAnnotationChart';
import SplineAreaChart from './SplineAreaChart';
import StackedAreaChart from './StackedAreaChart';
import BarChart from './BarChart';
import StackedBarChart from './StackedBarChart';
import DonutChart from './DonutChart';
import PieChart from './PieChart';
import MixedChart from './MixedChart';
import ReportsTables from './ReportsTables';
import Tabs from './Tabs';

const ApexChart = () => {
    return (
        <React.Fragment>
		 <div className="light_green">
			<PageTitle
				breadCrumbItems={[
					{ label: 'Reports', path: '/admin/reports', active: true },
				]}
				title={'Reports'}
			/>
			
			<Row className="mt-4 ">
			     <Col lg={12}>
				     <Card className="pl-4 pr-4 pt-4 pb-4">
					     <Tabs />
					 </Card>
				 </Col>
            </Row>
         </div>
        </React.Fragment>
    );
};

export default ApexChart;
