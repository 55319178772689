import React, { Component } from 'react';
import { Row, Col, UncontrolledDropdown, Card, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ChevronDown, LogOut, Wifi, X } from 'react-feather';
import { projectTitle } from '../constants/defaultValues';
import { getLoggedInUser } from '../helpers/authUtils';
import { Offline, Online, Detector } from "react-detect-offline";
/**
 * Renders the PageTitle
 */

const online = function () {
    return (
        <Detector
            render={({ online }) => (
                <div className={online ? "" : "d-none"}>
                    <div className="internet_warning">
                        <Card className="" >
                            <Row>
                                <Col xs={12} className="px-0 ml-4" >
                                    <h5 className="mb-0 ml-2" style={{ color: "#000000" }}>Your internet connection was restored.<Wifi style={{ color: "#ff726f" }} className="icon-dual icon-xs mb-2" /><X className="mr-5 mb-2 float-right" onClick={this.openModal} /> </h5>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
            )}
        />)
};

class onlineDetect extends Component {
    _isMounted = false;

    constructor(props) {

        super(props);
        this.state = {
            modal: true,
            detect: ""
        }
        this.openModal = this.openModal.bind(this);
    }
    componentDidMount() {
        // localStorage.setItem("online-detect", "offline") 
        this.setState({
        })
    }


    openModal() {
        localStorage.setItem("online-detect", "online")
        this.setState({ modal: !this.state.modal })
    }
    render() {
        const loggedInUser = getLoggedInUser();
        const onlines = window.navigator.onLine;
        setTimeout(() => {
            if (onlines) {
                this.openModal()
            } else {
                localStorage.setItem("online-detect", "offline")
            }
        }, 5000)
        
        const detect = window.localStorage.getItem('online-detect');
        return (
            <React.Fragment>
                <div>
                    {this.state.modal ?
                        onlines ?
                            <div className={detect !="online" ? "" : "d-none"}>
                                <div className="internet_warning">
                                    <Card className="" >
                                        <Row>
                                            <Col xs={12} className="px-0 ml-4" >
                                                <h5 className="mb-0 ml-2" style={{ color: "#000000" }}> <Wifi style={{ color: "#ff726f" }} className="icon-dual icon-xs mb-2 mr-2" /> Your internet connection was restored.<X className="mr-5 mb-2 float-right" onClick={this.openModal} /> </h5>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </div> : 
                            <div className="internet_warning">
                                <Card className="align-items-center" >
                                    <Row>
                                        <Col xs={12} className="px-0" style={{ textAlign: "center" }}>
                                            <h5 className="mb-0" style={{ color: "#FF0000", textAlign: "center" }}>Please check your internet connectivity. You are working in offline now! </h5>
                                        </Col>
                                    </Row>
                                </Card>
                            </div> : ""}
                </div>

            </React.Fragment>
        )
    }
}
export default onlineDetect;