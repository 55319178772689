import React, { Component } from 'react';
import { Row, Col, Card, Button,Modal,ModalBody,ModalHeader,ModalFooter,
CardBody, Input, UncontrolledDropdown, DropdownMenu, CustomInput,
DropdownItem, DropdownToggle } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { apiURL, BASE_URL } from '../../constants/defaultValues';
import moment from 'moment';
import axios from 'axios';
import PageTitle from '../../components/PageTitle';
import DocViewer ,{ PDFRenderer, PNGRenderer, JPGRenderer }from "react-doc-viewer";
import swal from 'sweetalert';
import ViewReport from "./ViewReport";
import { getLoggedInUser } from '../../helpers/authUtils';

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-2"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx} value={option.page}>{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);

export default class Tables extends Component {
		
	constructor(props) {
        super(props);

        this.state = {
            modal: false,
		    modal_standard: false,
		    modal_standard_log: false,
			new_data:[],
			Edit_data:[],
			modal_docviewer:false,
			Processing_data:[],
			closed_data:[],
			users:getLoggedInUser(),
        };
		
        this.tog_standard = this.tog_standard.bind(this);
        this.tog_standard_log = this.tog_standard_log.bind(this);
        this.toggle = this.toggle.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);
		this.tog_docviewer = this.tog_docviewer.bind(this);
    }
	
    tog_standard(){
		this.setState(prevState => ({
			modal_standard: !prevState.modal_standard
		}));
	}
	  
	tog_standard_log() {
		this.setState(prevState => ({
			modal_standard_log: !prevState.modal_standard_log
		}));
	}
   
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };
	openModalWithSize = size => {
        this.setState({ size: size, className: null });
        this.toggle();
    };
    
    openModalWithClass = className => {
        this.setState({ className: className, size: null });
        this.toggle();
    };
	
	tog_docviewer() {
        this.setState(prevState => ({
            modal_docviewer: !prevState.modal_docviewer
        }));
    }

	componentDidMount() {
		var values = {}
		values.states = this.state.users.state_id;
		if(this.state.users.state_id!=null){
			axios.post(apiURL + '/api/correct-report',values).then((res) => {
				this.setState({ new_data: res.data.data.new})
				this.setState({ Processing_data: res.data.data.processing})
				this.setState({ closed_data: res.data.data.closed})
			}).catch(e => {
				console.log("Addition failed , Error", e);
			});
		}else{		
			axios.post(apiURL + '/api/correct-report').then((res) => {
				this.setState({ new_data: res.data.data.new})
				this.setState({ Processing_data: res.data.data.processing})
				this.setState({ closed_data: res.data.data.closed})
			}).catch(e => {
				console.log("Addition failed , Error", e);
			});
		}
    }


	ViewProfile = (e , user_id=0) => { 
		if(user_id == 0){
			this.setState({
				view_profile: false,
				user_id,
				Edit_data: null
			});
		} else { 
			axios.get(apiURL + `/api/view-citizen/${user_id}/${this.state.users.id}`).then((res) => {
				if (res.data.status == 1) {
					var Edit_data = res.data.data;
					if (Edit_data.data[0].state_id != res.data.user.state_id &&  res.data.user.user_type==2) {
                        swal("Warning!", "You have been transferred to another state. Please logout and login to continue the operations.","warning").then((e)=>{
                            window.location.replace("/account/logout")
                        });
                        return
                    }else{
						this.setState({
							view_profile: true,
							user_id,
							Edit_data,
						});
					}
				} else {
					var alertStatus = {};
					alertStatus.message = res.data.message;
					alertStatus.color = "danger"
					this.setState({
						view_profile: false,
						user_id: 0,
						Edit_data: null,
						alertStatus
					});
					var that = this;
					setTimeout(function () {
						that.setState({
							alertStatus: null
						});
					}, 9000);
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	};
	 
	updateStatus = (row_id,status) => {
		var success_msg="";
		var fail_msg = "";
		swal({
			 title: "Are you sure?",
			 text: "Do you want to delete report?",
			 icon: "warning",
			 buttons: true,
			 dangerMode: true,
		})
		.then((willDelete) => {
			if (willDelete) {	
			   fetch(apiURL + `/api/delete-reports/${row_id}`, { mode: 'cors' ,method: 'DELETE'}).then(response => {
				   if (response.status >= 200 && response.status < 300) {
					 return response.json();
				   } else {
					 console.log(response);
				   }
			   })			
			   .then((res)=>{ 
				   var alertStatus={};
				   if(res.status===1){
					   alertStatus.color="success";
					   alertStatus.message=success_msg;
				   }else{
					   alertStatus.color="danger";
					   alertStatus.message=fail_msg;
				   }
				   this.setState({ 
					   alertStatus
				   });
				   window.location.reload();
			   }).catch(e => console.log("Addition failed , Error ", e));
			} else {
			swal("You have canceled the operation!");
			}
		})
	}
	
	   
	render() {
		var Processing_data = this.state.Processing_data.map((row, index) => {
				row.actions = <div>
					  <Button className="ml-1 mr-1" style={{ background: "#954dbe", border: "none" }} onClick={(e) => { this.ViewProfile(e, row.id) }} color="primary" size="sm">View</Button>
				</div>

				row.date =  moment(row.timestamp * 1000).format('DD-MM-YYYY HH:mm:ss')
				row.status = 'Processing'
				row.first_name = row.first_name ? row.first_name : "Anonymous" 
				row.phone_number = row.phone_number == null ? "" : row.phone_number
				row.email = row.email == null ? "" : row.email
				return row;
			});

		const columns = [
			{
				dataField: 'track_id',
				text: 'ID',
				sort: true,
			},
			{
				dataField: 'first_name',
				text: 'Reporter',
				sort: true,
			},
			{
				dataField: 'phone_number',
				text: 'Phone',
				sort: false,
			},
			{
				dataField: 'email',
				text: 'Email',
				sort: true,
			},
			{
				dataField: 'state_name',
				text: 'State',
				sort: false,
			},
			{
				dataField: 'lga_name',
				text: 'LGA',
				sort: false,
			},
			{
				dataField: 'report_name',
				text: 'Crime/incident',
				sort: false,
			},
			{
				dataField: 'date',
				text: 'Date Reported',
				sort: false,
			},
			{
				dataField: 'status',
				text: 'Status',
				sort: false,
			},
			{
				dataField: 'actions',
				text: 'Actions',
				sort: false,
				csvExport: false,
			},
		];

		const defaultSorted = [
			{
				dataField: 'id',
				order: 'desc',
			},
		];
		const { SearchBar } = Search;
		const { ExportCSVButton } = CSVExport;
		return (
			<React.Fragment>
				<Row>
					<Col>
					<ToolkitProvider
						bootstrap4
						keyField="id"
					   data={Processing_data}
						columns={columns}
						search
						exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
						{props => (
							<React.Fragment>
								<Row>
									<Col>
										<SearchBar {...props.searchProps} />
									</Col>
									<Col className="text-right">
										<ExportCSVButton {...props.csvProps} className="btn btn-primary">
											Export CSV
										</ExportCSVButton>
									</Col>
								</Row>

								<BootstrapTable
									{...props.baseProps}
									bordered={false}
									defaultSorted={defaultSorted}
									pagination={paginationFactory({ sizePerPage: 5, sizePerPageRenderer: sizePerPageRenderer, sizePerPageList: [{ text: '5', value: 5, }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: 'All', value: Processing_data.length }] })}
									wrapperClasses="table-responsive"
								/>
								 <ViewReport 
									modal={this.state.view_profile}
									toggle={this.ViewProfile}
									data={this.state.Edit_data}
									/>
							</React.Fragment>
						)}
					</ToolkitProvider>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
	
};

