import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Container, Row, Col, Card, CardBody, InputGroup, InputGroupAddon, UncontrolledDropdown, DropdownMenu, DropdownToggle,
	DropdownItem, Button, Badge, Modal, CustomInput, ModalHeader, Input, FormGroup, Label, ModalBody, ModalFooter
} from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox, AvInput } from "availity-reactstrap-validation";
import { MoreVertical } from 'react-feather';
import ReactToPrint from 'react-to-print';
import { apiURL, BASE_URL } from '../../constants/defaultValues';
import moment from 'moment';
import axios from 'axios';
import fetch from 'isomorphic-fetch';
import swal from 'sweetalert';
import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer, VideoRenderer, DocRenderer } from "react-doc-viewer";
import PrintModalContent from "./printModalContent";
import { getLoggedInUser } from '../../helpers/authUtils';
import ReactPlayer from 'react-player'
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';




const MyCustomPNGRenderer = ({
	mainState: { currentDocument },
}) => {
	if (!currentDocument) return null;
	return (
		<div className='player-wrapper' style={{ width: "100%", height: "100%" }}>
			<ReactPlayer
				className='react-player'
				url={currentDocument.fileData ? currentDocument.fileData.toString() : " "}
				width='100%'
				height='400px'
				controls={true}

			/>
		</div>
	);
};

MyCustomPNGRenderer.fileTypes = ["mp4", "video/mp4"];
MyCustomPNGRenderer.weight = 1;


const MyCustomAudioRender = ({
    mainState: { currentDocument },
}) => {
    if (!currentDocument) return null;
    return (
        <audio controls >
            <source src={currentDocument.fileData ? currentDocument.fileData.toString() : " "} />
        </audio>

    );
};

MyCustomAudioRender.fileTypes = ["mp3", "audio/mpeg"]
MyCustomAudioRender.weight = 1;

class ViewReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			dataLoaded: false,
			data: [],
			modal: false,
			toggleSwitch: false,
			user_role: [],
			table_data: [],
			permission_data: [],
			select_all: [],
			refresh_selection: true,
			status: false,
			print_data: [],
			print_profile: false,
			users: getLoggedInUser(),
			security: [],
			row_id: "",
			replay: false,
			editreplay:false
		}
	}



	componentDidMount() {
		this.setState({ correct_report: this.props.data })


	}


	componentWillUnmount() {
		this._isMounted = false;
		document.body.classList.remove('authentication-bg');
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.data != null && JSON.stringify(this.props.data) != JSON.stringify(prevProps.data)) {
			axios.post(apiURL + `/api/forward-so/${this.state.users.user_type}`, this.props.data).then((res) => {
				this.setState({ security: res.data.data })
			}).catch(e => {
				console.log("Addition failed , Error", e);
			});
		}
	}




	IsBetween(value, left, right) {
		return (left < value && value < right) || (right < value && value < left);
	}



	tog_docviewer = (e, offers = []) => { return (this.setState({ modal_docviewer: !this.state.modal_docviewer, offers })) };


	updateStatus = (row_id, status) => {
		console.log(row_id)
		var success_msg = "";
		var fail_msg = "";
		swal({
			title: "Are you sure?",
			text: "Do you want to delete report?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					fetch(apiURL + `/api/delete-reports/${row_id}`, { mode: 'cors', method: 'DELETE' }).then(response => {
						if (response.status >= 200 && response.status < 300) {
							return response.json();
						} else {
							console.log(response);
						}
					})
						.then((res) => {
							var alertStatus = {};
							if (res.status === 1) {
								alertStatus.color = "success";
								alertStatus.message = success_msg;
							} else {
								alertStatus.color = "danger";
								alertStatus.message = fail_msg;
							}
							this.setState({
								alertStatus
							});
							window.location.reload();
						}).catch(e => console.log("Addition failed , Error ", e));
				} else {
					swal("You have canceled the operation!");
				}
			})
	}
	replayStatus = (row_id, status) => { return (this.setState({ replay: !this.state.replay, row_id })) };
	editReplay = (row_id, status) => { return (this.setState({ editreplay: !this.state.editreplay, row_id })) };
	handleSubmit(event, values, id) {
		document.getElementById("forward-btn").disabled = true;

		axios.post(apiURL + `/api/froward-report/${values.user_id}`, values).then((res) => {
			if (res.data.status == 1) {
				document.getElementById("forward-btn").disabled = false;
				swal("Success!", res.data.message, "success").then(() => {
					window.location.reload();
				});
			} else {
				document.getElementById("forward-btn").disabled = false;
				swal("Warning!", res.data.message, "warning").then(() => {
				});

			}

		}).catch(e => {
			console.log("Addition failed , Error", e);
		});

	}


	updatehandleSubmit(event, values, id) {
		values.time = moment(moment().unix() * 1000).format('DD-MM-YYYY HH:mm:ss')

		document.getElementById("update-btn").disabled = true;
		axios.post(apiURL + `/api/update-status/${values.user_id}`, values).then((res) => {
			if (res.data.status == 1) {
				document.getElementById("update-btn").disabled = false;
				swal("Success!", res.data.message, "success").then(() => {
					window.location.reload();
				});
			} else if (res.data.status == 0) {
				document.getElementById("update-btn").disabled = false;
				swal("Warning!", res.data.message, "warning").then(() => {
				});
			}
			else {
				swal("Warning!", res.data.message, "warning").then(() => {
				});

			}
		}).catch(e => {
			console.log("Addition failed , Error", e);
		});

	}

	handleValidSubmit(event, values, id) {
		document.getElementById("replay-btn").disabled = true;
		values.time = moment(moment().unix() * 1000).format('DD-MM-YYYY HH:mm:ss')
		console.log(values)
		axios.post(apiURL + `/api/add-replay/${values.user_id}`, values).then((res) => {
			if (res.data.status == 1) {
				document.getElementById("replay-btn").disabled = false;
				swal("Success!", res.data.message, "success").then(() => {
					window.location.reload();
				});
			} else {
				document.getElementById("replay-btn").disabled = false;
				swal("Warning!", res.data.message, "warning").then(() => {
				});

			}

		}).catch(e => {
			console.log("Addition failed , Error", e);
		});


	}
	EdithandleValidSubmit(event, values, id) {
		values.time = moment(moment().unix() * 1000).format('DD-MM-YYYY HH:mm:ss')
		console.log(values)
		axios.post(apiURL + `/api/edit-replay/${values.user_id}`, values).then((res) => {
			if (res.data.status == 1) {
				document.getElementById("replay-btn").disabled = false;
				swal("Success!", res.data.message, "success").then(() => {
					window.location.reload();
				});
			} else {
				document.getElementById("replay-btn").disabled = false;
				swal("Warning!", res.data.message, "warning").then(() => {
				});

			}

		}).catch(e => {
			console.log("Addition failed , Error", e);
		});


	}


	render() {



		// this.props.data.feedback=this.props.data.feedback.map((row, index) => {
		//     row.date = <div>
		//         {moment(row.timestamp * 1000).format('DD-MM-YYYY HH:mm:ss')}
		//     </div>

		//     return row;
		// })

		var feedback_report = []
		if (this.props.data) {
			if (this.props.data.feedback) {
				feedback_report = this.props.data.feedback.map((row, index) => {
					row.date = moment(row.timestamp * 1000).format('DD-MM-YYYY HH:mm:ss')
					// row.feedback = 
					// 	<li className="black" style={{ wordBreak: "break-all" }}>{row.feedback}</li>


					return row;
				})
			}
		}
		const feedback = [
			{
				dataField: 'date',
				text: 'Date',
				sort: true,
				headerStyle: (colum, colIndex) => {
					return { width: '100px' };
				},
			},
			{
				dataField: 'feedback',
				text: 'feedback',
				sort: true,
				headerStyle: (colum, colIndex) => {
					return { width: '220px' };
				},
			},
		];


		const defaultSorted = [
			{
				dataField: 'id',
				order: 'asc',
			},
		];

		console.log(feedback_report, "feedback_report")
		return (

			<Modal
				isOpen={this.props.modal}
				toggle={this.props.toggle}
				size="xl"
				wrapClassName="modal-right"
			>
				<ModalHeader toggle={this.props.toggle}>
					Report Details
									  </ModalHeader>
				<ModalBody>
					<Row>
						{this.props.data ? this.props.data.data ? this.props.data.data.map((data) => {
							console.log(data)
							return (

								<Col lg={5}>
									<div className="bg_grey pt-4 pb-4">
										<div className="white bg_view pl-4 pr-4">
											<p>Crime / Incident Type</p>
										</div>
										<Row className="black pl-4 pr-4">
											<Col sm={6}>
												<p><strong>Type : </strong></p>
											</Col>
											<Col sm={6}>
												<p>{data.report_name}</p>
											</Col>
										</Row>

										<div className="white bg_view pl-4 pr-4">
											<p>State where the incident occured</p>
										</div>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>State : </strong></p>
											</Col>
											<Col sm={6}>
												<p>{data.state_name}</p>
											</Col>
										</Row>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>LGA : </strong></p>
											</Col>
											<Col sm={6}>
												<p>{data.lga_name}</p>
											</Col>
										</Row>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>Town/City : </strong></p>
											</Col>
											<Col sm={6}>
												<p>{data.city}</p>
											</Col>
										</Row>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>Address : </strong></p>
											</Col>
											<Col sm={6}>
												<p style={{ wordBreak: "break-all", textAlign: "left" }}	>{data.address}</p>
											</Col>
										</Row>
										{data.land_mark ? 
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>Land mark: </strong></p>
											</Col>
											<Col sm={6}>
												<p style={{ wordBreak: "break-all", textAlign: "left" }}	>{data.land_mark}</p>
											</Col>
										</Row> :""}

										<div className="white bg_view pl-4 pr-4">
											<p>Date range this incident could have occured</p>
										</div>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>From : </strong></p>
											</Col>
											<Col sm={6}>
												<p>{moment(data.date_range_from).format('DD-MM-YYYY HH:mm:ss')}</p>
											</Col>
										</Row>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>To : </strong></p>
											</Col>
											<Col sm={6}>
												<p>{moment(data.date_range_to).format('DD-MM-YYYY HH:mm:ss')}</p>
											</Col>
										</Row>

										<div className="white bg_view pl-4 pr-4">
											<p>Description of the incident</p>
										</div>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>Description : </strong></p>
											</Col>
											<Col sm={6}>
												<p style={{ wordBreak: "break-all", textAlign: "left" }}>{data.description}</p>
											</Col>
										</Row>

										<div className="white bg_view pl-4 pr-4">
											<p>Evidence</p>
										</div>
										<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
											<Col sm={6}>
												<p><strong>Evidence : </strong></p>
											</Col>
											<Col sm={6}>
												<Button color="primary" size="sm" onClick={this.tog_docviewer} >View File</Button>
											</Col>
											<Modal
												isOpen={this.state.modal_docviewer}
												toggle={this.tog_docviewer}
												size="md"
											>
												<ModalHeader toggle={() => this.setState({ modal_docviewer: false })}>
												</ModalHeader>
												<ModalBody className="pl-0 pr-0">
													<div className="text-center " >
														<h5 className="green"> <strong> Evidence </strong></h5>
													</div>
													<DocViewer
														pluginRenderers={[PDFRenderer, PNGRenderer, JPGRenderer, MyCustomPNGRenderer , MyCustomAudioRender]}
														config={{
															header: {
																disableHeader: false,
																disableFileName: false,
																retainURLParams: false
															}
														}}
														documents={[
															{ uri: data.evidence_url }
														]}
													/><div className="text-center mt-4">
														<Button onClick={this.tog_docviewer} color="primary" className="white width-sm">Close</Button>
														{/* <Button style={{ background: "#441e1d", border: "none" }} onclick={this.tog_docviewer} className="pt-0 pb-0">Close</Button> */}
													</div>
												</ModalBody>
											</Modal>

										</Row>
										{data.person == 1 || data.person == 2 ?
											<div>
												<div className="white bg_view pl-4 pr-4">
													<p>Iam</p>
												</div>
												<Col sm={12}>
													<div>
														<CustomInput type="radio" id="1" name="person" label="The impacted person" checked={data.person == 1} />
													</div>
													<div>
														<CustomInput type="radio" id="2" name="person" label="Reporting on behalf of the impacted" checked={data.person == 2} />
													</div>
												</Col>
											</div> : ""}
										{data.first_name ?
											<div>
												<div className="white bg_view mt-4 pl-4 pr-4">
													<p>Reporter's Contact Information</p>
												</div>
												<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
													<Col sm={6}>
														<p><strong>Full Name : </strong></p>
													</Col>
													<Col sm={6}>
														<p style={{ wordBreak: "break-all", textAlign: "left" }}>{data.first_name}</p>
													</Col>
												</Row>
											</div> : ""}
										{data.phone_number ?
											<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
												<Col sm={6}>
													<p><strong>Phone : </strong></p>
												</Col>
												<Col sm={6}>
													<p>{data.phone_number}</p>
												</Col>
											</Row> : ""}
										{data.email ?
											<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
												<Col sm={6}>
													<p><strong>Email : </strong></p>
												</Col>
												<Col sm={6}>
													<p style={{ wordBreak: "break-all", textAlign: "left" }}>{data.email}</p>
												</Col>
											</Row> : ""}
										{data.contact_address ?
											<Row className="black mt-0 pt-0 mb-0 pt-0 pl-4 pr-4">
												<Col sm={6}>
													<p><strong>Address : </strong></p>
												</Col>
												<Col sm={6}>
													<p style={{ wordBreak: "break-all", textAlign: "left" }}>{data.contact_address}</p>
												</Col>
											</Row> : ""}
									</div>
								</Col>
							)
						}) : "" : ""}
						<Col lg={7}>
							{this.props.data ? this.props.data.data ? this.props.data.data.map((data) => {
								return (
									<Col lg={12}>
										{data.status != 1 ?
											typeof this.props.data.log != "undefined" ?
												<Card className="bg_grey pl-4 pr-4 pt-4 pb-4">
													<p className="mb-0 pb-0">LOG</p>< hr />
													<Col className="black">

														{this.props.data.log.map((data) => {
															return (<div>
																<Row>
																	<Col lg="5" className="black"> <strong>{data.forwarded_to}</strong></Col>
																	<Col lg="7" className="black"> {data.message}</Col>
																</Row>

															</div>
															)
														})}
													</Col>
												</Card>
												: ""

											: ""}

									</Col>)
							}) : "" : ""}
							<Card className="bg_grey pl-4 pr-4 pt-4 pb-4">
								<p className="mb-0 pb-0">ACTIONS</p>< hr />
								<div className="button-list text-center ipad_responsive">
									{this.props.data ?
										this.props.data.data ?
											this.props.data.data.map((data) => {
												return (
													<Row className="mt-5">

														<Col lg={3}>
															<ReactToPrint
																trigger={() => <Button style={{ background: "#7c82d4", border: "none" }} className="p-19 white width-sm" >Print Report</Button>}
																content={() => this.componentRef}
																copyStyles={true}
																pageStyle={""}
															/>
															{/*  */}
														</Col>
														{/* <Col lg={3}>
															<Button style={{ background: "#d47cb0", border: "none" }} onClick={() => { this.updateStatus(data.id, 2) }} className="p-19 white width-sm">Delete Report</Button>
														</Col> */}
														{data.status == 1 ?
															<Col lg={3}>
																<UncontrolledDropdown className="d-inline">
																	<DropdownToggle style={{ background: "#68ab5b", border: "none", disable: true }} className=" white width-sm">Forward report to security operative's</DropdownToggle>
																	<DropdownMenu className=" drop-down-menu left-dropdown-arrow">

																		<div className="" style={{ width: '300px' }}>
																			<Row className="pl-2 pr-2 pt-2 pb-2">
																				<Col lg={12}>
																					<ModalHeader className="pt-0">
																						<p className="mb-0" style={{ fontSize: "13px" }}> Security Operatives</p>
																					</ModalHeader>
																					{this.state.security.length > 0 ?
																						<AvForm onValidSubmit={this.handleSubmit}
																							className="form-horizontal"
																							id="sercutiy-form">
																							<FormGroup>
																								<AvField
																									name="security_operatives_id"
																									type="select"
																									autoComplete="off"
																									placeholder="Select"
																									value={this.state.client}
																									validate={{
																										required: {
																											value: true,
																											errorMessage: "select security operative "
																										}
																									}}
																									onChange={e => this.setState({ client: e.target.value })}
																								>
																									<option value="">Select </option>
																									{this.state.security ? this.state.security.map((data) => {
																										return (<option value={data.id}>{data.first_name} {data.middle_name} {data.last_name}</option>)
																									}) : ""}
																								</AvField>
																								<AvInput type="hidden" id="user_id" name="user_id" value={data.id}></AvInput>
																							</FormGroup>
																							<Button color="primary" size="sm" id="forward-btn">Ok</Button>
																						</AvForm> : <p className="mt-2 mb-0" style={{ fontSize: "13px", color: "red" }}>No Security Operatives</p>}

																				</Col>
																			</Row>
																		</div>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</Col> : ""}
														{data.status != 2 ?
															<Col lg={3} >
																<UncontrolledDropdown className="d-inline">
																	<DropdownToggle style={{ background: "#0e3707", border: "none", disable: true }} className="p-19 white width-sm">Update Status</DropdownToggle>
																	<DropdownMenu className=" drop-down-menu  ">
																		<Row className="pl-2 pr-2 pt-2 pb-2">
																			<Col lg={12}>
																				<ModalHeader className="pt-0">
																					<p className="mb-0" style={{ fontSize: "13px" }}> Update Status</p>
																				</ModalHeader>
																				<AvForm onValidSubmit={this.updatehandleSubmit}
																					className="form-horizontal mt-2"
																					id="sercutiy-form">
																					<FormGroup>
																						<AvField
																							name="update_status"
																							type="select"
																							autoComplete="off"
																							placeholder="Select"
																							value={this.state.client}
																							validate={{
																								required: {
																									value: true,
																									errorMessage: "select status "
																								}
																							}}
																							onChange={e => this.setState({ client: e.target.value })}
																						>
																							<option value="">Select </option>
																							{data.status =!4  ? <option value="Forwarded to appropriate authority">Forwarded to appropriate authority </option>:""}
																							{data.status == 1 ? <option value="Recieved">Received</option> : ""}
																							{data.status != 1 ? <option value="Closed">Closed</option> : ""}
																						</AvField>
																						<AvInput type="hidden" id="user_id" name="user_id" value={data.id}></AvInput>
																					</FormGroup>
																					<Button color="primary" size="sm" ID="update-btn" >Ok</Button>
																				</AvForm>

																			</Col>
																		</Row>
																	</DropdownMenu>
																</UncontrolledDropdown>
																{/* <Button style={{background:"#0e3707" ,border:"none"}}  block className="p-19 white width-sm">Update Status</Button> */}
															</Col>
															: ""}
															{data.status != 2 ? 
														<Col lg={3}>
															{this.props.data ?
																this.props.data.log.length == 3 ? <Button style={{ background: "#d47cb0", border: "none" }} onClick={() => { this.replayStatus(data.id, 2) }} className="p-19 white width-sm">Reply </Button>
																 : <Button style={{ background: "#d47cb0", border: "none" }} onClick={() => { this.editReplay(data.id, 2) }} className="p-19 white width-sm">Edit Reply </Button>:"" }
														</Col>:""}

													</Row>)
											}) : "" : ""}
								</div>
							</Card>
							<Modal
								isOpen={this.state.replay}
								toggle={this.replayStatus}
								size="md"
							>
								<ModalHeader toggle={() => this.setState({ replay: false })}>
									<div className="text-center " >
										<h5 className="green"> <strong> Reply </strong></h5>
									</div>
								</ModalHeader>
								<ModalBody className="pl-0 pr-0">

									<AvForm onValidSubmit={this.handleValidSubmit} id="replay-form" className="authentication-form">
										<Row className="pl-2 pr-2 pt-2 pb-2">
											<Col lg={12}>
												<AvField
													name="replay"
													label="Reply"
													type="textarea"
													validate={{
														required: {
															value: true,
															errorMessage: "Reply  is required"
														}

													}}  />
											</Col>
											<AvInput type="hidden" id="user_id" name="user_id" value={this.state.row_id}></AvInput>
											<Col lg={12}>
												<div className="text-right">
													<Button color="primary" id="replay-btn" className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Submit</Button>
													<Button onClick={this.replayStatus} color="primary" className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Close</Button>
												</div>
											</Col>
										</Row>
									</AvForm>



								</ModalBody>
							</Modal>
							<Modal
								isOpen={this.state.editreplay}
								toggle={this.editReplay}
								size="md"
							>
								<ModalHeader toggle={() => this.setState({ editreplay: false })}>
									<div className="text-center " >
										<h5 className="green"> <strong>Edit Reply </strong></h5>
									</div>
								</ModalHeader>
								<ModalBody className="pl-0 pr-0">

									<AvForm onValidSubmit={this.EdithandleValidSubmit} id="edit-form" className="authentication-form">
									{this.props.data ? this.props.data.data ? this.props.data.data.map((data) => {
										return(
										<Row className="pl-2 pr-2 pt-2 pb-2">
											<Col lg={12}>
												<AvField
													name="replay"
													label="Reply"
													value={data.replay}
													type="textarea"
													validate={{
														required: {
															value: true,
															errorMessage: "replay  is required"
														}

													}}  />
											</Col>
											<AvInput type="hidden" id="user_id" name="user_id" value={this.state.row_id}></AvInput>
											<Col lg={12}>
												<div className="text-right">
													<Button color="primary" id="replay-btn" className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Submit</Button>
													<Button onClick={this.editReplay} color="primary" className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Close</Button>
												</div>
											</Col>
										</Row>) }) :"" :""}
									</AvForm>



								</ModalBody>
							</Modal>

							{feedback_report.length > 0 ?
								<Card className="bg_grey pl-4 pr-4 pt-4 pb-4">
									<p className="mb-0 pb-0">FEEDBACK</p>< hr />
									<div className="" style={{ height: "300px", overflowY: 'scroll' }}>
										<ToolkitProvider
											bootstrap4
											keyField="id"
											data={feedback_report}
											columns={feedback}
											search
											exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
											{props => (
												<React.Fragment>
													<BootstrapTable
														{...props.baseProps}
														bordered={false}
														defaultSorted={defaultSorted}
														wrapperClasses="table-responsive"
													/>
												</React.Fragment>
											)}
										</ToolkitProvider>
									</div>
								</Card> : ""}
						</Col>


						<div className="d-none">
							<PrintModalContent
								ref={el => (this.componentRef = el)}
								data={this.props.data}
							/>

						</div>

					</Row>
				</ModalBody>
				<ModalFooter>
					<Button onClick={this.props.toggle} color="primary" className="white width-sm">Close</Button>
				</ModalFooter>


			</Modal>

		);

	}
};

export default (ViewReport);


