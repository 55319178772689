// @flow
import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import {
    Card, CardBody, Media, Row, Col, Modal,
    ModalHeader, ModalBody, Button
} from 'reactstrap';

import ReactPlayer from 'react-player'
import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer } from "react-doc-viewer";
import { TimeGridSlicer } from '@fullcalendar/timegrid';
/* Chat Item Avatar */


const MyCustomPNGRenderer = ({
    mainState: { currentDocument },
}) => {
    if (!currentDocument) return null;
    return (
        <div className='player-wrapper' style={{ width: '100%', height: '400px' }}>
            <video controls style={{ width: '100%', height: '400px' }} >
                <source src={currentDocument.fileData ? currentDocument.fileData.toString() : " "} type='video/mp4'/>
            </video>

        </div>
    );
};

MyCustomPNGRenderer.fileTypes = ["mp4", "mov", "mkv", "webm", "mpeg-2", "3gp", 'video/3gpp', "video/mp4", "video/quicktime", "video/x-matroska", "video/webm", "video/mpeg"]
MyCustomPNGRenderer.weight = 1;


class albumModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            Edit_data: [],
            modal: false,
            selectImage: ""
        }
    }
    componentDidMount() {
        this.setState({
            selectImage: this.props.selectImage
        })
    }


    openModal = (e, offers = []) => { return (this.setState({ modal: !this.state.modal, offers })) };
    render() {
        console.log(this.props.selectImage)
        return (

            <Modal
                isOpen={this.props.image}
                toggle={this.props.toggle}
                size="fd"
            >
                <ModalHeader toggle={this.toggle}>
                </ModalHeader>

                <ModalBody className="pl-0 pr-0">
                            <DocViewer
                                pluginRenderers={[ MyCustomPNGRenderer,PDFRenderer, PNGRenderer, JPGRenderer]}
                                config={{
                                    header: {
                                        disableHeader: false,
                                        disableFileName: false,
                                        retainURLParams: false
                                    }
                                }}
                                documents={[
                                    { uri: this.props.selectImage }
                                ]}
                            />  
                    <div className="text-center mt-4">
                        <Button onClick={this.props.toggle} color="primary" className="white width-sm">Close</Button>
                    </div>
                </ModalBody>
            </Modal>

        );
    }
};

export default (albumModal);


