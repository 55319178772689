// @flow
import React,{ Component } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, Media, Input } from 'reactstrap';

import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
// import { makeColorGradient } from '../../helpers/helperUtils';
import Loader from '../../components/Loader';
import { getLoggedInUser } from '../../helpers/authUtils';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';

// import ministryIcon from '../../assets/images/ministry-icon.png';

const Member = ({ image, name, chat_count, isActive, className, onClick ,active}) => {
    return (<Media className={"border-top align-items-center chat-use-item "+(isActive ? "chat-active":"")} onClick={onClick}>
		<div style={{borderRadius : "50%" , borderWidth:"2px", backgroundColor : active == "Y" ? "#008000" : "#ff0000"  , width:"10px" , height:"10px"}} />
			<img src={image} className={`avatar rounded-circle mr-3 ${className}`} alt={name} /><Media body>
                <h6 className="mt-0 mb-0 font-size-15">{name} {chat_count>0 && !isActive ? <span className="chat-item-counts" >{chat_count}</span>:""}</h6>
            </Media>
        </Media>)
}

export default class CallCenters extends Component {
	_isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
			dataLoading:true,
			call_centers:[],
			search_word:""
        };
    }
	
	componentDidMount() {
		var that =this;
		this._isMounted=true;
		this.loadData();
		this.interval = setInterval(() => {
			that.loadData();
		}, 10000);
    }
	
	componentWillUnmount() {
		this._isMounted = false;
	}
	
	componentDidUpdate(prevProps,prevState){
		if(this.props.call_center_id!=null && JSON.stringify(this.props.call_center_id)!=JSON.stringify(prevProps.call_center_id)){
			this.loadData();
		}
	}
	
	toggleLoader = () => {
        this.setState(prevState => ({
            dataLoading: !prevState.dataLoading,
        }));
    };
	
	loadData = () => {		
		const loggedInUser = getLoggedInUser();
		if(loggedInUser){
			var post_data={};
			post_data.user_id = loggedInUser.id;
			// post_data.user_type = 1
		}
		
		axios.post(apiURL+'/api/so-admin',post_data).then((res)=>{ 
			var call_centers = res.data.data;
			console.log(call_centers)
			if(res.data.status===1){
				this.setState({ 
					dataLoading:false,
					call_centers
				});
			}else{
				this.setState({ 
					dataLoading:false,
					call_centers:[],
				});
			}
			var that=this;
			setTimeout(function(){ 
				that.setState({
					alertStatus:null
				});
			}, 5000);
		}).catch(e => console.log("Addition failed , Error ", e));		
    }
	
	render() {
		const { call_centers } = this.state;
		console.log(this.state.call_centers)
		var call_center_id=0;
		if(this.props.call_center_id!=null){
			call_center_id = this.props.call_center_id.id;
		}
		
		var data = call_centers;
		if (this.state.search_word!="") {
			data = data.filter(row => {
				return row.first_name.toLowerCase().includes(this.state.search_word.toLowerCase())
			})
		}

		return (<Card>
			<CardBody className="pt-2">
				<h5 className="header-title mb-4 d-flex align-items-center"><Input type="serach" id="call_centers_search" name="call_centers_search" className="ml-3" placeholder="Search" onChange={(e)=>{ this.setState({ search_word:e.target.value }) }} /></h5>

				{this.state.dataLoading ? <Loader />:<PerfectScrollbar style={{ maxHeight: '70vh', minHeight: '70vh', width: '100%' }}>
					{data.map(row=>{ return <Member image={apiURL+'/uploads/users/'+row.profile_pic} name={row.first_name} chat_count={row.chat_count} isActive={ row.id==call_center_id }  active ={row.active} key={row.id} onClick={()=>this.props.handleCallCenterChange(row)}  />})}
				</PerfectScrollbar>}
			</CardBody>
		</Card>);
	}
};

