import React, { Component } from 'react';
import { Row, Col, UncontrolledDropdown, Card, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ChevronDown, LogOut, Wifi, X } from 'react-feather';
import { projectTitle } from '../constants/defaultValues';
import { getLoggedInUser } from '../helpers/authUtils';
import { Offline, Online, Detector } from "react-detect-offline";
import OnlineDetect from './OnlineDetect';
/**
 * Renders the PageTitle
 */



class PageTitle extends Component {
	_isMounted = false;

	constructor(props) {

		super(props);
		this.state = {
			modal: true,
			Online:window.navigator.onLine ? true : false
		}
		this.openModal = this.openModal.bind(this);
	}
	componentDidMount() {
		this.setState({
		})
	}


	openModal() {
		this.setState({ modal: !this.state.modal })
		console.log(this.state.modal)
	}
	render() {
		const loggedInUser = getLoggedInUser();
		const title = this.props.title || "";
		const items = this.props.breadCrumbItems || "";
		setTimeout(() => {
			const  onlines = window.navigator.onLine;
			this.setState({ Online : onlines})
		 },5000)
		return (
			<React.Fragment>
				 <OnlineDetect /> 
				<Row>
					<Col lg="8">
						{loggedInUser.user_type == 1 ? <p><strong>{projectTitle} </strong> {items.map((item, index) => { return <React.Fragment key={index}>| {item.label}</React.Fragment>; })}</p> : loggedInUser.user_type == 2 ? <p><strong className="text-uppercase">{loggedInUser.state_name} STATE SECURITY ADMINISTRATION </strong></p> : <p><strong className="text-uppercase"> SECURITY OPERATOR</strong></p>}
					</Col>
					<Col lg="4" className="text-right">
						<UncontrolledDropdown className="d-inline">
							<DropdownToggle className="btn_top">{loggedInUser.first_name}<i data-feather="activity" className="icon-dual"></i> <i className="icon"><ChevronDown></ChevronDown></i></DropdownToggle>
							<DropdownMenu>
								<Link to={"/account/logout"} className="dropdown-item notify-item">
									<LogOut className="icon-dual icon-xs mr-2"></LogOut>
									<span>Logout</span>
								</Link>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}
export default PageTitle;