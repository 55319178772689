import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import alert1 from '../../assets/images/alert.jpg';
import logo from '../../assets/images/logo-fav.png';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import FileUploader from '../../components/FileUploader';
import Topbar from '../../components/Topbarin';

import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';

class AcceptTermsandConditions extends Component {

    constructor(props) {
        super(props);
		this.state = {
			modal_yes: false,
			modal_submit: false
            
        };
		this.tog_yes = this.tog_yes.bind(this);
		this.tog_submit = this.tog_submit.bind(this);
    }
	tog_yes() {
		this.setState(prevState => ({
		  modal_yes: !prevState.modal_yes
		}));
	  }
	tog_submit() {
		this.setState(prevState => ({
		  modal_submit: !prevState.modal_submit
		}));
	  }
    componentDidMount() {
        this._isMounted = true;

        document.body.classList.add('accept_terms');
    }
    render() {
		  
        return (
            <React.Fragment className="pl-0 pr-0 ml-0 mr-0" >
			<Topbar/>
			
                <div className="mt-4 fillreport container-fluid terms-logo-hide pl-0 pr-0 ml-0 mr-0">
                    <Row className=" terms-logo-hide">
	                     <Col lg={3} className="">
						     <div className="white_bg pl-2 pr-0 ml-0 mr-0" style={{height:"100%"}}>
							     <div className="text-center pt-4">
								     <img src={logo} alt="" height="100" />
								 </div>
								 <div>
								     <p>PROGRESS FLOW</p>
								 </div>
								 <div className="radio_custom_sirp">
								     <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox" className="" label="Accept Terms & Conditions"  />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox1" label="Select Type Of Crime" />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Fill Report" />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox3" label="Confirm Your ID" />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox4" label="Submit Report" checked />
									 </div>
								 </div>
							 </div>
						 </Col>
						 <Col lg={9} className="">
						     <Row>
							     <Col lg="12" >
								     <div className="submit_report mt-4">
									     <div className="text-center modal-text"><h2 style={{fontWeight:"700",color:"#1c9741",paddingTop:"80px"}}>Your report has been submitted successfully!</h2></div>	 
									     <div className="text-center f_17">
										      Write down this ID :<span>SIR212141664</span>
										 </div>
										 <div className="text-center f_17">
										      You can track the progress of your report with the ID
										 </div>
										 <div className="text-center f_17 m-50 mb-4">
										      <Button className="bg-clr pt-2 pb-2">CLOSE</Button>
										 </div>
									 </div>
								 </Col>
							 </Row>
						 </Col>   
								  
                    </Row>
                    <Row>
						 <Modal
							  isOpen={this.state.modal_yes}
							  toggle={this.tog_yes}
							  size="lg"
							>
							  <ModalHeader toggle={() => this.setState({ modal_yes: false })}>
							  </ModalHeader>
							  <ModalBody>
						        <div className="text-center"><img className="img-fluid mt-1 mb-4" src={alert1} alt="" /></div>
								<div className="text-center modal-text"><h2 style={{fontWeight:"800",color:"#f30b0b"}}>WARNING</h2></div>	 
						        <div className="text-center modal-text pt-2">
								     <p className="f-19">If your report is found to be fake or false,</p>
								     <p className="f-19">you will be <strong>APPREHENDED !</strong></p>
								</div>
								<div className="text-center m-40 mt-4 mb-4">
							     <Button className="bg_pop f-18 ml-4 mr-4 btn_modal" onClick={this.tog_yes}>I Don't Accept</Button>
								 <Link to="/citizens/fill-report"><Button className="green_bg f-18 ml-4 mr-4 btn_modal">I Accept</Button></Link>
							    </div>
						  </ModalBody>
						 </Modal>
				    </Row>
					<Row>
						 <Modal
							  isOpen={this.state.modal_submit}
							  toggle={this.tog_submit}
							  size="lg"
							>
							  <ModalHeader toggle={() => this.setState({ modal_submit: false })}>
							  </ModalHeader>
							  <ModalBody>
								
								<div className="text-center m-40 mt-4 mb-4">
							     <Link to="/citizens/select-type-of-crime"><Button className="bg_pop f-18 ml-4 mr-4 btn_modal">NO</Button></Link>
								 <Link to="/citizens/submit-report"><Button className="green_bg f-18 ml-4 mr-4 btn_modal">YES</Button></Link>
							    </div>
						  </ModalBody>
						 </Modal>
				    </Row>
                   </div>
            </React.Fragment>
        )
    }
}


export default AcceptTermsandConditions;