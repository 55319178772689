import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';

import { Scrollbars } from 'react-custom-scrollbars';

import logo from '../../assets/images/logo-fav.png';
import Topbar from '../../components/Topbarin';
	
class AcceptTermsandConditions extends Component {

    constructor(props) {
        super(props);
    }
	
	componentDidMount() {
        this._isMounted = true;

        document.body.classList.add('citizens');
        document.body.classList.add('accept_terms');
    }

    render() {
		  
        return (
            <React.Fragment className="pl-0 pr-0 ml-0 mr-0" >
			 <Topbar/>
                <div className="mt-4 terms-logo-hide pl-0 pr-0 ml-0 mr-0">
                    <Row className=" terms-logo-hide">
	                     <Col lg={3} className="">
						     <div className="white_bg pl-2 pr-2 ml-0 mr-0" style={{height:"100%"}}>
							     <div className="text-center pt-4">
								     <img src={logo} alt="" height="100" />
								 </div>
								 <div>
								     <p>PROGRESS FLOW</p>
								 </div>
								 <div className="radio_custom_sirp">
								     <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox"  className="enabled-checkbox" label="Accept Terms & Conditions"  checked disabled />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox1" label="Select Type Of Crime" disabled />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Fill Report" disabled />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox3" label="Confirm Your ID" disabled />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox4" label="Submit Report" disabled />
									 </div>
								 </div>
							 </div>
						 </Col>
						 <Col lg={9} className="mt-3">
						     <div className="white_bg mt-4" style={{borderRadius:"10px",height:"100%"}}>
								     <div>
									     <h3 className="text-center black pt-4 pb-1" style={{fontWeight:"700"}}>NOTICE BEFORE FILLING REPORT</h3>
							             <p className="text-center f-600 f-16 black">Prior to filling a complaint with his portal,we request you to carefully read the terms and conditions below</p>
							         </div>
							         <div className="terms_scroll term_bg pb-4 mb-4">
										  <div className="">
										      <h3 className="f-600 black pt-4 pl-3 pr-3 pb-1">Terms & Conditions</h3>
										  </div>
									 </div>
                           
							         <div className="mb-4 text-center">
									     <Link to="/citizens/submit-report"><Button className="accept_btn btn_terms ml-4 mr-4">I ACCEPT</Button></Link>
									     <Link to="/"><Button className="dont_accept_btn btn_terms">I DON'T ACCEPT</Button></Link>
							         </div>
							 </div> 
						 </Col>
                    </Row>
                    
                   </div>
            </React.Fragment>
        )
    }
}


export default AcceptTermsandConditions;