import React, { Component } from 'react';
import {
    Row, Col, Card,
    Button, CardBody, Modal,
    ModalHeader, ModalBody,
    ModalFooter, Input, FormGroup,
    UncontrolledDropdown, InputGroup,
    DropdownMenu, DropdownItem,
    InputGroupAddon, Label, CustomInput,
    DropdownToggle, Badge
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { ChevronDown, ChevronLeft, ChevronUp, ChevronRight } from 'react-feather';
import { MoreVertical } from 'react-feather';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
import PageTitle from '../../components/PageTitle';
import swal from 'sweetalert';
import EditModal from "./EditModal"


const records = [
    {
        title: 'Kidnapping',
        subtitle: 'Report A Kidnapping',
        actions: <div>
            <Button className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Edit</Button>
            <Button className="ml-1 mr-1 delete-bg pl-4 pr-4" size="sm">Delete</Button>
        </div>,


    },
    {
        title: 'Bandltry',
        subtitle: 'Report Bandltry',
        actions: <div>
            <Button className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Edit</Button>
            <Button className="ml-1 mr-1 delete-bg pl-4 pr-4" size="sm">Delete</Button>
        </div>,


    }

];

const columns = [
    {
        dataField: 'title',
        text: 'Title',
        sort: true,
    },
    {
        dataField: 'sub_title',
        text: 'Subtitle',
        sort: false,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
    },
];

const defaultSorted = [
    {
        dataField: 'id',
        order: 'asc',
    },
];



class Tables extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            modal_standard: false,
            table_data: [],
            EditProfile:false,
            Edit_data:[]

        };
        this.tog_standard = this.tog_standard.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);
    }
    tog_standard() {
        this.setState(prevState => ({
            modal_standard: !prevState.modal_standard
        }));
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };

    openModalWithSize = size => {
        this.setState({ size: size, className: null });
        this.toggle();
    };


    openModalWithClass = className => {
        this.setState({ className: className, size: null });
        this.toggle();
    };

    componentDidMount() {
        axios.get(apiURL + '/api/crimes').then((res) => {
            this.setState({ table_data: res.data.data })
        }).catch(e => {
            console.log("Addition failed , Error", e);
        });



    }
    handleValidSubmit = (event, values) => {
        document.getElementById("crime=list-btn").disabled = true;
        axios.post(apiURL + '/api/add-crime', values).then((res) => {
            if (res.data.status === 1) {
                document.getElementById("crime=list-btn").disabled = false;
                swal("Success!", res.data.message, "success").then(() => {
                    window.location.reload();
                });
            } else {
                document.getElementById("crime=list-btn").disabled = false;
                swal("Warning!", res.data.message, "warning");
            }
            this.setState({
                dataLoaded: true,
            });
        }).catch(e => console.log("Addition failed , Error ", e));


    }
    
	EditProfile = (e , user_id=0) => { 
		if(user_id == 0){
			this.setState({
				EditProfile: false,
				user_id,
				Edit_data: null
			});
		} else { 
            axios.get(apiURL + `/api/crime/${user_id}`).then((res) => {
				if (res.status == 200) {
					var Edit_data = res.data.data;
					this.setState({
						EditProfile: true,
						user_id,
						Edit_data,
					});
				} else {
					var alertStatus = {};
					alertStatus.message = res.data.message;
					alertStatus.color = "danger"
					this.setState({
						EditProfile: false,
						user_id: 0,
						Edit_data: null,
						alertStatus
					});
					var that = this;
					setTimeout(function () {
						that.setState({
							alertStatus: null
						});
					}, 9000);
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	 };

	 updateStatus = (row_id,status) => {
		var data_upload={};
		var success_msg="";
		var fail_msg = "";
		var status_word = "";
		swal({
			  title: "Are you sure?",
			  text: "Do you want to delete Crime List ?",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {		
                axios.delete(apiURL + `/api/crime/${row_id}`).then((res) => {
					var alertStatus={};
					if(res.status===1){
						alertStatus.color="success";
						alertStatus.message=success_msg;
					}else{
						alertStatus.color="danger";
						alertStatus.message=fail_msg;
					}
					this.setState({ 
						alertStatus
					});
					window.location.reload();
				}).catch(e => console.log("Addition failed , Error ", e));
			  } else {
				swal("You have canceled the operation!");
			  }
			})
		}

    render() {

       let table_data = this.state.table_data.map((row, index) => {
            row.actions = 
           
            <div>
                <Button className="m-1 edit-bg pl-4 pr-4" size="sm"  onClick={(e) => { this.EditProfile(e, row.id) }}>Edit</Button>
                <Button className="m-1 delete-bg pl-4 pr-4" size="sm" onClick={() => { this.updateStatus(row.id, 2) }}>Delete</Button>
            </div>
            
            return row;
        })


        const { SearchBar } = Search;
        const { ExportCSVButton } = CSVExport;
        console.log(table_data.length)
        return (
            <React.Fragment>
                <Row>
                    <Col xl={12}>
                        <div className="button-list text-right">
                            <UncontrolledDropdown className="d-inline">
                                <DropdownToggle color="primary" className="pl-4 pr-4">&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; Add Crime &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; <i className="icon"><ChevronDown></ChevronDown></i></DropdownToggle>
                                <DropdownMenu className=" drop-down-menu">
                                    <AvForm onValidSubmit={this.handleValidSubmit} id="crime-list-form" className="authentication-form">
                                        <Row className="pl-2 pr-2 pt-2 pb-2">
                                            <Col lg={12}>
                                                <AvField
                                                    name="title"
                                                    label="Title"
                                                    type="text"
                                                    required />
                                            </Col>
                                            <Col lg={12}>
                                                <AvField
                                                    name="sub_title"
                                                    label="Subtitle"
                                                    type="text"
                                                    validate={{
                                                        required: {
															value: true,
															errorMessage: "sub title is required."
														},
                                                        minLength: {
                                                            value: 2,
                                                            errorMessage: "The sub title must be between 2 and 180 characters"
                                                        },
                                                        maxLength: {
                                                            value: 80,
                                                            errorMessage: "The  sub title must be between 2 and 180 characters"
                                                        }
													}}  />
                                            </Col>
                                            <Col lg={12}>
                                                <div className="text-right">
                                                    <Button color="primary" id="crime=list-btn" className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Submit</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToolkitProvider
                            bootstrap4
                            keyField="id"
                            data={table_data}
                            columns={columns}
                            search
                            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
                            {props => (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            <SearchBar {...props.searchProps} />
                                        </Col>
                                    </Row>

                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        defaultSorted={defaultSorted}
                                        pagination={paginationFactory({ sizePerPage: 5, sizePerPageRenderer: sizePerPageRenderer, sizePerPageList: [{ text: '5', value: 5, }, { text: '10', value: 10 }, { text: '25', value: 25 }, { text: "All", value: table_data.length}] })}
                                        wrapperClasses="table-responsive"
                                    />
                                </React.Fragment>
                            )}

                        </ToolkitProvider>
                    </Col>
                </Row>

                <Row>
                    <Card>
                        <CardBody>

                            <Row>
                                <Modal
                                    isOpen={this.state.modal_standard}
                                    toggle={this.tog_standard}
                                    size="md"
                                >
                                    <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
                                        Add User
									  </ModalHeader>
                                    <ModalBody>


                                    </ModalBody>
                                </Modal>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <EditModal
					modal={this.state.EditProfile}
					toggle={this.EditProfile}
					Edit_data={this.state.Edit_data}
					user_id={this.state.user_id}
				/>

            </React.Fragment>
        );
    };
}
{/* table starts */ }
const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(e.target.value)}>
            {options.map((option, idx) => {
                return <option key={idx} value={option.page}>{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);




{/* table ends */ }
export default Tables;
