import React, { Component } from 'react';
import { Row, Col,Label,Card } from 'reactstrap';
import { Offline, Online } from "react-detect-offline";
import { getLoggedInUser } from '../../helpers/authUtils';

import axios from 'axios';
import { apiURL } from '../../constants/defaultValues';
// import Loader from '../..';
import PageTitle from '../../components/PageTitle';
import Loader from "react-loader-spinner";
import CallCenters from './CallCenters';
import ChatWindow from './ChatWindow';
import AlbumModal from "../albumModal"
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';

export default class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: getLoggedInUser(),
            mdas:[],
			call_center_id:null,
			dataLoading:true,
			selectImage:"",
				image:false,
				loader:false
        };
		this.handleCallCenterChange = this.handleCallCenterChange.bind(this);
    }
	
	componentDidMount() {
		const loggedInUser = getLoggedInUser();
		if(loggedInUser.user_type==3){
			var call_center_id = {id:loggedInUser.id};			
			this.handleCallCenterChange(call_center_id);
		}else{
			this.setState({
				dataLoading:false,
				
			});
		}
	
    }
	
	handleCallCenterChange = (call_center_id) => {
		const loggedInUser = getLoggedInUser();
		if(JSON.stringify(this.state.call_center_id)!==JSON.stringify(call_center_id)){
			this.setState({ loader:true})
			this.setState({
				call_center_id,				
			});

			console.log(this.state.call_center_id ,"call_canter_id")
			var post_data={};
			post_data.so_admin_id = 1;
			post_data.user_id = loggedInUser.id;
			console.log(post_data)
			axios.post(apiURL+'/api/add-so-chat',post_data).then((res)=>{
				console.log(res)
				this.setState({
					dataLoading:false,
					loader:false
				});
			}).catch(e => console.log("Addition failed , Error ", e));
		}
		
		
	};

	openImage = (e,filename="") => {
		this.setState({ image: !this.state.image ,
			selectImage : filename
		})
	}


    render() {
		const { call_center_id } = this.state;
		console.log(call_center_id,"call_center_id")
		const loggedInUser = getLoggedInUser();
		console.log(loggedInUser,"loggedInUser")
        return (
            <React.Fragment>
				<div className="light_green">
				{loggedInUser.role!=='Call Center' ? 
						<PageTitle
							breadCrumbItems={[
								{ label: 'Contact Call Center', path: '/contact-call-center', active: true },
							]}
							title={'Contact Call Center'}
						/>:""}
				
                {this.state.dataLoading ?""
				//  <Loader />
				 : 
				 <div className="">  
				 {/* <Offline>
								<div >
									<Card className="align-items-center mt-5" >
										<Row className="align-items-center">
											<Col xs={12} className="px-0" style={{ textAlign: "center", height: "200px" }}>
												<h2 className="mb-0 mt-5" style={{ color: "#ff726f", textAlign: "center" }}>Please check your internet connectivity!</h2>
												<h2 className="mb-0" style={{ color: "#ff726f", textAlign: "center" }}>Chat option can only work when you connect to internet.</h2>
											</Col>
										</Row>
									</Card>
								</div>
							</Offline>
							<Online>                   */}
					<Row className="pt-4 pb-3">
						{loggedInUser.role!=='Call Center' ? <Col sm={12} xl={4}>
							{/* <CallCenters 
								call_center_id={call_center_id}
								handleCallCenterChange={this.handleCallCenterChange}
							/> */}
						</Col>
						:""}
						<Col sm={12} >
						{this.state.loader ?
							<div className={this.state.loader ? "": "d-none"} style={{ textAlign:"center" }}>
							<Loader
								type="TailSpin"
								color="#006239"
								height={100}
								width={100}//3 secs
							/> </div>:
													
							<ChatWindow 
								call_center_id={call_center_id}
								openImage = {this.openImage} 
							/>	
							} 
						 </Col>
					</Row>  
					{/* </Online>                */}
                </div>
				 } 

<AlbumModal
				selectImage={this.state.selectImage}
				image={this.state.image}
				toggle={this.openImage}
				 /></div>
            </React.Fragment>
        )
    }
}
