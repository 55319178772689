import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';


import Home from '../pages/citizens/citizen';
import ReportTracking from '../pages/citizens/report-tracking';
import StatusofReport from '../pages/citizens/status-of-report';
import AcceptTermsandConditions from '../pages/citizens/accept-terms-and-conditions';
import SelectTypeofCrime from '../pages/citizens/select-type-of-crime';
import FillReport from '../pages/citizens/fill-report';
import CitizenSubmitReport from '../pages/citizens/submit-report';
import ReportersInformation from '../pages/citizens/reporters-information';

import Charts from '../pages/reports/';
import Fssamadmin from '../pages/fssamadmin/';
import CrimeList from '../pages/crimelist/';
import SecurityOperative from '../pages/securityOperative/';
import SubmitReport from '../pages/submit-report/';
import FssamReports from '../pages/fssam-reports/';

import SecurityOperativeReports from "../pages/SecurityOperativeReports/"

import ChatWindow from '../pages/admin_so_chat';
import fssamChat from '../pages/admin_fssam_Chat';
import So_fssam_chat from '../pages/fssam_so_chat';
import fssam_admin_Chat from '../pages/fssam_admin_Chat';
import admin_fssam_Chat from "../pages/admin_fssam_Chat"
import so_admin_chat from "../pages/so_admin_chat";
import so_fssam_chat from "../pages/so_fssam_chat"
import GroupChat from '../pages/group_chat';


// auth
const AdminLogin = React.lazy(() => import('../pages/auth/AdminLogin'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const Fssam = React.lazy(() => import('../pages/auth/Fssam'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

const DataVariable = React.lazy(() => import('../pages/datavariable/DataVariable'));

// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
// const Charts = React.lazy(() => import('../pages/charts/'));
// const Fssamadmin = React.lazy(() => import('../pages/fssamadmin/'));
// const CrimeList = React.lazy(() => import('../pages/crimelist/'));
// const SecurityOperative = React.lazy(() => import('../pages/securityOperative/'));
// const ChatWindow = React.lazy(() => import('../pages/So_admin_chat/'));
// const fssamChat = React.lazy(() => import('../pages/fssam_admin_Chat/'));





// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));


// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                //return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
				return <Home />
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(parseInt(loggedInUser.user_type)) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    component: Dashboard,
    roles: [1,2,3],
    route: PrivateRoute
};

// charts
const chartRoutes = {
    path: '/admin/reports',
    name: 'Reports',
    component: Charts,
    icon: FeatherIcon.PieChart,
    roles: [1],
    route: PrivateRoute
};

// charts
const citizenReportRoutes = {
    path: '/reports',
    name: 'View Citizen Reports',
    component: Charts,
    icon: FeatherIcon.PieChart,
    roles: [2],
    route: PrivateRoute
};

const fssamadminRoutes = {
    path: '/admin/fssam',
    name: 'FSSAM',
    component: Fssamadmin,
    icon: FeatherIcon.User,
    roles: [1],
    route: PrivateRoute
};

const SecurityOperativeRoutes   = {
    path: '/admin/security-operatives',
    name: 'Security operatives',
    component: SecurityOperative,
    icon: FeatherIcon.User,
    roles: [1],
    route: PrivateRoute
};

const crimelistRoutes = {
    path: '/admin/crime-list',
    name: 'Crime List',
    component: CrimeList,
    icon: FeatherIcon.List,
    roles: [1],
    route: PrivateRoute
};

const SubmitReportRoutes = {
    path: '/submit-report',
    name: 'Submit Report',
    component: SubmitReport,
    icon: FeatherIcon.Send,
    roles: [2],
    route: PrivateRoute
};

const fssamReportsRoutes = {
    path: '/fssam-reports',
    name: 'FSSAM Reports',
    component: FssamReports,
    icon: FeatherIcon.List,
    roles: [2],
    route: PrivateRoute
};

const SecurityReports = {
    path: '/security-operative/reports',
    name: 'Reports',
    component: SecurityOperativeReports,
    icon: FeatherIcon.List,
    roles: [3],
    route: PrivateRoute
};

const ChatWindowRoutes = {
    path: '/admin/security-operative-chat',
    name: 'Security Operative chat',
    component: ChatWindow,
    icon: FeatherIcon.List,
    roles: [1],
    route: PrivateRoute
};


const So_fssam_chatRoutes = {
    path: '/account/security-operative-chat',
    name: 'Security Operative chat',
    component: So_fssam_chat,
    icon: FeatherIcon.List,
    roles: [2],
    route: PrivateRoute
};


const admin_fssam_ChatRoutes = {
    path: '/admin-chat',
    name: <span>Admin chat<span id="contact_ministry-chat_counts" className="chat-item-counts ml-2" >0</span></span>,
    component: fssam_admin_Chat,
    icon: FeatherIcon.List,
    roles: [2],
    route: PrivateRoute
};


const fssam_admin_ChatRoutes = {
    path: '/fssam-chat',
    name: 'FSSAM chat',
    component: admin_fssam_Chat,
    icon: FeatherIcon.List,
    roles: [1],
    route: PrivateRoute
};


const so_admin_ChatRoutes= {
    path: '/security-operative/admin',
    name: <span>Admin chat<span id="so_contact_ministry-chat_counts" className="chat-item-counts ml-2" >0</span></span>,
    component: so_admin_chat,
    icon: FeatherIcon.List,
    roles: [3],
    route: PrivateRoute
};


const so_fssam_ChatRoutes = {
    path: '/security-operative/account',
    name: 'FSSAM chat',
    component: so_fssam_chat,
    icon: FeatherIcon.List,
    roles: [3],
    route: PrivateRoute
};


const GroupChatRoutes = {
    path: '/group-chat',
    name: 'Group chat',
    component: GroupChat,
    icon: FeatherIcon.List,
    roles: [1,2,3],
    route: PrivateRoute
};

const adminLoginRoutes = {
    path: '/admin/login',
    name: 'Login',
    component: AdminLogin,
	route: Route,
};

const datavariableRoutes = {
    path: '/admin/data-variables',
    name: 'Data Variables',
    icon: FeatherIcon.FileText,
    component: DataVariable,
    route: PrivateRoute,
    roles: [1],
};


const citizensRoutes = {
    path: '/citizens',
    name: 'Citizens',
    children: [
		{
            path: '/citizens/status-of-report',
            name: 'Status of Report',
            component: StatusofReport,
            route: Route,
        },
		{
            path: '/citizens/accept-terms-and-conditions',
            name: 'Accept Terms and Conditions',
            component: AcceptTermsandConditions,
            route: Route,
        },
		{
            path: '/citizens/submit-report',
            name: 'Select Type of Crime',
            component: SelectTypeofCrime,
            route: Route,
        },
		{
            path: '/citizens/fill-report',
            name: 'Fill Report',
            component: FillReport,
            route: Route,
        },
		/*{
            path: '/citizens/submit-report',
            name: 'Submit Report',
            component: CitizenSubmitReport,
            route: Route,
        },*/
		{
            path: '/citizens/reporters-information',
            name: 'Reporters Information',
            component: ReportersInformation,
            route: Route,
        },
		{
            path: '/citizens/report-tracking',
            name: 'Report Tracking',
            component: ReportTracking,
            route: Route,
        }],

};


// authfssamChat
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
		{
            path: '/account/fssam',
            name: 'Fssam',
            component: Fssam,
            route: Route,
        },
		{
            path: '/account/home',
            name: 'Home',
            component: Home,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
	citizensRoutes,
	adminLoginRoutes,
    dashboardRoutes,
    chartRoutes,
	citizenReportRoutes,
	fssamadminRoutes,
    SecurityOperativeRoutes,
	crimelistRoutes,
	SubmitReportRoutes,
	fssamReportsRoutes,
	authRoutes,
    ChatWindowRoutes,
    So_fssam_chatRoutes,
	datavariableRoutes,
	admin_fssam_ChatRoutes,
    fssam_admin_ChatRoutes,
    so_admin_ChatRoutes,
    so_fssam_ChatRoutes,
    SecurityReports,
    GroupChatRoutes

];

const authProtectedRoutes = [dashboardRoutes,chartRoutes,citizenReportRoutes,SubmitReportRoutes,So_fssam_chatRoutes,SecurityReports,so_fssam_ChatRoutes,so_admin_ChatRoutes,fssam_admin_ChatRoutes,admin_fssam_ChatRoutes,ChatWindowRoutes,fssamReportsRoutes,fssamadminRoutes , SecurityOperativeRoutes,crimelistRoutes,datavariableRoutes,GroupChatRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
