import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import axios from 'axios';
import { apiURL } from '../constants/defaultValues';
import * as layoutConstants from '../constants/layout';
import { allFlattenRoutes as routes } from './index';
import { getLoggedInUser  , isUserAuthenticated} from '../helpers/authUtils';
// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = Loadable({
    loader: () => import('../layouts/Auth'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const VerticalLayout = Loadable({
    loader: () => import('../layouts/Vertical'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

const HorizontalLayout = Loadable({
    loader: () => import('../layouts/Horizontal'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});

class Routes extends Component {
	componentDidMount() {
		var  that=this;
		// setTimeout(function(){			
			that.refreshRerouteCounts();			
		// }, 5000);
	}
	
	componentWillUnmount() {
		const loggedInUser = getLoggedInUser();
		if(loggedInUser!=null){
			if(loggedInUser.role==='Control Center'){
				clearInterval(this.interval);
				clearInterval(this.interval2);
				clearInterval(this.interval3);
			}else if(loggedInUser.role==='Nodal Officer' || loggedInUser.role==='Nodal Supervisor'){
				clearInterval(this.interval2);
			}
			if(loggedInUser.role!=='Control Center' && this.interval!=null && typeof this.interval!='undefined'){
				clearInterval(this.interval);
			}
			
			if((loggedInUser.role==='Nodal Officer' || loggedInUser.role==='Nodal Supervisor') && this.interval2!=null && typeof this.interval2!='undefined'){
				clearInterval(this.interval2);
			}
			
			if((loggedInUser.role==='Call Center') && this.interval3!=null && typeof this.interval3!='undefined'){
				clearInterval(this.interval3);
			}
			
			if((loggedInUser.role==='Administrator') && this.interval4!=null && typeof this.interval4!='undefined'){
				clearInterval(this.interval4);
			}
			
		}
	}


    refreshRerouteCounts(){
		const loggedInUser = getLoggedInUser();
		console.log(loggedInUser,"loggedUser")
		var  that=this;
		if(loggedInUser != null){

		
			if(loggedInUser.user_type== 2){
				var post_data={};
					post_data.user_id = loggedInUser.id;
					post_data.admin_fssam_id = 1;
					axios.post(apiURL+'/api/fssam-admin-count',post_data).then((res)=>{
						console.log(res.data) 
					if(typeof res.data.data!='undefined'){				
						document.getElementById('contact_ministry-chat_counts').innerHTML=res.data.data;
					}			
				}).catch(e => console.log("Addition failed , Error ", e));	
				
				// let req_data = {mda_id:loggedInUser.mda_id};
				// if(loggedInUser.role==='User'){
				// 	req_data = {user_id:loggedInUser.id};
				// }
				// axios.post(apiURL+'/api/ideas/count',req_data).then((res)=>{ 
				// 	if(typeof res.data.data!='undefined'){					
				// 		document.getElementById('ideas_total_counts').innerHTML=res.data.data;
				// 	}			
				// }).catch(e => console.log("Addition failed , Error ", e));	
				
					that.interval2 = setInterval(() =>{
						var post_data={};
						post_data.user_id = loggedInUser.id;
						post_data.admin_fssam_id = 1;
						axios.post(apiURL+'/api/fssam-admin-count',post_data).then((res)=>{ 
							if(typeof res.data.data!='undefined'){					
								document.getElementById('contact_ministry-chat_counts').innerHTML=res.data.data;
							}			
						}).catch(e => console.log("Addition failed , Error ", e));
						
					
					
					}, 10000);
			}else if(loggedInUser.user_type== 3){
				var post_data={};
					post_data.user_id = loggedInUser.id;
					post_data.so_admin_id = 1;
					axios.post(apiURL+'/api/so-admin-count',post_data).then((res)=>{
						console.log(res.data) 
					if(typeof res.data.data!='undefined'){				
						document.getElementById('so_contact_ministry-chat_counts').innerHTML=res.data.data;
					}			
				}).catch(e => console.log("Addition failed , Error ", e));	
				
				// let req_data = {mda_id:loggedInUser.mda_id};
				// if(loggedInUser.role==='User'){
				// 	req_data = {user_id:loggedInUser.id};
				// }
				// axios.post(apiURL+'/api/ideas/count',req_data).then((res)=>{ 
				// 	if(typeof res.data.data!='undefined'){					
				// 		document.getElementById('ideas_total_counts').innerHTML=res.data.data;
				// 	}			
				// }).catch(e => console.log("Addition failed , Error ", e));	
				
				that.interval2 = setInterval(() =>{
					var post_data={};
					post_data.user_id = loggedInUser.id;
					post_data.so_admin_id = 1;
					axios.post(apiURL+'/api/so-admin-count',post_data).then((res)=>{ 
						if(typeof res.data.data!='undefined'){					
							document.getElementById('so_contact_ministry-chat_counts').innerHTML=res.data.data;
						}			
					}).catch(e => console.log("Addition failed , Error ", e));
					
				
				
				}, 10000);
			}
			// setTimeout(function(){
			// 	that.refreshRerouteCounts();			
			// }, 2000);
		}
	}
    // returns the layout
    getLayout = () => {
        if (!isUserAuthenticated()) return AuthLayout;

        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    render() {
        const Layout = this.getLayout();
        
        // rendering the router with layout
        return <BrowserRouter>
            <Layout {...this.props}>
                <Switch>
                    {routes.map((route, index) => {
                        return (
                            !route.children ?
                                <route.route
                                    key={index}
                                    path={route.path}
                                    roles={route.roles}
                                    exact={route.exact}
                                    component={route.component}></route.route>
                                : null
                        );
                    })}
                </Switch>
            </Layout>
        </BrowserRouter>
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        user: state.Auth.user,
    };
};

export default connect(
    mapStateToProps,
    null
)(Routes);
