// @flow
import React, { Component } from 'react';

import {
	Row, Col, Card, CardBody, Button, UncontrolledDropdown, FormGroup, Label, Input,
	DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter,
	DropdownItem, DropdownMenu, CustomInput
} from 'reactstrap';
import { ChevronDown, LogOut, ChevronLeft, ChevronUp, ChevronRight } from 'react-feather';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { getLoggedInUser } from '../../helpers/authUtils';
import DocViewer from './DocViewer';
import { apiURL } from '../../constants/defaultValues';
import axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import PageTitle from '../../components/PageTitle';
import Loader from "react-loader-spinner";
import ReportsTables from './ReportsTables';

class ApexChart extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			modal_standard: false,
			modal_docviewer: false,
			user: getLoggedInUser(),
			loader : false

		};
		this.tog_standard = this.tog_standard.bind(this);
		this.tog_docviewer = this.tog_docviewer.bind(this);
		this.toggle = this.toggle.bind(this);
		this.openModalWithSize = this.openModalWithSize.bind(this);
		this.openModalWithClass = this.openModalWithClass.bind(this);
	}
	componentDidMount()
	{
		this.setState({ loader : false})
	}
	tog_standard() {
		this.setState(prevState => ({
			modal_standard: !prevState.modal_standard
		}));
	}
	tog_docviewer() {
		this.setState(prevState => ({
			modal_docviewer: !prevState.modal_docviewer
		}));
	}

	toggle = () => {
		this.setState(prevState => ({
			modal: !prevState.modal,
		}));
	};

	openModalWithSize = size => {
		this.setState({ size: size, className: null });
		this.toggle();
	};


	openModalWithClass = className => {
		this.setState({ className: className, size: null });
		this.toggle();
	};

	handleValidSubmit = (event, values) => {
		document.getElementById("submit-btn").disabled = true;
		var formData = new FormData(document.getElementById("submit-report-form"));
		formData.append("added_by", this.state.user.id)
		this.setState({ loader : true})
		axios.post(apiURL + '/api/add-report', formData).then((res) => {
			if (res.data.status === 1) {
				this.setState({ loader : false})
				swal("Success!", res.data.message, "success").then(() => {
					window.location.reload();
					document.getElementById("submit-btn").disabled = false;
				});
			} else {
				this.setState({ loader : false})
				swal("Warning!", res.data.message, "warning");
				document.getElementById("submit-btn").disabled = false;
			}
			this.setState({
				loader : false,
				dataLoaded: true,
			});
		}).catch(e => console.log("Addition failed , Error ", e));


	}

	checkMimeType = (event) => {
		//getting file object
		let files = event.target.files
		//define message container
		let err = ''
		// list allow mime type
		const types = ['.pdf', 'application/pdf']
		// loop access array
		for (var x = 0; x < files.length; x++) {
			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {
				// create error message and assign to container 
				err += files[x].name + ' is not a supported file';
			}
		};

		if (err !== '') { // if message not same old that mean has error 
			event.target.value = null;
			swal("Warning", err, "warning");
			return false;
		}
		return true;
	}

	onChange = e => {
		switch (e.target.name) {
			case 'attachment':
				if (e.target.files[0]) {
					if (this.checkMimeType(e)) {
						e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
					} else {
						e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
					}
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};



	render() {
		return (

			<React.Fragment>
				<div className="light_green">
					<PageTitle
						breadCrumbItems={[
							{ label: 'SUBMIT REPORT TO FSSAM', path: '/submit-report', active: true },
						]}
						title={'SUBMIT REPORT TO FSSAM'}
					/>

					<Row className="mt-4">
						<Col lg={12} className={!this.state.loader ? "" :"d-none"}>
							<Card className="pl-4 pr-4 pt-4 pb-4">
								<h5 className="header-title mb-3 mt-0">SUBMIT REPORT TO FSSAM</h5>
								<hr />
								<AvForm onValidSubmit={this.handleValidSubmit} id="submit-report-form" className="authentication-form">
									<div>
										<AvField name="report" label="Report Summary" rows="10" type="textarea"
											placeholder="Brief description"
											validate={{
												required: {
													value: true,
													errorMessage: "Report Summary is required"
												}
											}} />
									</div>
									<div>
										{/* <Col lg={12}> */}
										<div className="custom-file mb-3">
											<Row className="pl-3 pr-3 pt-3 pb-0">
												<Col lg="2">
													<div className="text-r"><label className="black f-15 pt-2 text-r">Attachment</label></div>
												</Col>
												<Col lg="10">
													<AvGroup className="">
														{/* <Label for="name">Name
																<div className="invalid-feedback d-none " id="import_file_error">Please choose the csv file to import</div>
															</Label> */}
														<AvField tag={CustomInput} type="file"
															name="attachment"
															id="attachment"
															accept=".pdf"
															placeholder=""
															validate={{
																required: {
																	value: true,
																	errorMessage: "This field is required"
																}
															}}
															onChange={this.onChange}
															required
														/>
													</AvGroup>
													{/* <AvInput type="file" className="p_5 form-control" name="attachment" id="text" placeholder="" required /> */}
												</Col>
											</Row>
										</div>
										{/* </Col> */}
									</div>
									<div className="text-right mt-4 mb-4">
										<Button style={{ background: "#072308" }} id="submit-btn">Submit Report</Button>
									</div>
								</AvForm>
							</Card>
							</Col>
							<Col  lg={12} className={this.state.loader ? "" :"d-none"}>
							{this.state.loader ?
								<Card className="pl-4 pr-4 pt-4 pb-4" >
									<h5 className="header-title mb-3 mt-0">SUBMIT REPORT TO FSSAM</h5>
									<hr />
									<Loader 
									style={{textAlign :"center"}}
										type="TailSpin"
										color="#006239"
										height={100}
										width={100}//3 secs
									/> </Card> : ""}
						</Col>
					</Row>

					<Row>
						<Modal
							isOpen={this.state.modal_standard}
							toggle={this.tog_standard}
							size="md"
						>
							<ModalHeader toggle={() => this.setState({ modal_standard: false })}>
							</ModalHeader>
							<ModalBody className="pl-0 pr-0">
								<div className="white bg_view pl-4 pr-4">
									<p className="">Report Review</p>
								</div>
								<div className="pl-4 pr-4" style={{ height: "300px" }}>
									<p><strong>Brief Description</strong></p>
								</div>
								<div>
									<Row>
										<Col lg={3}>
											<div className="pl-4 pr-4">
												<strong>Attachment:</strong>
											</div>
										</Col>
										<Col lg={9}>
											<Button onClick={this.tog_docviewer} style={{ background: "#072308" }} className="pt-0 pb-0">View File</Button>
										</Col>
									</Row>
								</div>
								<ModalFooter>
									<Button style={{ background: "#076d07", border: "none" }} className="pt-0 pb-0" onClick={this.tog_standard}>Submit</Button>
									<Button style={{ background: "#707170", border: "none" }} className="pt-0 pb-0" onClick={this.tog_standard}>Cancel</Button>
								</ModalFooter>

							</ModalBody>
						</Modal>
					</Row>
					<Row>
						<Modal
							isOpen={this.state.modal_docviewer}
							toggle={this.tog_docviewer}
							size="md"
						>
							<ModalHeader toggle={() => this.setState({ modal_docviewer: false })}>
							</ModalHeader>
							<ModalBody className="pl-0 pr-0">
								<div className="text-center " >
									<h5 className="green"> <strong> Evidence </strong></h5>
								</div>
								<DocViewer />
								<div className="text-center mt-4">
									<Button style={{ background: "#441e1d", border: "none" }} className="pt-0 pb-0" onClick={this.tog_docviewer}>Close</Button>
								</div>
							</ModalBody>
						</Modal>
					</Row>
				</div>
			</React.Fragment>
		);
	}
};

export default ApexChart;