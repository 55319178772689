import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Container, Row, Col, Card, CardBody, InputGroup, InputGroupAddon, UncontrolledDropdown, DropdownMenu, DropdownToggle,
    DropdownItem, Button, Badge, Modal, CustomInput, ModalHeader, Input, FormGroup, Label, ModalBody, ModalFooter
} from "reactstrap";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { MoreVertical } from 'react-feather';
import BootstrapTable from 'react-bootstrap-table-next';

import { apiURL, BASE_URL } from '../../constants/defaultValues';

import axios from 'axios';
import fetch from 'isomorphic-fetch';
import swal from 'sweetalert';

class EditModal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            dataLoaded: false,
            Edit_data: [],
            modal: false,
        }
    }

    componentDidMount() {
        this.setState({ dataLoaded: true })

    }




    componentWillUnmount() {
        this._isMounted = false;
        document.body.classList.remove('authentication-bg');
    }


    IsBetween(value, left, right) {
        return (left < value && value < right) || (right < value && value < left);
    }


    handleValidSubmit = (event, values) => {
        document.getElementById("crime=list-btn").disabled = true;
        axios.post(apiURL + '/api/edit-crime', values).then((res) => {
            if (res.data.status === 1) {
                document.getElementById("crime=list-btn").disabled = false;
                swal("Success!", res.data.message, "success").then(() => {
                    window.location.reload();
                });
            } else {
                document.getElementById("crime=list-btn").disabled = false;
                window.location.reload();
                // swal("Warning!", res.data.message, "warning");
            }
            this.setState({
                dataLoaded: true,
            });
        }).catch(e => console.log("Addition failed , Error ", e));


    }
    openModal = (e, offers = []) => { return (this.setState({ modal: !this.state.modal, offers })) };
    render() {
        const initialContent = "";

        const { modal, toggle, user_id } = this.props;
        const Edit_data = this.props.Edit_data
        return (

            <Modal
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size="md"
                wrapClassName="modal-right"
            >
                <ModalHeader toggle={this.props.toggle}>Edit Crime</ModalHeader>
                <ModalBody>
                    
                        
                            {/* {this.props.Edit_data ? this.props.Edit_data.map((data) => {
                                return ( */}
                                    <AvForm onValidSubmit={this.handleValidSubmit} id="crime-list-form" className="authentication-form">
                                <Row className="pl-2 pr-2 pt-2 pb-2">
                                                <Col lg={12}>
                                                <AvField
                                                     value={this.props.Edit_data ? this.props.Edit_data.title : ""}
                                                    name="title"
                                                    label="Title"
                                                    type="text"
                                                    required />
                                            </Col>
                                            <Col lg={12}>
                                                <AvField
                                                    name="sub_title"
                                                    label="Subtitle"
                                                    value={this.props.Edit_data ? this.props.Edit_data.sub_title : ""}
                                                    type="text"
                                                    
                                                    validate={{
                                                        required: {
															value: true,
															errorMessage: "sub title is required."
														},
                                                        minLength: {
                                                            value: 2,
                                                            errorMessage: "The sub title must be between 2 and 180 characters"
                                                        },
                                                        maxLength: {
                                                            value: 80,
                                                            errorMessage: "The sub title  must be between 2 and 180 characters"
                                                        }
													}} />
                                            </Col>
                                            <AvInput type="hidden" id="edit_id" name="edit_id" value={this.props.user_id}></AvInput>
                                            <Col lg={12}>
                                                <div className="text-right">
                                                    <Button color="primary" id="crime=list-btn" className="ml-1 mr-1 edit-bg pl-4 pr-4" size="sm">Submit</Button>
                                                </div>
                                            </Col> </Row>
                                            </AvForm>
                                            {/* )

                            }) : ""} */}

                       
                   
                </ModalBody>

            </Modal>
        );
    }
};

export default (EditModal);


