import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import Flatpickr from 'react-flatpickr'

import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';
import logomob from '../../assets/images/mob-logo.png';

import { Link } from 'react-router-dom';

import * as FeatherIcon from 'react-feather';

import { getLoggedInUser } from '../../helpers/authUtils';
import Loader from '../../components/Loader';
import OverviewWidget from '../../components/OverviewWidget';
import logo from '../../assets/images/logo-fav.png';
import amotekun from '../../assets/images/amotekun.jpg';
import lalong from '../../assets/images/lalong.jpg';
import taraba from '../../assets/images/taraba.jpg';
import crime1 from '../../assets/images/crime-1.jpg';
import alert1 from '../../assets/images/alert.jpg';

import SwiperCore, { Navigation , Autoplay,Pagination,EffectCoverflow  } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';


SwiperCore.use([Navigation,Autoplay, Pagination,EffectCoverflow]);	
 
class Citizen extends Component {

    constructor(props) {
        super(props);

        var oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 15);

        this.state = {
			modal_standard: false,
            user: getLoggedInUser(),
            filterDate: [oneWeekAgo, new Date()]
        };
		this.tog_standard = this.tog_standard.bind(this);
    }
	tog_standard() {
		this.setState(prevState => ({
		  modal_standard: !prevState.modal_standard
		}));
	  }
	  componentDidMount() {
        this._isMounted = true;

        document.body.classList.add('citizens');
    }

    render() {
		  
		     const params = {
			effect: 'coverflow',
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: '2',
			coverflowEffect: {
				rotate: -20,
				stretch: 4,
				depth: 120,
				modifier: 1,
				slideShadows: true
			  },
			              loop: true,
            centeredSlides: true,
			navigation: 
			      {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				  }
             }

        return (
            <React.Fragment>
			
			<div className="navbar navbar-expand flex-column flex-md-row navbar-custom">
				  <Container fluid>
					{ /* logo */}
					<Link to="/" className="navbar-brand mr-0 mr-md-2 logo">
					  <span className="logo-lg mob-logo-lg">
						<img src={logotext} alt="" height="" style={{maxWidth:"100%!important"}}/>
					  </span>
					  <span className="logo-sm">
						<img src={logomob} alt="" height="24" />
					  </span>
					</Link>

					{ /* menu*/}
					
					<ul className="top-nav-temrs menu-color navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0" >
						 <li className=" mob-hide ml-2 mr-2">Home</li>
						 <li className=" mob-hide ml-2 mr-2">About Us</li>
						 <Link to="/citizens/report-tracking"><li className="mob-hide ml-2 mr-2">Track Report</li></Link>
						 <li className="mob-hide ml-2 mr-2">Contact Us</li>
					</ul>
					<ul className="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
					  <li className="">
						<button className="button-menu-mobile open-left disable-btn" onClick={this.props.openLeftMenuCallBack} >
						  <Menu className="menu-icon" />
						  <X className="close-icon" />
						</button>
					  </li>
					</ul>

				  </Container>
				</div>
			
                <div className="white_bg terms-logo-hide mt-5">
                    <Row className="container pb-1 p-50 ">
						 <Col lg={4} className="pl-0 pr-0"> 
							 <div className="mob-float-right logo-mob mt-4">
								 <img src={logo} alt="" height="250" />
							 </div>
						 </Col>
						 <Col lg={8} className=""> 
							  <div className="text-center">
								 <h2 className="grey mt-5">Welcome to</h2>
								 <h1 className="grey f-800">Security Incident Reporting Platform</h1>
							  </div>
							  <div className="text-center">
								 <Button onClick={this.tog_standard} color="primary" className="width-lg mt-3 ml-4 mr-4 pt-2 pb-2 f-19 p-30 m-30">Report a crime or incident</Button>
								 <Link to="/citizens/report-tracking" ><Button color="" className="width-lg ml-4 mt-3 mr-4 pt-2 pb-2 f-19 violet pl-4 pr-4 p-30 m-30">Track your Report</Button></Link>
							 </div>
						 </Col>
                    </Row>
                    
                    <Row className=" green_bg row_height">
					     <Col lg={12}>
						     <div className="text-center">
							     <h1 className="white" style={{fontWeight:"700"}}>See Something... Say Something</h1>
							 </div>
						     <Swiper {...params}>
								<div className="swiper-slider text-center ml-0 pl-0 mr-0 pr-0"><img className="img-fluid ml-0 pl-0 mr-0 pr-0" src={crime1} alt="" height="667" /></div>
								<div className="swiper-slider text-center ml-0 pl-0 mr-0 pr-0"><img className="img-fluid ml-0 pl-0 mr-0 pr-0" src={crime1} alt="" height="667" /></div>
								<div className="swiper-slider text-center ml-0 pl-0 mr-0 pr-0"><img className="img-fluid ml-0 pl-0 mr-0 pr-0" src={crime1} alt="" height="667" /></div>
							  </Swiper> 
						 </Col>
                    </Row>
                    <Row className="pl-4 pr-4">
					     <h1 className="green security_news" style={{fontWeight:"700"}}>Security News</h1>
                    </Row>
                    <Row className="pl-4 pr-4 pt-2 mb-4">
					     <Col lg={4} className="text-center"> 
						        <img className="img-fluid mt-4" src={lalong} alt="" />
							 <div className="">
							     <h3 className="blog-text">Lalong meets security heads, orders onslaught against criminals</h3>
							 </div>
							 <div className="bl_og">
							     <Button block className="green_bg f-18 pt-1 pb-1">Read more...</Button>
							 </div>
						 </Col>
						 <Col lg={4} className="text-center"> 
							     <img className="img-fluid mt-4" src={amotekun} alt=""  />
							 <div className="">
							     <h3 className="blog-text">Amotekun has no magic wand for insecurity , says Togun</h3>
							 </div>
							 <div className="bl_og">
							     <Button block className="green_bg f-18 pt-1 pb-1">Read more...</Button>
							 </div>
						 </Col>
						 <Col lg={4} className="text-center"> 
							    <img className="img-fluid mt-4" src={taraba} alt=""  />
							 <div className="">
							     <h3 className="blog-text">Security operatives deployed to construction sites in Taraba</h3>
							 </div>
							 <div className="bl_og">
							     <Button block className="green_bg f-18 pt-1 pb-1">Read more...</Button>
							 </div>
						 </Col>
                    </Row>
				<Row>
					 <Modal
						  isOpen={this.state.modal_standard}
						  toggle={this.tog_standard}
						  size="lg"
						>
						  <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
						  </ModalHeader>
						  <ModalBody>
						        <div className="text-center"><img className="img-fluid mt-1 mb-4" src={alert1} alt="" /></div>
								<div className="text-center modal-text"><h5 style={{fontWeight:"700"}}>IS IT AN EMERGENCY?</h5></div>	 
						        <div className="text-center modal-text pt-2">
								     <p className="f-19"> Does it feel like the situation could get heated or violent very soon?</p>
								     <p className="f-19"> Is someone is immediate danger?</p>
								     <p className="f-19"> If so please call 112 now.</p>
								</div>
								<div className="text-center mt-4 mb-4">
							     <Link to="/citizens/accept-terms-and-conditions"><Button className="green_bg f-18 btn_modal">Proceed</Button></Link>
							    </div>
						  </ModalBody>
				     </Modal>
				</Row>
                </div>
				<footer className=" footer  pb-3">
					<div className="container-fluid">
						<div className="row">
							<div className="col-8 mob_foot">
								2021 All Rights Reserved. | SIRP
							</div>
							<div className="col-4 text-right mob_foot">
								<span>Terms & Conditions</span>
							</div>
						</div>
					</div>
                </footer>
            </React.Fragment>
        )
    }
}


export default Citizen;