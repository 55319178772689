
import React, { Component } from 'react';

import { Row, Col,Card, CardBody, Button, UncontrolledDropdown,DropdownToggle,Modal, ModalHeader, ModalBody, ModalFooter,
         DropdownItem,DropdownMenu,} from 'reactstrap';
import { ChevronDown, ChevronLeft, ChevronUp, ChevronRight } from 'react-feather';

import PageTitle from '../../components/PageTitle';

import ReportsTables from './ReportsTables';

 class ApexChart extends Component {

	constructor(props) {
        super(props);

        this.state = {
            modal: false,
        };

        this.toggle = this.toggle.bind(this);
        this.openModalWithSize = this.openModalWithSize.bind(this);
        this.openModalWithClass = this.openModalWithClass.bind(this);
    }

    /**
     * Show/hide the modal
     */
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    };

    /**
     * Opens large modal
     */
    openModalWithSize = size => {
        this.setState({ size: size, className: null });
        this.toggle();
    };

    /**
     * Opens modal with custom class
     */
    openModalWithClass = className => {
        this.setState({ className: className, size: null });
        this.toggle();
    };
	
	 render() {
        return (
   
        <React.Fragment><div className="light_green ">
            <PageTitle
				breadCrumbItems={[
					{ label: 'Security operatives', path: '/admin/security-operatives', active: true },
				]}
				title={'Security operatives'}
			/>
            
			<Row className="mt-3">
			     <Col lg={12}>
				     <Card className="pl-4 pr-4 pt-4 pb-4">
					     <ReportsTables />
					 </Card>
				 </Col>
            </Row>
         </div>
        </React.Fragment>
    );
	 }
};

export default ApexChart;

