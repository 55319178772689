import React, { Component } from 'react';
import { Row, Col, Card, 
         Button,CardBody,Modal, 
		 ModalHeader, ModalBody, 
		 ModalFooter, Input, FormGroup,
		 UncontrolledDropdown, 
		 DropdownMenu, DropdownItem,
		 InputGroupAddon,Label,CustomInput,
		 DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import alert1 from '../../assets/images/alert.jpg';
import logo from '../../assets/images/logo-fav.png';
import { AvForm, AvGroup,AvRadioGroup,AvRadio, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import FileUploader from '../../components/FileUploader';

import { Container } from 'reactstrap';
import { Menu, X, Search, Settings, User, HelpCircle, Lock, LogOut } from 'react-feather';
import logotext from '../../assets/images/logo-text.jpg';
import Topbar from '../../components/Topbarin';

class AcceptTermsandConditions extends Component {

    constructor(props) {
        super(props);
		this.state = {
			modal_yes: false,
			modal_submit: false
            
        };
		this.tog_yes = this.tog_yes.bind(this);
		this.tog_submit = this.tog_submit.bind(this);
    }
	tog_yes() {
		this.setState(prevState => ({
		  modal_yes: !prevState.modal_yes
		}));
	  }
	tog_submit() {
		this.setState(prevState => ({
		  modal_submit: !prevState.modal_submit
		}));
	  }
	  
	  componentDidMount() {
        this._isMounted = true;

        document.body.classList.add('accept_terms');
    }

    render() {
		  
        return (
            <React.Fragment className="pl-0 pr-0 ml-0 mr-0" >
			
			<Topbar/>
                <div className="mt-4 fillreport container-fluid terms-logo-hide pl-0 pr-0 ml-0 mr-0">
                    <Row className=" terms-logo-hide">
	                     <Col lg={3} className="">
						     <div className="white_bg pl-4 pr-0 ml-0 mr-0" style={{height:"100%"}}>
							     <div className="text-center pt-4">
								     <img src={logo} alt="" height="100" />
								 </div>
								 <div>
								     <p>PROGRESS FLOW</p>
								 </div>
								 <div className="radio_custom_sirp" style={{height:"100%"}}>
								     <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox" className="" label="Accept Terms & Conditions"  />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox1" label="Select Type Of Crime" />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox2" label="Fill Report" />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox3" label="Confirm Your ID" />
									 </div>
									 <div className="mt-3 mb-3">
										<CustomInput type="checkbox" id="exampleCustomCheckbox4" label="Submit Report" />
									 </div>
								 </div>
							 </div>
						 </Col>
						 <Col lg={9} className="">
						     <p className="black f-600 mt-4 f-18">Reporter's Information</p>
						     <div className="white_bg mt-0 pl-4 pr-4 pt-4 pb-4" style={{borderRadius:"10px",height:"100%"}}>
								  <h5 className=" black f-700">I am :</h5>
								  <AvForm><Row className="pl-3 pr-3 pt-3 pb-3">
								      <Col lg="12">
									      <div className="pt-2 pb-2">
										     <AvRadioGroup name="radioCustomInputExample" label="" required>
												  <AvRadio customInput label="The impacted person" value="impactedperson" />
												  <AvRadio customInput label="Reporting on behalf of the impacted" value="Squirtle" />
											 </AvRadioGroup>
										  </div>
									  </Col>
								  </Row></AvForm>
								  
								  
								  <h5 className="black f-700">My Contact Information:</h5>
								  <Row className="pl-3 pr-3 pt-3 pb-2">
								      <Col lg="4" className="pt-2">
									      <div className="d-flex">
										     <label className="black pt-2">First Name</label>
											 <AvForm className="pl-2 pr-2 mob-reporter-padding"> 
										         <AvInput type="text" name="text" id="text" placeholder="" required />
										     </AvForm>
										 </div>
									  </Col>
									  <Col lg="4" className="pt-2">
									     <div className="d-flex">
										     <label className="black pt-2">Middle Name</label>
											 <AvForm className="pl-2 pr-2"> 
										         <AvInput type="text" name="text" id="text" placeholder="" required />
										     </AvForm>
										 </div>
									  </Col>
									  <Col lg="4" className="pt-2">
									     <div className="d-flex">
										     <label className="black pt-2">Last Name</label>
											 <AvForm className="pl-2 pr-2 mob-reporter-padding"> 
										         <AvInput type="text" name="text" id="text" placeholder="" required />
										     </AvForm>
										 </div>
									  </Col>
									  
								  </Row>
								  <Row className="pl-3 pr-3 pt-2 pb-2">
								      <Col lg="6">
									     <Row>
										     <Col lg="2">
											     <label className="black pt-2">Phone</label>
											 </Col>
											 <Col lg="10">
											     <AvForm className=""> 
										             <AvInput type="text" name="text" id="text" placeholder="" required />
										         </AvForm>
											 </Col>
										 </Row>
									  </Col>
									  <Col lg="6">
									     <Row>
										     <Col lg="2">
											     <label className="black pt-2">Email</label>
											 </Col>
											 <Col lg="10">
											     <AvForm className=""> 
										             <AvInput type="text" name="text" id="text" placeholder="" required />
										         </AvForm>
											 </Col>
										 </Row>
									  </Col>
								  </Row>
								  
								  <Row className="pl-3 pr-3 pt-0 pb-0">
								      <Col lg="1">
									      <div className=""><label className="black f-15 pt-2 text-r">Address</label></div>
									  </Col>
									  <Col lg="11">
									    <AvForm> 
										     <AvInput type="textarea" name="text" id="text" placeholder="" required />
										</AvForm>
									  </Col>
								  </Row>
								  
								  <Row>
								      <Col lg="12">
									     <div className="text-center mt-4">
										      <Button className="ml-4 mr-4 bg-clr mt-2 pt-2 pb-2" onClick={this.tog_yes}>PREVIOUS</Button>
										      <Button className="ml-4 mr-4 mt-2 green_bg_bg pt-2 pb-2" onClick={this.tog_submit}>SUBMIT</Button>
										 </div>
									  </Col>
								  </Row>
							 </div>
						 </Col>
                    </Row>
                    <Row>
						 <Modal
							  isOpen={this.state.modal_yes}
							  toggle={this.tog_yes}
							  size="lg"
							>
							  <ModalHeader toggle={() => this.setState({ modal_yes: false })}>
							  </ModalHeader>
							  <ModalBody>
						        <div className="text-center"><img className="img-fluid mt-1 mb-4" src={alert1} alt="" /></div>
								<div className="text-center modal-text"><h2 style={{fontWeight:"800",color:"#f30b0b"}}>WARNING</h2></div>	 
						        <div className="text-center modal-text pt-2">
								     <p className="f-19">If your report is found to be fake or false,</p>
								     <p className="f-19">you will be <strong>APPREHENDED !</strong></p>
								</div>
								<div className="text-center m-40 mt-4 mb-4">
							     <Link to="/citizens/select-type-of-crime"><Button className="bg_pop f-18 ml-4 mt-2 mr-4 btn_modal">I Don't Accept</Button></Link>
								 <Link to="/citizens/fill-report"><Button className="green_bg f-18 ml-4 mr-4 mt-2 btn_modal">I Accept</Button></Link>
							    </div>
						  </ModalBody>
						 </Modal>
				    </Row>
					<Row>
						 <Modal
							  isOpen={this.state.modal_submit}
							  toggle={this.tog_submit}
							  size="lg"
							>
							  <ModalHeader toggle={() => this.setState({ modal_submit: false })}>
							  </ModalHeader>
							  <ModalBody>
								<div className="text-center modal-text"><h2 style={{fontWeight:"700",color:"#62000d"}}>Are you sure want to submit this report?</h2></div>	 
								<div className="text-center m-40 mt-4 mb-4">
							     <Link to="/citizens/select-type-of-crime"><Button className="bg_pop f-18 ml-4 mr-4 mt-2 btn_modal">NO</Button></Link>
								 <Link to="/citizens/submit-report"><Button className="green_bg f-18 ml-4 mr-4 btn_modal mt-2">YES</Button></Link>
							    </div>
						  </ModalBody>
						 </Modal>
				    </Row>
                   </div>
            </React.Fragment>
        )
    }
}


export default AcceptTermsandConditions;